import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// @mui
import { Container, Tab, Tabs, Box } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';

// components
import Iconify from '../../components/iconify';
import CustomBreadcrumbs from '../../components/custom-breadcrumbs';
import { useSettingsContext } from '../../components/settings';
// import { Helmet } from 'react-helmet-async';

// sections
import {
  AccountGeneral,
  OrganizationSettings,
  //   AccountBillng,
  //   AccountSocialLinks,
  //   AccountNotifications,
} from './sections/account';

import AddUsersPage from '../../views/addPatients/AddPatientsPage';

// ----------------------------------------------------------------------

export default function ProfileAccountPage() {
  const { themeStretch } = useSettingsContext();
  const { tab } = useParams();

  const [currentTab, setCurrentTab] = useState('general');

  useEffect(() => {
    console.log('tab', tab);
    if (tab) {
      setCurrentTab(tab);
    }
  }, [tab]);

  const TABS = [
    {
      value: 'general',
      label: 'General',
      icon: <Iconify icon="ic:round-account-box" />,
      component: <AccountGeneral />,
    },
    {
      value: 'organization',
      label: 'Organización',
      icon: <Iconify icon="mdi:people" />,
      component: <OrganizationSettings />,
    },
    {
      value: 'addUsers',
      label: 'Agregar Pacientes',
      icon: <Iconify icon="mdi:people-add" />,
      component: <AddUsersPage />,
    },
    // {
    //   value: "social_links",
    //   label: "Social links",
    //   icon: <Iconify icon="eva:share-fill" />,
    //   component: <AccountSocialLinks socialLinks={_userAbout.socialLinks} />,
    // }
  ];

  return (
    <>
      {/* <Helmet>
        <title> User: Account Settings | Minimal UI</title>
      </Helmet> */}
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Account"
          links={[{ name: 'Profile', href: PATH_DASHBOARD.profile.general }, { name: 'General' }]}
        />

        <Tabs
          value={currentTab}
          onChange={(event, newValue) => {
            setCurrentTab(newValue);
          }}>
          {TABS.map(tab => (
            <Tab key={tab.value} label={tab.label} icon={tab.icon} value={tab.value} />
          ))}
        </Tabs>

        {TABS.map(
          tab =>
            tab.value === currentTab && (
              <Box key={tab.value} sx={{ my: 5, pb: 8 }}>
                {tab.component}
              </Box>
            ),
        )}
      </Container>
    </>
  );
}

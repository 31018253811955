import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../core';
// @mui
import { Box } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import { useSettingsContext } from '../../components/settings';
// Navigation
import Main from '../dashboard/Main';
import Header from '../dashboard/header';
import NavMini from '../dashboard/nav/NavMini';
import NavVertical from '../dashboard/nav/NavVertical';

export default function WebviewLayout() {
  const { themeLayout } = useSettingsContext();

  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);

  const isNavMini = themeLayout === 'mini';

  const adminStatus = useSelector((state: RootState) => state.account.admin);

  const navigate = useNavigate();

  useEffect(() => {
    if (adminStatus) {
      console.log('User is admin');
      navigate('/dashboard');
    }
  }, [adminStatus]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderNavVertical = <NavVertical openNav={open} onCloseNav={handleClose} />;

  if (isNavMini) {
    return (
      <>
        <Header onOpenNav={handleOpen} />

        <Box
          sx={{
            display: { lg: 'flex' },
            minHeight: { lg: 1 },
          }}>
          {isDesktop ? <NavMini /> : renderNavVertical}

          <Main>
            <Outlet />
          </Main>
        </Box>
      </>
    );
  }

  return (
    <>
      <Header onOpenNav={handleOpen} isWebview={true} />
      <Box
        sx={{
          display: { lg: 'flex' },
          minHeight: { lg: 1 },
        }}>
        <Main>
          <Outlet />
        </Main>
      </Box>
    </>
  );
}

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  useMediaQuery,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/es';

import Iconify from '../iconify';

import dayjs from 'dayjs';

import type { RootState } from '../../core';
import { type Filter } from './types';
import { log } from 'console';
import { date } from 'yup';
import { set } from 'lodash';

interface UIState {
  dateButtonSelected: boolean;
  openModal: boolean;
}

const initialUIState = { dateButtonSelected: false, openModal: false };

export default function DateFilterResumen() {
  const [UIState, setUIState] = useState<UIState>(initialUIState);
  const [dateRangeStart, setDateRangeStart] = useState<string | null>(null);
  const [dateRangeEnd, setDateRangeEnd] = useState<string | null>(null);

  const dispatch = useDispatch();

  const userState = useSelector((state: RootState) => state.user.settings);

  const defaultFilterValue = {
    type: 'days',
    days: 3,
  };

  const filter: Filter = userState?.filter || defaultFilterValue;

  useEffect(() => {
    dispatch.user.getSettingsInfo(filter);
  }, [filter]);

  const handleClick = (days: number) => {
    const filter = {
      type: 'days',
      days,
    };

    dispatch.user.getSettingsInfo(filter);

    setUIState({
      ...UIState,
      dateButtonSelected: false,
    });

    setDateRangeStart(null);
    setDateRangeEnd(null);
  };

  const handleClose = () => {
    setUIState(prevState => ({
      ...prevState,
      openModal: false,
      dateButtonSelected: false,
    }));
  };

  const handleApply = () => {
    // Implement your logic to apply the date filter here...

    if (
      (dateRangeStart !== null && dateRangeEnd !== null) ||
      (filter.date?.start !== null && filter.date?.end !== null)
    ) {
      const newFilter = {
        type: 'date',
        date: {
          start: dateRangeStart ? dayjs(dateRangeStart).format('YYYY-MM-DD') : filter.date?.start,
          end: dateRangeEnd ? dayjs(dateRangeEnd).format('YYYY-MM-DD') : filter.date?.end,
        },
      };

      dispatch.user.getSettingsInfo(newFilter);

      // handle UI
      setUIState({
        ...UIState,
        openModal: false,
      });

      setDateRangeStart(null);
      setDateRangeEnd(null);
    } else {
      // TODO: show error message in toast
      console.warn('Please select both the start and end dates.');
    }
  };

  const isMobile = useMediaQuery('(max-width: 600px)'); // You can adjust the breakpoint as needed
  console.log('filter.days-----', filter.days);
  return (
    <Container style={{ paddingLeft: '0px', margin: '0px', display: 'flex', gap: '8px' }}>
      <Button
        variant="contained"
        color={filter.days === 180 ? 'primary' : 'inherit'}
        onClick={() => {
          handleClick(180);
        }}>
        6 meses
      </Button>

      <Button
        variant="contained"
        color={filter.type === 'date' ? 'primary' : 'inherit'}
        style={{ display: 'flex', alignItems: 'center' }}
        onClick={() => {
          setUIState(prevState => ({
            ...prevState,
            dateButtonSelected: true,
            openModal: true,
          }));
        }}>
        Fechas exactas
        <Iconify icon="mdi:calendar-outline" style={{ marginLeft: '10px' }} />
      </Button>

      <Dialog
        open={UIState.openModal}
        onClose={() => {
          setUIState(prevState => ({ ...prevState, openModal: false }));
        }}>
        <DialogTitle>Seleccione las fechas</DialogTitle>
        <DialogContent style={{ marginTop: 20 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
            <DatePicker
              label="Inicio"
              value={dateRangeStart ?? filter.date?.start ?? null}
              onChange={newValue => {
                if (newValue !== null) {
                  setDateRangeStart(newValue);
                }
              }}
              renderInput={params => <TextField {...params} />}
            />
            <DatePicker
              label="Final"
              value={dateRangeEnd ?? filter.date?.end ?? null}
              onChange={newValue => {
                if (newValue !== null) {
                  setDateRangeEnd(newValue);
                }
              }}
              disableFuture
              renderInput={params => <TextField {...params} />}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleApply} color="primary" autoFocus>
            Aplicar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

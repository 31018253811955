import React from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// components
import Iconify from '../../../../../components/iconify';
import { useSnackbar } from '../../../../../components/snackbar';

import { useCallback, useEffect, useMemo } from 'react';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import styles

import { useNavigate, useParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';

// @mui

import { Link, Card, Grid, Box, Typography, CardHeader, Stack } from '@mui/material';

import FormProvider, { RHFTextField, RHFEditor } from '../../../../../components/hook-form';

import { useForm, Controller } from 'react-hook-form';

import { styled } from '@mui/material/styles';

import { PATH_DASHBOARD } from '../../../../../routes/paths';
import { GLOBAL } from '../../../../../constants/variables';
import { API_URL } from 'constants/api';
import axios from 'axios';

// ----------------------------------------------------------------------

const StyledIcon = styled(Iconify)(({ theme }) => ({
  width: 20,
  height: 20,
  marginTop: 1,
  flexShrink: 0,
  marginRight: theme.spacing(2),
}));

// ----------------------------------------------------------------------

type FormValuesProps = IExpediente;

interface IExpediente {
  _id: string;
  medical_history: string;
  user_id: string;
}

export default function Expediente() {
  const { userId } = useParams(); // Get the userId from the URL
  const token = localStorage.getItem(GLOBAL.TOKEN_KEY);

  const ExpedienteSchema = Yup.object().shape({
    text_body: Yup.string(),
    _id: Yup.string().required('ID is required'),
    medical_history: Yup.string().required('Medical History is required'),
    user_id: Yup.string().required('User ID is required'),
  });

  const { enqueueSnackbar } = useSnackbar();

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(ExpedienteSchema),
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
    control,
  } = methods;

  useEffect(() => {
    const fetchExpediente = async () => {
      const urlExpediente = `${API_URL}medical-history/user/${userId}`;

      console.log('fetchExpediente --');

      await axios
        .get(urlExpediente, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then(results => {
          reset(results.data);
          console.log('results --', results.data);
        })
        .catch(err => {
          if (err.message === 'Request failed with status code 401') {
            console.log('Failed request');
          }
        });
    };
    fetchExpediente();
  }, []);

  const onSubmit = async (data: FormValuesProps) => {
    try {
      const payload = {
        user_id: data.user_id,
        medical_history: data.medical_history,
      };

      console.log('data', payload);
      await axios.post(`${API_URL}medical-history`, payload, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      enqueueSnackbar('Cambiado exitosamente');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Card>
      <style>
        {`
        .ql-container {
          height: 200px !important;
        }
      `}
      </style>
      <CardHeader title="Expediente Clinico" />
      <Stack spacing={2} sx={{ p: 3 }}>
        <Stack direction="row"></Stack>

        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item sx={{ width: '100%' }}>
              <Stack spacing={2}>
                {/* Hidden Input for User ID */}
                <Controller
                  name="user_id"
                  control={control}
                  defaultValue={userId} // Use the ID you get from useParams
                  render={({ field }) => <input type="hidden" {...field} />}
                />
                {/*
                <Block>
                  <RHFTextField multiline rows={4} name="medical_history" />
                </Block>
*/}
                {/* WYSIWYG Editor for Medical History */}
                <Block>
                  <Controller
                    name="medical_history"
                    control={control}
                    render={({ field }) => (
                      <ReactQuill value={field.value} onChange={field.onChange} />
                    )}
                  />
                </Block>

                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                  Guardar cambios
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </FormProvider>
      </Stack>
    </Card>
  );
}

interface BlockProps {
  sx?: any;
  children: React.ReactNode;
}

function Block({ sx, children }: BlockProps) {
  return <Box sx={{ mb: 2, ...sx }}>{children}</Box>;
}

import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Link, Typography, Container, Stack } from '@mui/material';
// routes
import { PATH_AUTH } from '../../routes/paths';
// components
import Iconify from '../../components/iconify';
import Header from '../../components/header';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
// config
import { HEADER } from '../../config-global';
// sections
import AuthResetPasswordForm from '../../sections/auth/AuthResetPasswordForm';
// assets
import { PasswordIcon } from '../../assets/icons';

// ----------------------------------------------------------------------

export default function ResetPasswordPage() {
  const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);

  return (
    <>
      <Header isOffset={isOffset} />
      <Container component="main">
        <Stack
          sx={{
            py: 12,
            m: 'auto',
            maxWidth: 400,
            minHeight: '100vh',
            textAlign: 'center',
            justifyContent: 'center',
          }}>
          <PasswordIcon sx={{ mb: 5, height: 96 }} />

          <Typography variant="h3" paragraph>
            ¿Olvidaste tu contraseña?
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 5 }}>
            Por favor escribe la dirección de correo electrónico asociada con tu cuenta y te
            enviaremos un correo con una liga para regenerar tu contraseña.
          </Typography>

          <AuthResetPasswordForm />

          {/* TODO: Add Empty state when email is sent */}

          <Link
            component={RouterLink}
            to={PATH_AUTH.login}
            color="inherit"
            variant="subtitle2"
            sx={{
              mt: 3,
              mx: 'auto',
              alignItems: 'center',
              display: 'inline-flex',
            }}>
            <Iconify icon="eva:chevron-left-fill" width={16} />
            Regresar
          </Link>
        </Stack>
      </Container>
    </>
  );
}

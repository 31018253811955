import { useEffect, useState, useCallback } from 'react';
//import { Amplify, Storage } from 'aws-amplify';
// import awsconfig from './aws-exports'; // Your AWS configuration file

import { useParams } from 'react-router-dom';

// @mui
import {
  Stack,
  Dialog,
  Button,
  TextField,
  type DialogProps,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
// components
import Iconify from '../../../../components/iconify';
import { Upload } from '../../../../components/upload';

import axios, { type AxiosError } from 'axios';
import { GLOBAL } from '../../../../constants/variables';
import { API_URL } from 'constants/api';

// ----------------------------------------------------------------------

interface Props extends DialogProps {
  title?: string;
  //
  onCreate?: VoidFunction;
  onUpdate?: VoidFunction;
  //
  folderName?: string;
  onChangeFolderName?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  //
  open: boolean;
  onClose: VoidFunction;
}

const urlString = `${API_URL}medical-history/files/upload`;
const token = localStorage.getItem(GLOBAL.TOKEN_KEY);

export default function FileNewFolderDialog({
  title = 'Subir archivos',
  open,
  onClose,
  //
  onCreate,
  onUpdate,
  //
  folderName,
  onChangeFolderName,
  ...other
}: Props) {
  const [files, setFiles] = useState<Array<File | string>>([]);
  const { userId } = useParams(); // Get the userId from the URL

  useEffect(() => {
    if (!open) {
      setFiles([]);
    }
  }, [open]);

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const newFiles = acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      );

      setFiles([...files, ...newFiles]);
    },
    [files],
  );

  // const handleUpload = () => {
  //    onClose();
  //   console.log('ON UPLOAD');
  // };

  const handleUpload = async () => {
    // Ensure files are selected for upload

    console.log('handleUpload triggered');

    if (!files.length) {
      console.log('No files selected for upload.');
      return;
    }

    // Create a FormData instance
    const formData = new FormData();

    // Append files to formData
    files.forEach((file, index) => {
      formData.append(`file`, file);
    });

    if (userId) {
      formData.append('userId', userId);
    }

    try {
      const response = await fetch(urlString, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData, // automatically sets 'multipart/form-data'
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Upload successful:', data);
      onClose();

      //window.location.reload();

      // Get the current pathname
      const currentPathname = window.location.pathname;

      // Append the query parameter to the current pathname
      const newPathname = `${currentPathname}?upload=success`;

      // Update the location with the modified pathname
      window.history.pushState({ path: newPathname }, '', newPathname);

      // Reload the page
      window.location.reload();
    } catch (error) {
      if (error instanceof Error) {
        console.error('There was a problem with the fetch operation:', error.message);
      } else {
        console.error('No error message');
        console.error('There was a problem with the fetch operation:', error);
      }
    }
  };

  const handleRemoveFile = (inputFile: File | string) => {
    const filtered = files.filter(file => file !== inputFile);
    setFiles(filtered);
  };

  const handleRemoveAllFiles = () => {
    setFiles([]);
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose} {...other}>
      <DialogTitle sx={{ p: theme => theme.spacing(3, 3, 2, 3) }}> {title} </DialogTitle>

      <DialogContent dividers sx={{ pt: 1, pb: 0, border: 'none' }}>
        {(onCreate || onUpdate) && (
          <TextField
            fullWidth
            label="Nombre"
            value={folderName}
            onChange={onChangeFolderName}
            sx={{ mb: 3 }}
          />
        )}

        <Upload multiple files={files} onDrop={handleDrop} onRemove={handleRemoveFile} />
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          startIcon={<Iconify icon="eva:cloud-upload-fill" />}
          onClick={handleUpload}>
          Subir
        </Button>

        {!!files.length && (
          <Button variant="outlined" color="inherit" onClick={handleRemoveAllFiles}>
            Eliminar todo
          </Button>
        )}

        {(onCreate || onUpdate) && (
          <Stack direction="row" justifyContent="flex-end" flexGrow={1}>
            <Button variant="soft" onClick={onCreate || onUpdate}>
              {onUpdate ? 'Save' : 'Create'}
            </Button>
          </Stack>
        )}
      </DialogActions>
    </Dialog>
  );
}

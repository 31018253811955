import React, { useEffect, useState } from 'react';
import axios, { type AxiosError } from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { format } from 'date-fns';
import moment from 'moment';
import classnames from 'classnames';

import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Button,
  Card,
  Grid,
  CardHeader,
  CardContent,
  Container,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

// Componentes
import Scrollbar from '../../../../components/scrollbar';
import { TableHeadCustom } from '../../../../components/table';
import Chart, { useChart } from '../../../../components/chart';
import Iconify from '../../../../components/iconify';
import Label from '../../../../components/label';
import Image from 'components/image';
import LightBox from 'components/lightbox';

// Constants
import { GLOBAL } from '../../../../constants/variables';
import { API_URL } from 'constants/api';

import { styled } from '@mui/material/styles';
import Lightbox from 'components/lightbox/Lightbox';
import { Functions } from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
  circleIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',
    float: 'left',
    height: '30px',
    borderRadius: '50%',
    color: 'white',
    '&.critico': {
      backgroundColor: '#EA5944',
    },
    '&.optimo': {
      backgroundColor: '#00AD69',
    },
    '&.default': {
      backgroundColor: '#54565A',
    },
    '&.aceptable': {
      backgroundColor: '#FFA043',
    },
  },
  text: {
    marginLeft: '50px',
    display: 'flex', // Add display flex
    alignItems: 'center', // Align items vertically in the flex container
    justifyContent: 'center', // Align items horizontally in the flex container
  },
}));

const StyledIcon = styled(Iconify)(({ theme }) => ({
  width: '20px',
  height: '20px',
  color: 'white',
}));

interface EventDetailPageProps {
  eventId: string;
}

interface EventData {
  _id: string;
  title: string;
  type: string;
  image: string;
  category: string;
  date: string;
  lastSensorDate: string;
  glucose: Array<{
    blood_glucose_mg_per_dL: number;
    timestamp: string;
  }>;
  glucoseEventVariability: {
    glucoseFinal: number;
    glucoseInitital: number;
    glucoseSpike: number;
    intensityChange: number;
    minimumGlucose: number;
    outRange: number;
    score: string;
  };
  created_at: string;
  updated_at: string;
}

interface GlucoseData {
  blood_glucose_mg_per_dL: number;
  timestamp: string;
}

interface ScoreData {
  deltaGlucose: number;
  deltaTime: number;
  baseLineRecovery: number;
  score: number;
}

interface EventDetailPageProps {
  eventId: string;
  onBackButtonClick: () => void; // Add the callback function
}

interface ZoneScoreProps {
  scoreA: number;
  scoreB: number;
  scoreC: number;
}

export default function EventDetailPage() {
  const classes = useStyles();

  const { eventId } = useParams();

  const token = localStorage.getItem(GLOBAL.TOKEN_KEY);
  const [tableData, setTableData] = useState<EventData | null>(null);
  const [chartData, setChartData] = useState<GlucoseData[]>([]);
  const [scoreData, setScoreData] = useState<ScoreData>();
  const [zoneScore, setZoneScore] = useState<ZoneScoreProps>();

  const [openLightbox, setOpenLightbox] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const urlEventString = `${API_URL}events/${eventId}`;

    const fetchEventData = async () => {
      try {
        const eventResults = await axios.get(urlEventString, {
          headers: {
            application: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        console.log('eventResults: ', eventResults.data);

        setTableData(eventResults.data);
        setScoreData(calculateValues(eventResults.data));

        const glucoseData = eventResults.data.glucose.map((item: GlucoseData) => {
          const glucoseDatum = {
            blood_glucose_mg_per_dL: item.blood_glucose_mg_per_dL,
            timestamp: item.timestamp,
          };

          return glucoseDatum;
        });

        setChartData(glucoseData);

        const zoneScoreA = calculateZoneScore(eventResults.data.glucose, 10);
        const zoneScoreB = calculateZoneScore(eventResults.data.glucose, 7);
        const zoneScoreC = calculateZoneScore(eventResults.data.glucose, 4);

        setZoneScore({
          scoreA: zoneScoreA,
          scoreB: zoneScoreB,
          scoreC: zoneScoreC,
        });
      } catch (err) {
        if ((err as AxiosError).message === 'Request failed with status code 401') {
          // dispatch.auth.logout();
          console.log('failed request ');
        }
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchEventData();
  }, [eventId]);

  const chartOptions = useChart({
    xaxis: {
      categories: chartData?.map(data => format(new Date(data.timestamp), 'hh:mm a')) ?? [],
      labels: {
        show: true,
        hideOverlappingLabels: true,
      },
    },
    // ...
  });

  const calculateValues = (tableData: any) => {
    // Calculating Delta Glucose
    const glucoseEventVariability = tableData.glucoseEventVariability;
    const glucoseData = tableData.glucose;

    const deltaGlucose =
      Number(glucoseEventVariability.glucoseSpike) -
      Number(glucoseEventVariability.glucoseInitital);

    // Calculatin Delta Time
    const maxGlucoseObject = glucoseData.reduce(
      (maxObject: any, currentObject: any) => {
        return currentObject.blood_glucose_mg_per_dL > maxObject.blood_glucose_mg_per_dL
          ? currentObject
          : maxObject;
      },
      { blood_glucose_mg_per_dL: -Infinity },
    );
    const end = dayjs(maxGlucoseObject.timestamp);
    const start = dayjs(tableData.date);

    const elapsedMinutes = end.diff(start, 'minute');

    // Calculating Base Line Recovery
    // -ΔG/ΔG = (Pico de la glucosa - Glucosa Final ) / (Pico Glucosa- Glucosa Inicial)
    let baseLineRecovery =
      (Number(glucoseEventVariability.glucoseSpike) -
        Number(glucoseEventVariability.glucoseFinal)) /
      deltaGlucose;

    baseLineRecovery = baseLineRecovery * 100;

    const score = calculateScore(deltaGlucose, baseLineRecovery, elapsedMinutes);

    return {
      deltaGlucose: Number(deltaGlucose.toFixed(1)),
      deltaTime: elapsedMinutes,
      baseLineRecovery: Number(baseLineRecovery.toFixed(0)),
      score,
    };
  };

  function calculateScore(deltaGlucose: number, baselineRecovery: number, deltaT: number): number {
    // calculate deltaGlucose score
    let deltaGlucoseScore;

    switch (true) {
      case deltaGlucose <= 5:
        deltaGlucoseScore = 10;
        break;
      case deltaGlucose <= 9:
        deltaGlucoseScore = 9;
        break;
      case deltaGlucose <= 15:
        deltaGlucoseScore = 8;
        break;
      case deltaGlucose <= 19:
        deltaGlucoseScore = 7;
        break;
      case deltaGlucose <= 25:
        deltaGlucoseScore = 6;
        break;
      case deltaGlucose <= 30:
        deltaGlucoseScore = 5;
        break;
      case deltaGlucose <= 35:
        deltaGlucoseScore = 4;
        break;
      case deltaGlucose <= 40:
        deltaGlucoseScore = 3;
        break;
      case deltaGlucose <= 45:
        deltaGlucoseScore = 2;
        break;
      case deltaGlucose <= 50:
        deltaGlucoseScore = 1;
        break;
      default:
        deltaGlucoseScore = 0;
    }

    // calculate baselineRecovery score
    let baselineRecoveryScore;

    switch (true) {
      case baselineRecovery > 140:
        baselineRecoveryScore = 5;
        break;
      case baselineRecovery >= 130:
        baselineRecoveryScore = 6;
        break;
      case baselineRecovery >= 120:
        baselineRecoveryScore = 7;
        break;
      case baselineRecovery >= 110:
        baselineRecoveryScore = 8;
        break;
      case baselineRecovery >= 100:
        baselineRecoveryScore = 9;
        break;
      case baselineRecovery >= 90:
        baselineRecoveryScore = 10;
        break;
      case baselineRecovery >= 80:
        baselineRecoveryScore = 9;
        break;
      case baselineRecovery >= 70:
        baselineRecoveryScore = 8;
        break;
      case baselineRecovery >= 60:
        baselineRecoveryScore = 7;
        break;
      case baselineRecovery >= 50:
        baselineRecoveryScore = 6;
        break;
      case baselineRecovery < 50:
        baselineRecoveryScore = 5;
        break;
      default:
        baselineRecoveryScore = 0;
    }

    // calculate deltaT score
    let deltaTScore;

    switch (true) {
      case deltaT <= 15:
        deltaTScore = 5;
        break;
      case deltaT <= 20:
        deltaTScore = 6;
        break;
      case deltaT <= 25:
        deltaTScore = 7;
        break;
      case deltaT <= 30:
        deltaTScore = 8;
        break;
      case deltaT <= 36:
        deltaTScore = 9;
        break;
      case deltaT <= 40:
        deltaTScore = 10;
        break;
      case deltaT <= 45:
        deltaTScore = 9;
        break;
      case deltaT <= 48:
        deltaTScore = 8;
        break;
      case deltaT <= 50:
        deltaTScore = 7;
        break;
      case deltaT <= 55:
        deltaTScore = 6;
        break;
      case deltaT <= 59:
        deltaTScore = 5;
        break;
      case deltaT >= 60:
        deltaTScore = 4;
        break;
      default:
        deltaTScore = 0;
    }

    let totalScore;

    if (deltaGlucoseScore === 10) {
      totalScore = deltaGlucoseScore;
    } else {
      totalScore = (deltaGlucoseScore + deltaTScore + baselineRecoveryScore) / 3;
    }

    return Number(totalScore.toFixed(0));
  }

  function calculateZoneScore(glucoseReadings: GlucoseData[], calibration: number): number {
    const score = 10;
    let maxGlucosePeak = 0;

    glucoseReadings.forEach((reading, index) => {
      // Calculate Glucose Peak
      if (reading.blood_glucose_mg_per_dL > maxGlucosePeak) {
        maxGlucosePeak = reading.blood_glucose_mg_per_dL;
      }
    });

    // Calculate the SD of slopes
    const glucoseSlope = calculateSDofSlopes(glucoseReadings);

    // Calculate Glucose Rate of change
    const rateOfChange = calculateAverageRateOfChange(glucoseReadings);

    console.log('Max Glucose Peak: ', maxGlucosePeak);
    console.log('Glucose Slope (SD): ', glucoseSlope);
    console.log('Rate of Change: ', rateOfChange);

    console.log('\n');

    // Deductions
    const glucosePeakDeduction = maxGlucosePeak >= 100 ? 1 - (maxGlucosePeak - 100) / 60 : 1;
    const glucoseSlopeDeduction = glucoseSlope > 2 ? 1 - (glucoseSlope - 2) / 13 : 1;
    const areaUnderCurveDeduction = rateOfChange > 5 ? 1 - (rateOfChange - 2) / 18 : 1;

    console.log('Glucose Peak Deduction: ', glucosePeakDeduction);
    console.log('Glucose Slope Deduction: ', glucoseSlopeDeduction);
    console.log('Area Under Curve Deduction: ', areaUnderCurveDeduction);

    console.log('\n');

    // Scores
    let glucosePeakScore = maxGlucosePeak >= 160 ? 1 : score * glucosePeakDeduction;
    let glucoseSlopeScore = glucoseSlope > 15 ? 1 : score * glucoseSlopeDeduction;
    let areaUnderCurveScore = rateOfChange >= 20 ? 1 : score * areaUnderCurveDeduction;

    glucosePeakScore = glucosePeakScore === 10 ? calibration : glucosePeakScore;
    glucoseSlopeScore = glucoseSlopeScore === 10 ? calibration : glucoseSlopeScore;
    areaUnderCurveScore = areaUnderCurveScore === 10 ? calibration : areaUnderCurveScore;

    let length = 3;

    if (glucosePeakScore === 0) {
      length -= 1;
    }
    if (glucoseSlopeScore === 0) {
      length -= 1;
    }
    if (areaUnderCurveScore === 0) {
      length -= 1;
    }

    console.log('\n');
    // Composite score
    const compositeScore = (glucosePeakScore + glucoseSlopeScore + areaUnderCurveScore) / length;
    console.log('Composite Score: ', compositeScore);

    console.log('\n');
    // Final Zone Score
    let zoneScore = compositeScore;

    zoneScore = Math.round(zoneScore);

    console.log('zone Score: ', zoneScore);

    return zoneScore;
  }

  function calculateAlternateScores(
    score: number,
    glucosePeakScore: number,
    glucoseSlopeScore: number,
    areaUnderCurveScore: number,
  ) {
    glucosePeakScore = glucosePeakScore === 10 ? score : glucosePeakScore;
    glucoseSlopeScore = glucoseSlopeScore === 10 ? score : glucoseSlopeScore;
    areaUnderCurveScore = areaUnderCurveScore === 10 ? score : areaUnderCurveScore;

    let length = 3;

    if (glucosePeakScore === 0) {
      length -= 1;
    }
    if (glucoseSlopeScore === 0) {
      length -= 1;
    }
    if (areaUnderCurveScore === 0) {
      length -= 1;
    }

    const compositeScore = (glucosePeakScore + glucoseSlopeScore + areaUnderCurveScore) / length;

    const newScore = Math.round(compositeScore);
    console.log(`Alternate Score - ${score}`, newScore);
    return newScore;
  }

  function calculateSDofSlopes(glucoseData: GlucoseData[]): number {
    // Extract the glucose readings and time intervals from the glucoseData array
    const glucoseReadings = glucoseData.map(data => data.blood_glucose_mg_per_dL);
    const timeIntervals: Date[] = glucoseData.map(data => new Date(data.timestamp));

    // Calculate the slopes between consecutive glucose readings
    const slopes = [];
    for (let i = 1; i < glucoseReadings.length; i++) {
      const deltaTime = (timeIntervals[i].getTime() - timeIntervals[i - 1].getTime()) / (1000 * 60); // time difference in minutes
      const deltaGlucose = glucoseReadings[i] - glucoseReadings[i - 1];

      // Handle cases where time doesn't change or glucose reading doesn't change
      let slope;
      if (deltaTime === 0) {
        slope = 0; // Use a slope of 0 for identical timestamps
      } else {
        slope = deltaGlucose / deltaTime;
      }
      slopes.push(slope);
    }

    // Calculate the mean of the slopes
    const mean = slopes.reduce((a, b) => a + b, 0) / slopes.length;

    // Calculate the squared deviations from the mean
    const squaredDeviations = slopes.map(slope => Math.pow(slope - mean, 2));

    // Calculate the variance (mean of squared deviations)
    const variance = squaredDeviations.reduce((a, b) => a + b, 0) / squaredDeviations.length;

    // Calculate the standard deviation (square root of variance)
    const standardDeviation = Math.sqrt(variance);

    return standardDeviation;
  }

  function calculateAverageRateOfChange(glucoseData: GlucoseData[]): number {
    const timeIntervals: Date[] = glucoseData.map(data => new Date(data.timestamp));

    let totalRateOfChange = 0;

    // Loop through glucoseData and sum the rate of change of glucose levels
    for (let i = 1; i < glucoseData.length; i++) {
      const deltaGlucose =
        glucoseData[i].blood_glucose_mg_per_dL - glucoseData[i - 1].blood_glucose_mg_per_dL;
      const deltaTime = (timeIntervals[i].getTime() - timeIntervals[i - 1].getTime()) / (1000 * 60); // time difference in minutes

      // Check for identical timestamps and assign a rate of change of 0 if true
      const rateOfChange = deltaTime !== 0 ? deltaGlucose / deltaTime : 0;

      totalRateOfChange += rateOfChange;
    }

    // Calculate the average rate of change
    const averageRateOfChange = totalRateOfChange / (glucoseData.length - 1);

    return averageRateOfChange;
  }

  const series = [
    {
      name: 'Glucosa',
      data: chartData ? chartData.map(data => data.blood_glucose_mg_per_dL) : [], // Set the data to the blood glucose values from glucoseData
    },
  ];

  const TABLE_HEAD = [
    { id: 'name', label: 'Evento', align: 'center' },
    { id: 'date', label: 'Fecha', align: 'center' },
    { id: 'zoneScore', label: 'Score', align: 'center' },
    { id: 'score', label: 'Score', align: 'center' },
  ];
  const METRICS_HEAD = [
    { id: 'glucoseSpike', label: 'Glucosa máxima', align: 'center' },
    { id: 'minimumGlucose', label: 'Glucosa mínima', align: 'center' },
    { id: 'intensityChange', label: 'Intensidad', align: 'center' },
    { id: 'outRange', label: 'Fuera de rango', align: 'center' },
  ];

  const TABLE_HEAD2 = [
    { id: 'name', label: 'ΔG mg/dl', align: 'center' },
    { id: 'date', label: 'ΔT minutos', align: 'center' },
    { id: 'glucoseSpike', label: '-ΔG/ΔG %', align: 'center' },
    { id: 'minimumGlucose', label: 'Score', align: 'center' },
  ];
  const TABLE_HEAD3 = [
    { id: 'name', label: 'Score Normal', align: 'center' },
    { id: 'date', label: 'Score Medio', align: 'center' },
    { id: 'glucoseSpike', label: 'Score Agresivo', align: 'center' },
  ];

  return (
    <>
      <Container sx={{ paddingY: '30px' }}>
        {/* TODO: Add Empty State if event has no data. "No data available for this event." */}
        <Button
          onClick={() => navigate(-1)}
          sx={{ margin: '20px' }}
          variant="outlined"
          size="large"
          color="inherit">
          <Iconify icon="fe:arrow-left" style={{ marginRight: '10px' }} />
          Todos los eventos
        </Button>
        {/* Event Score Table */}
        <Grid item xs={12} md={12} lg={12}>
          <Card dir="ltr">
            <TableContainer sx={{ height: '120px' }}>
              <Scrollbar>
                <Table sx={{ minWidth: 800 }}>
                  <TableHeadCustom headLabel={TABLE_HEAD} />

                  <TableBody>
                    {tableData != null && (
                      <TableRow>
                        <TableCell align="center" className={classes.text}>
                          <div
                            className={classnames(classes.circleIconWrapper, {
                              critico: tableData.glucoseEventVariability.score === 'Critico',
                              optimo: tableData.glucoseEventVariability.score === 'Optimo',
                              aceptable: tableData.glucoseEventVariability.score === 'Aceptable',
                              default: tableData.glucoseEventVariability.score === 'Empty',
                            })}>
                            {tableData.category === 'Ejercicio' ? (
                              <StyledIcon icon="ic:baseline-directions-run" />
                            ) : tableData.category === 'Comida' ? (
                              <StyledIcon icon="ic:baseline-restaurant-menu" />
                            ) : (
                              <StyledIcon icon="ic:baseline-star" />
                            )}
                          </div>
                          <span className={classes.text}>{tableData.title}</span>
                        </TableCell>
                        <TableCell align="center">
                          {moment(tableData.date).format('D MMM - HH:mm')}
                        </TableCell>
                        <TableCell align="center">{zoneScore?.scoreB}</TableCell>
                        <TableCell align="center">
                          <Label
                            variant="soft"
                            color={
                              (tableData.glucoseEventVariability.score === 'Optimo' && 'primary') ||
                              (tableData.glucoseEventVariability.score === 'Aceptable' &&
                                'warning') ||
                              (tableData.glucoseEventVariability.score === 'Critico' && 'error') ||
                              (tableData.glucoseEventVariability.score === 'Empty' && 'default') ||
                              'default'
                            }
                            sx={{ textTransform: 'capitalize' }}>
                            {tableData.glucoseEventVariability.score}
                          </Label>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>
          </Card>
        </Grid>
        <Grid item xs={12} md={12} lg={12} sx={{ marginTop: '30px' }}>
          <Card dir="ltr">
            <CardHeader title="Impacto del evento en glucosa" />
            <CardContent>
              <Chart type="line" series={series} options={chartOptions} height={320} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={12} lg={12} sx={{ marginTop: '30px' }}>
          <Card dir="ltr">
            <TableContainer sx={{ height: '120px' }}>
              <Scrollbar>
                <Table sx={{ minWidth: 800 }}>
                  <TableHeadCustom headLabel={METRICS_HEAD} />

                  <TableBody>
                    {tableData != null && (
                      <TableRow>
                        <TableCell align="center">
                          {tableData.glucoseEventVariability.glucoseSpike} mg/dL{' '}
                        </TableCell>
                        <TableCell align="center">
                          {tableData.glucoseEventVariability.minimumGlucose} mg/dL
                        </TableCell>
                        <TableCell align="center">
                          {tableData.glucoseEventVariability.intensityChange} mg/dL
                        </TableCell>
                        <TableCell align="center">
                          {tableData.glucoseEventVariability.outRange?.toFixed(0)} minutos
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>
          </Card>
        </Grid>
        {/* Zone Score Table */}
        {/* <Grid item xs={12} md={12} lg={12} sx={{ marginTop: '30px' }}>
        <TableContainer sx={{ height: '120px' }}>
          <Scrollbar>
            <Table sx={{ minWidth: 800 }}>
              <TableHeadCustom headLabel={TABLE_HEAD3} />

              <TableBody>
                {tableData != null && (
                  <TableRow>
                    <TableCell align="center">{zoneScore?.scoreA}</TableCell>
                    <TableCell align="center">{zoneScore?.scoreB}</TableCell>
                    <TableCell align="center">{zoneScore?.scoreC}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>
      </Grid> */}
        {/* Event Score Table */}
        {/* <Grid item xs={12} md={12} lg={12} sx={{ marginTop: '30px' }}> 
        <TableContainer sx={{ height: '120px' }}>
          <Scrollbar>
            <Table sx={{ minWidth: 800 }}>
              <TableHeadCustom headLabel={TABLE_HEAD2} />

              <TableBody>
                {tableData != null && (
                  <TableRow>
                    <TableCell align="center">
                      { ΔG = Pico Glucosa - Glucosa Inicial }
                      {scoreData?.deltaGlucose}
                    </TableCell>
                    <TableCell align="center">
                      { ΔT = Tiempo de Pico - Tiempo Inicial }
                      {scoreData?.deltaTime}
                    </TableCell>
                    <TableCell align="center">
                      { -ΔG/ΔG = (Pico de la glucosa - Glucosa Final ) / (Pico Glucosa- Glucosa Inicial) }
                      {scoreData?.baseLineRecovery}
                      {' %'}
                    </TableCell>
                    <TableCell align="center">
                      { Score }
                      {scoreData?.score}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>
      </Grid> */}
        {/* Render the chart */}
        {tableData?.image && (
          <Card sx={{ marginTop: '30px' }}>
            <Image src={tableData.image} onClick={() => setOpenLightbox(true)} ratio="21/9" />
            {/* <Lightbox
              disabledZoom={true}
              disabledTotal={true}
              disabledVideo={true}
              disabledCaptions={false}
              disabledSlideshow={true}
              disabledThumbnails={true}
              disabledFullscreen={true}
              index={0}
              open={openLightbox}
              close={() => setOpenLightbox(false)}
              slides={[{ src: tableData?.image }]}
            /> */}
          </Card>
        )}
      </Container>
    </>
  );
}

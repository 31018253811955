import React, { type SyntheticEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from '../../core';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useSnackbar } from 'notistack';

// MUI
import { Stack, Button, InputAdornment, IconButton, Card, Typography } from '@mui/material';

// Components
import FormProvider, { RHFTextField } from '../../components/hook-form';
import { CustomAvatar } from '../../components/custom-avatar';
import Iconify from 'components/iconify/Iconify';

// Variables
import { API_URL } from '../../constants/api';

// API
import api from '../../services/api';
import { I } from '@fullcalendar/core/internal-common';

// --------

interface InviteUsersFormProps {
  isOnboarding?: boolean;
}
interface FormValuesProps {
  email: string;
}

export default function InviteUsersForm({ isOnboarding = false }: InviteUsersFormProps) {
  const [emailInvites, setEmailInvites] = useState<FormValuesProps[]>([]);

  const navigate = useNavigate();

  const authState = useSelector((state: RootState) => state.auth);
  const auth = authState.auth;
  const { enqueueSnackbar } = useSnackbar();

  const InviteUsersSchema = Yup.object().shape({
    email: Yup.string().email('Este correo no es valido').required(),
  });

  type FormValuesProps = Yup.InferType<typeof InviteUsersSchema>;

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(InviteUsersSchema),
    defaultValues: {
      email: '',
    },
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors },
  } = methods;

  const handleAddEmail = async (data: FormValuesProps) => {
    try {
      setEmailInvites([...emailInvites, data]);
      reset();
    } catch (error) {
      console.log(error);
    }
  };

  const clickHandler = () => {
    handleSubmit(handleAddEmail)();
  };

  const handleRemoveEmail = (email: FormValuesProps) => {
    const newEmailInvites = emailInvites.filter(item => item.email !== email.email);
    setEmailInvites(newEmailInvites);
  };

  const sendInvites = () => {
    if (emailInvites.length > 0) {
      const emailInvitesClean = emailInvites.map(item => {
        return item.email;
      });

      const data = {
        emails: emailInvitesClean,
      };

      api
        .post(`admin/organization-invitation`, data)
        .then(response => {
          enqueueSnackbar('Invitaciones enviadas');
          console.log(response);
          navigate('/');
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      navigate('/');
    }
  };

  return (
    <>
      <Stack spacing={3}>
        <FormProvider methods={methods} onSubmit={handleSubmit(handleAddEmail)}>
          <RHFTextField
            name="email"
            label="Correo Electrónico"
            placeholder="hola@somos.me"
            helperText="Los que agregues a la lista recibirán una invitación para crear su cuenta"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button variant="contained" color="inherit" onClick={clickHandler}>
                    Agregar
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </FormProvider>
        {emailInvites.length > 0 && (
          <Card sx={{ p: 3, textAlign: 'left' }}>
            <Typography variant="h6">Emails Agregados</Typography>
            <Typography variant="body2">
              Has agregado a {emailInvites.length}{' '}
              {emailInvites.length === 1 ? 'colega' : 'colegas'}
            </Typography>

            <Stack spacing={2} sx={{ mt: 2 }}>
              {emailInvites.map((email, index) => (
                <Stack direction="row" sx={{ alignItems: 'center' }} key={index}>
                  <CustomAvatar alt={email.email} name={email.email} />

                  <Typography variant="body1" sx={{ marginLeft: 2 }}>
                    {email.email}
                  </Typography>
                  <IconButton
                    edge="end"
                    sx={{ marginLeft: 'auto' }}
                    onClick={e => {
                      handleRemoveEmail(email);
                    }}>
                    <Iconify icon="ph:x-bold" />
                  </IconButton>
                </Stack>
              ))}
            </Stack>
          </Card>
        )}

        {emailInvites.length > 0 || !isOnboarding ? (
          <Button
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            onClick={sendInvites}
            disabled={emailInvites.length === 0}>
            Enviar invitaciones
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            onClick={() => {
              navigate('/dashboard');
            }}>
            {' '}
            Los agrego despues
          </Button>
        )}
      </Stack>
    </>
  );
}

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from '../../core';

import { differenceInSeconds, formatDistanceToNow, intervalToDuration, format } from 'date-fns';

import {
  Tab,
  Tabs,
  Card,
  Table,
  Button,
  Tooltip,
  Divider,
  Container,
  IconButton,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
} from '@mui/material';

import { PATH_DASHBOARD } from '../../routes/paths';

// components
import Iconify from '../../components/iconify';
import Label, { LabelColor } from '../../components/label';

// for api calls
import axios from 'axios';
import { GLOBAL } from '../../constants/variables';
import { API_URL } from 'constants/api';

import CustomBreadcrumbs from '../../components/custom-breadcrumbs';
import { useSettingsContext } from '../../components/settings';

import {
  useTable,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TablePaginationCustom,
} from '../../components/table';

// Sections
import { UserTableEmptyState } from '../../sections/@dashboard/user/list';

const token = localStorage.getItem(GLOBAL.TOKEN_KEY);

const TABLE_HEAD = [
  { id: 'category', label: 'Categoria', align: 'left' },
  { id: 'email', label: 'Usuario', align: 'left' },
  { id: 'created', label: 'Fecha', align: 'left' },
];

interface ActivityData {
  user_id: string;
  email: string;
  created_at: string;
  message: string;
  type: string;
  _id: string;
}

export default function ActivityFeed({ withBreadcrumbs = true }) {
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0); // state variable for total pages
  const [totalRows, setTotalRows] = useState(0); // state variable for total rows
  const [tableData, setTableData] = useState<ActivityData[]>([]);
  const [selectedButton, setSelectedButton] = useState<number>(7);

  const {
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const accountState = useSelector((state: RootState) => state.account);
  const isEmpty = accountState.settings.isEmpty;

  function formatTimeDifference(date: Date): string {
    const difference = differenceInSeconds(new Date(), date);
    const duration = intervalToDuration({ start: date, end: new Date() });

    if (difference < 60) {
      return `Hace ${difference} segundos`;
    }

    let result = 'Hace';

    if (duration.days && duration.days > 0) {
      result += ` ${duration.days} ${duration.days === 1 ? 'día' : 'días'}`;
    }

    if (duration.hours && duration.hours > 0) {
      result += ` ${duration.hours} ${duration.hours === 1 ? 'hora' : 'horas'}`;
    }

    if (duration.minutes && duration.minutes > 0) {
      result += ` ${duration.minutes} ${duration.minutes === 1 ? 'minuto' : 'minutos'}`;
    }

    return result.trim();
  }

  useEffect(() => {
    const getActivtyFeed = async () => {
      setLoading(true);
      const urlString = `${API_URL}feeds?page=${page + 1}&days=${selectedButton}`;
      console.log('urlString ----- ', urlString);

      await axios
        .get(urlString, {
          headers: {
            application: 'application/json',
            authorization: `Bearer ${token}`,
          },
        })
        .then(res => {
          setLoading(false);
          console.log('activity feed', res.data);
          setTableData(res.data.data);
          setTotalRows(res.data.data.count);
          setTotalPages(Math.ceil(res.data.data.count / 20));
        })
        .catch(err => {
          if (err.message === 'Request failed with status code 401') {
            console.log('Request failed with status code 401');
            // dispatch.auth.logout();
          }
        });
    };
    getActivtyFeed();
  }, [page, selectedButton]);

  return (
    <>
      <Container sx={{ my: 5 }}>
        {withBreadcrumbs && (
          <CustomBreadcrumbs
            heading="Actividad"
            links={[
              { name: 'Dashboard', href: PATH_DASHBOARD.root },
              { name: 'Actividad', href: PATH_DASHBOARD.activity },
            ]}
          />
        )}

        {isEmpty ? (
          <UserTableEmptyState />
        ) : (
          <>
            <Container style={{ display: 'flex', marginLeft: '-20px', gap: '8px' }}>
              <Button
                variant="contained"
                color={selectedButton === 1 ? 'primary' : 'inherit'}
                onClick={() => {
                  setSelectedButton(1);
                }}>
                Hoy
              </Button>
              <Button
                variant="contained"
                color={selectedButton === 3 ? 'primary' : 'inherit'}
                onClick={() => {
                  setSelectedButton(3);
                }}>
                3 días
              </Button>
              <Button
                variant="contained"
                color={selectedButton === 7 ? 'primary' : 'inherit'}
                onClick={() => {
                  setSelectedButton(7);
                }}>
                7 días
              </Button>
              <Button
                variant="contained"
                color={selectedButton === 14 ? 'primary' : 'inherit'}
                onClick={() => {
                  setSelectedButton(14);
                }}>
                2 semanas
              </Button>
            </Container>

            <Card sx={{ marginTop: '30px' }}>
              <TableContainer sx={{ mt: 3, overflow: 'unset' }}>
                <Table sx={{ minWidth: 800 }}>
                  {/*          <TableHeadCustom headLabel={TABLE_HEAD} /> */}
                  <TableBody>
                    {tableData?.map(activity => (
                      <TableRow key={activity._id}>
                        <TableCell>{activity.type} </TableCell>
                        <TableCell> {activity.message} </TableCell>

                        <TableCell align="right">
                          {activity.created_at
                            ? formatTimeDifference(new Date(activity.created_at))
                            : '-'}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePaginationCustom
                count={totalRows}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={onChangePage}
                //
              />
            </Card>
          </>
        )}
      </Container>
    </>
  );
}

import { useTheme } from '@mui/material/styles';
import { Card, Typography, Stack, type CardProps, Box } from '@mui/material';
// utils
import { fCurrency, fPercent } from '../../../../utils/formatNumber';
// theme
import { type ColorSchema } from '../../../../theme/palette';
// components
import Iconify from '../../../../components/iconify';
import Chart, { useChart } from '../../../../components/chart';

// ----------------------------------------------------------------------

interface Props extends CardProps {
  title: string;
  total: number;
  units: string;
  percent: number;
  color?: ColorSchema;
  icon: string;
}

export default function IndicatorDetailWidget({
  title,
  units,
  total,
  icon,
  percent,
  color = 'primary',
  sx,
  ...other
}: Props) {
  const theme = useTheme();
  return (
    <Card
      sx={{
        width: 1,
        boxShadow: 0,
        color: theme.palette[color].darker,
        bgcolor: theme.palette[color].lighter,
        ...sx,
      }}
      {...other}>
      <Iconify
        icon={icon}
        sx={{
          p: 1.5,
          top: 24,
          right: 24,
          width: 48,
          height: 48,
          borderRadius: '50%',
          position: 'absolute',
          color: theme.palette[color].lighter,
          bgcolor: theme.palette[color].dark,
        }}
      />

      <Stack spacing={1} sx={{ p: 3 }}>
        <Typography variant="subtitle2">{title}</Typography>

        <Box display="flex" alignItems="center">
          <Typography variant="h3">{total}</Typography>
          <Typography variant="h4" sx={{ ml: 1 }}>
            {units}
          </Typography>
        </Box>

        {percent !== 0 && <TrendingInfo percent={percent} />}
      </Stack>
    </Card>
  );
}

// ----------------------------------------------------------------------

interface TrendingInfoProps {
  percent: number;
}

function TrendingInfo({ percent }: TrendingInfoProps) {
  return (
    <Stack direction="row" alignItems="center" flexWrap="wrap" spacing={0.5}>
      <Iconify icon={percent < 0 ? 'eva:trending-down-fill' : 'eva:trending-up-fill'} />

      <Typography variant="subtitle2" component="span">
        {percent > 0 && '+'}

        {fPercent(percent)}
      </Typography>
    </Stack>
  );
}

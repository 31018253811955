import { init, type RematchDispatch, type RematchRootState } from '@rematch/core';
import { models, type RootModel } from './models';
import persistPlugin from '@rematch/persist';
import { type PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import * as Sentry from '@sentry/react';

const sentryReuxEnhancer = Sentry.createReduxEnhancer({});

const persistConfig: PersistConfig<RootModel> = {
  key: 'root',
  storage,
  whitelist: ['account', 'auth', 'user'],
};

export const store = init({
  models,
  plugins: [persistPlugin<RootModel, typeof models>(persistConfig)],
  redux: {
    enhancers: [sentryReuxEnhancer],
  },
});

export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel>;

import React from 'react';
// @mui
import { Typography, Stack } from '@mui/material';
// components
import Logo from '../../components/logo';
import Image from '../../components/image';

//
import { StyledRoot, StyledSectionBg, StyledSection, StyledContent } from './styles';

// ----------------------------------------------------------------------

interface Props {
  title?: string;
  illustration?: string;
  children: React.ReactNode;
}

export default function LoginLayout({ title, children }: Props) {
  return (
    <StyledRoot>
      <Logo
        sx={{
          zIndex: 9,
          position: 'absolute',
          mt: { xs: 1.5, md: 5 },
          ml: { xs: 2, md: 5 },
        }}
      />

      <StyledSection>
        <Typography
          variant="h1"
          sx={{
            mb: 10,
            maxWidth: 680,
            textAlign: 'center',
            color: theme => theme.palette.primary.darker,
          }}>
          {title || 'Somos Smart Wellness'}
        </Typography>

        <Image
          disabledEffect
          visibleByDefault
          alt="auth"
          src={'/assets/illustrations/illustration_dashboard_somos.png'}
          sx={{ maxWidth: 720 }}
        />

        <StyledSectionBg />
      </StyledSection>

      <StyledContent>
        <Stack sx={{ width: 1 }}>{children}</Stack>
      </StyledContent>
    </StyledRoot>
  );
}

import React from 'react';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';
import { type RootState } from '../../../core';

import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

export const useAccountEmail = () => {
  const accountStore = useSelector((state: RootState) => state.account);
  const userAccount = accountStore.user;

  return userAccount.email;
};

const ICONS = {
  user: icon('ic_user'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_chat'),
  dashboard: icon('ic_dashboard'),
  ranges: icon('ic_menu_item'),
  recommendations: icon('ic_file'),
  settings: icon('ic_setting'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'GENERAL',
    items: [
      { title: 'Usuarios', path: PATH_DASHBOARD.users, icon: ICONS.user },
      //      { title: "Reports", path: PATH_DASHBOARD.reports, icon: ICONS.ecommerce },
      { title: 'Actividad', path: PATH_DASHBOARD.activity, icon: ICONS.analytics },
      //{ title: 'Rangos', path: PATH_DASHBOARD.ranges.root, icon: ICONS.ranges },
      //{
      //  title: 'Recomendaciones',
      //  path: PATH_DASHBOARD.recommendations.root,
      //  icon: ICONS.recommendations,
      //},
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'Cuenta',
    items: [
      {
        title: 'Configuración',
        path: PATH_DASHBOARD.profile.root,
        icon: ICONS.settings,
      },
    ],
  },
];

export default navConfig;

import React, { type SyntheticEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from '../../core';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useSnackbar } from 'notistack';

// MUI
import { Stack, Button, InputAdornment, IconButton, Card, Typography } from '@mui/material';

// Components
import FormProvider, { RHFTextField } from '../../components/hook-form';
import { CustomAvatar } from '../../components/custom-avatar';
import Iconify from 'components/iconify/Iconify';

// Variables
import { API_URL } from '../../constants/api';

// --------
interface FormValuesProps {
  email: string;
}

export default function InvitePatientsForm() {
  const [Invites, setInvites] = useState<FormValuesProps[]>([]);

  const navigate = useNavigate();

  const authState = useSelector((state: RootState) => state.auth);
  const auth = authState.auth;

  const InviteUsersSchema = Yup.object().shape({
    email: Yup.string().required(),
  });

  type FormValuesProps = Yup.InferType<typeof InviteUsersSchema>;

  const { enqueueSnackbar } = useSnackbar();

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(InviteUsersSchema),
    defaultValues: {
      email: '',
    },
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors },
  } = methods;

  const handleAddEmail = async (data: FormValuesProps) => {
    try {
      setInvites([...Invites, data]);
      reset();
    } catch (error) {
      console.log(error);
    }
  };

  const clickHandler = () => {
    handleSubmit(handleAddEmail)();
  };

  const handleRemoveEmail = (email: FormValuesProps) => {
    const newInvites = Invites.filter(item => item.email !== email.email);
    setInvites(newInvites);
  };

  const sendInvites = () => {
    const data = Invites.map(contact => {
      return contact.email;
    });

    axios
      .post(
        `${API_URL}admin/patient-invitation`,
        { data },
        {
          headers: {
            Authorization: `Bearer ${auth}`,
            'Content-Type': 'application/json',
          },
        },
      )
      .then(response => {
        enqueueSnackbar('Invitaciones enviadas');

        setInvites([]);
      })
      .catch(error => {
        console.log(error);
        console.log(error.message);
      });
  };

  return (
    <>
      <Stack spacing={3}>
        <FormProvider methods={methods} onSubmit={handleSubmit(handleAddEmail)}>
          <RHFTextField
            name="email"
            label="Contacto"
            placeholder="Correo o teléfono"
            helperText="Los que agregues a la lista recibirán una invitación para conectar 
            su cuenta"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button variant="contained" color="inherit" onClick={clickHandler}>
                    Agregar
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </FormProvider>
        {Invites.length > 0 && (
          <Card sx={{ p: 3, textAlign: 'left' }}>
            <Typography variant="h6">Pacientes Agregados</Typography>
            <Typography variant="body2">
              Has agregado a {Invites.length} {Invites.length === 1 ? 'paciente' : 'pacientes'}
            </Typography>

            <Stack spacing={2} sx={{ mt: 2 }}>
              {Invites.map((email, index) => (
                <Stack direction="row" sx={{ alignItems: 'center' }} key={index}>
                  <CustomAvatar alt={email.email} name={email.email} />

                  <Typography variant="body1" sx={{ marginLeft: 2 }}>
                    {email.email}
                  </Typography>
                  <IconButton
                    edge="end"
                    sx={{ marginLeft: 'auto' }}
                    onClick={e => {
                      handleRemoveEmail(email);
                    }}>
                    <Iconify icon="ph:x-bold" />
                  </IconButton>
                </Stack>
              ))}
            </Stack>
          </Card>
        )}

        <Button
          variant="contained"
          color="primary"
          fullWidth
          size="large"
          onClick={sendInvites}
          disabled={Invites.length < 1}>
          Enviar invitaciones
        </Button>
      </Stack>
    </>
  );
}

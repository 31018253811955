import { createModel } from '@rematch/core';
import { type RootModel } from '.';

export const account = createModel<RootModel>()({
  state: {
    user: {
      id: '',
      email: '',
      name: '',
      lastName: '',
      photoURL: 'https://res.cloudinary.com/siempresomos/image/upload/v1683228294/defaulUser.png',
      organization: '',
    },
    settings: {
      isEmpty: false,
    },
    admin: false,
  },
  reducers: {
    storeAccountInfo(state, payload: any) {
      return {
        ...state,
        user: {
          ...state.user,
          ...payload,
        },
      };
    },
    setEmptyState(state, payload: any) {
      return {
        ...state,
        settings: {
          ...state.settings,
          isEmpty: payload,
        },
      };
    },
    setAdminStatus(state, payload: any) {
      return {
        ...state,
        admin: payload,
      };
    },
  },
  effects: dispatch => ({
    async getAccountInfo(payload) {
      dispatch.account.storeAccountInfo(payload);
    },
    async getEmptyState(payload) {
      dispatch.account.setEmptyState(payload);
    },
    async getAdminStatus(payload) {
      dispatch.account.setAdminStatus(payload);
    },
  }),
});

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';

import { GLOBAL } from '../../../../constants/variables';
import { API_URL } from 'constants/api';
import Chart, { useChart } from '../../../../components/chart';
import Iconify from '../../../../components/iconify';

import { format } from 'date-fns';
import moment from 'moment';

import {
  TextField,
  Card,
  Tab,
  Tabs,
  Container,
  Divider,
  Button,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
} from '@mui/material';
import {
  TableHeadCustom,
  useTable,
  TableNoData,
  TableEmptyRows,
  TablePaginationCustom,
} from '../../../../components/table';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import IndicatorsTableToolbar from './IndicatorsTableToolbar';
import Label, { type LabelColor } from '../../../../components/label';

import api from '../../../../services/api';

// TODO: move to static global stylesheet

const useStyles = makeStyles(theme => ({
  hoverRow: {
    '&:hover': {
      backgroundColor: '#F4F6F8',
      cursor: 'pointer',
    },
  },
  circleIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',
    float: 'left',
    height: '30px',
    borderRadius: '50%',
    color: 'white',
    '&.critico': {
      backgroundColor: 'red',
    },
    '&.optimo': {
      backgroundColor: 'green',
    },
    '&.default': {
      backgroundColor: 'orange',
    },
  },
  text: {
    marginLeft: '50px',
    display: 'flex', // Add display flex
    alignItems: 'center', // Align items vertically in the flex container
  },
}));

const StyledIcon = styled(Iconify)(({ theme }) => ({
  width: '20px',
  height: '20px',
  color: 'white',
}));

const TABLE_HEAD = [
  { id: 'name', label: 'Nombre' },
  { id: 'value', label: 'Valor', align: 'right' },
  { id: 'unit', label: 'Unidades', align: 'right' },
  { id: 'change', label: '% de cambio', align: 'right' },
  { id: 'created_at', label: 'Fecha', align: 'right' },
  { id: 'status', label: 'Estatus', align: 'right' },
  { id: 'range', label: 'Rango', align: 'right' },
];

const STATUS_OPTIONS = [
  {
    label: 'Todos',
    color: 'info',
    value: '',
  },
  {
    label: 'Optimo',
    color: 'primary',
    value: 'optimo',
  },
  {
    label: 'En Rango',
    color: 'warning',
    value: 'aceptable',
  },
  {
    label: 'Fuera De Rango',
    color: 'error',
    value: 'critico',
  },
];
interface IndicatorData {
  _id: string;
  name: string;
  value: string;
  unit: number;
  range: string;
  created_at: string;
  template_indicator_id: string;
  range_title?: string;
  percentageChange: number;
}

export interface UserTests {
  _id: string;
  name: string;
  type: string;
  category: string;
  date: string;
  user: string;
  image: string;
}

const getStatus = (range: string) => {
  switch (range) {
    case '1':
    case 'm1':
    case 'f1':
      return 'Muy Bajo';
    case '5':
    case 'm5':
    case 'f5':
      return 'Muy Alto';
    case '2':
    case 'm2':
    case 'f2':
      return 'Bajo';
    case '4':
    case 'm4':
    case 'f4':
      return 'Alto';
    case '3':
    case 'm3':
    case 'f3':
      return 'Optimo';
    default:
      return range; // Add a default case for unknown values
  }
};

export default function IndicatorListPage() {
  const classes = useStyles();
  const { userId } = useParams(); // Get the user userIdrom the URL
  const token = localStorage.getItem(GLOBAL.TOKEN_KEY);
  const [tableData, setTableData] = useState<IndicatorData[]>([]);
  const [loading, setLoading] = useState(true);
  const [filterName, setFilterName] = useState('');
  const [filterStatus, setFilterStatus] = useState('Todos');
  const [totalPages, setTotalPages] = useState(0); // state variable for total pages
  const [totalRows, setTotalRows] = useState(0); // state variable for total rows
  const [userTests, setUserTests] = useState<UserTests[]>([]); // state variable for total rows

  const [selectedTest, setSelectedTest] = useState<string | null>(null);

  const [aceptableCount, setAceptableCount] = useState(0);
  const [criticoCount, setCriticoCount] = useState(0);
  const [optimoCount, setOptimoCount] = useState(0);
  const [calibrandoCount, setCalibrandoCount] = useState(0);
  const [totalStatusCount, setTotalStatusCount] = useState(0);

  const {
    page,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const navigate = useNavigate();

  const isFiltered = filterName !== '';

  useEffect(() => {
    const urlIndicatorString = createUrlIndicatorString();
    fetchIndicatorData(urlIndicatorString);

    const urlStatusString = createUrlStatusString();
    fetchIndicatorStatusCount(urlStatusString);
  }, [filterName, page, filterStatus, selectedTest]);

  useEffect(() => {
    const urlTestString = `${API_URL}test/${userId}?page=1`;
    fetchTestData(urlTestString);
  }, []);

  // == Helper functions ===

  const createUrlIndicatorString = (): string => {
    let urlIndicatorString: string;

    if (selectedTest) {
      urlIndicatorString = `test/indicator/${selectedTest}?page=${page + 1}`;
    } else {
      urlIndicatorString = `users/indicators/${userId}?page=${page + 1}`;
    }

    if (filterName && filterName.trim() !== '') {
      urlIndicatorString = urlIndicatorString + '&name=' + filterName;
    }

    if (filterStatus && filterStatus.trim() !== '') {
      let range = '';
      switch (filterStatus) {
        case 'Optimo':
          range = 'Optimo';
          break;
        case 'En Rango':
          range = 'Aceptable';
          break;
        case 'Fuera De Rango':
          range = 'Critico';
          break;
        default:
          range;
      }

      urlIndicatorString = urlIndicatorString + '&range=' + range;
    }

    return urlIndicatorString;
  };

  const createUrlStatusString = (): string => {
    let urlStatusString: string;

    if (selectedTest) {
      urlStatusString = `${API_URL}users/indicators/range-count/${userId}?testId=${selectedTest}`;
    } else {
      urlStatusString = `${API_URL}users/indicators/range-count/${userId}`;
    }
    return urlStatusString;
  };

  // ===== API calls =====
  const fetchIndicatorData = async (urlIndicatorString: string) => {
    await api
      .get(urlIndicatorString)
      .then(indicatorResults => {
        console.log('IndicatorResults', indicatorResults.data);
        const data = indicatorResults.data.data;
        setTableData(data);
        setTotalRows(indicatorResults.data.count);
        setTotalPages(Math.ceil(indicatorResults.data.count / 20)); // assuming that you're displaying 20 rows per page
      })
      .catch(err => {
        console.log('error', err);
      });
  };

  const fetchIndicatorStatusCount = async (urlStatusString: string) => {
    // console.log('urlStatusString ---- ', urlStatusString);
    await api
      .get(urlStatusString)
      .then(statusResults => {
        setAceptableCount(statusResults.data.aceptable);
        setCriticoCount(statusResults.data.critico);
        setOptimoCount(statusResults.data.optimo);
        setTotalStatusCount(statusResults.data.todos);
      })
      .catch(err => {
        console.log('error', err);
      });
  };

  const fetchTestData = async (urlTestString: string) => {
    await api
      .get(urlTestString)
      .then(indicatorResults => {
        //console.log('indicatorTestResults ----- ', indicatorResults.data);
        setUserTests(indicatorResults.data);
      })
      .catch(err => {
        console.log('error', err);
      });
  };

  const onDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedId = event.target.value;
    // const selected = userTests.find(test => test._id === selectedId);
    console.log('selectedId ---', selectedId);
    setSelectedTest(selectedId);
  };

  const handleFilterStatus = (event: React.SyntheticEvent<Element, Event>, newValue: string) => {
    // setPage(0);
    setFilterStatus(newValue);
  };

  const handleResetFilter = () => {
    setFilterName('');
    setFilterStatus('Todos');
  };

  const handleFilterName = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setPage(0);
    setFilterName(event.target.value);
  };

  const handleIndicatorNavigation = (templateID: string) => {
    navigate(templateID);
  };

  const isNotFound = !tableData.length;

  return (
    <>
      <Card>
        <h3 style={{ paddingTop: '20px', paddingLeft: '20px', paddingBottom: '20px' }}>
          Indicadores
        </h3>

        <Tabs
          value={filterStatus}
          onChange={handleFilterStatus}
          sx={{
            px: 2,
            bgcolor: 'background.neutral',
          }}>
          {STATUS_OPTIONS.map(tab => (
            <Tab
              key={tab.label}
              label={tab.label}
              value={tab.label}
              icon={
                <Label color={(tab.color as LabelColor) ?? 'default'}>
                  {tab.label === 'En Rango' && aceptableCount}
                  {tab.label === 'Fuera De Rango' && criticoCount}
                  {tab.label === 'Optimo' && optimoCount}
                  {tab.label === 'Todos' && totalStatusCount}
                </Label>
              }
            />
          ))}
        </Tabs>

        <Divider />
        <IndicatorsTableToolbar
          isFiltered={isFiltered}
          filterName={filterName}
          onFilterName={handleFilterName}
          onResetFilter={handleResetFilter}
          userTests={userTests}
          onDateChange={onDateChange}
          selectedTest={selectedTest}
        />

        <TableContainer sx={{ mt: 3, overflow: 'unset' }}>
          <Table sx={{ minWidth: 800 }}>
            <TableHeadCustom headLabel={TABLE_HEAD} />

            <TableBody>
              {tableData?.map(indicator => (
                <TableRow
                  key={indicator._id}
                  className={classes.hoverRow}
                  onClick={() => handleIndicatorNavigation(indicator.template_indicator_id)}>
                  <TableCell>{indicator.name} </TableCell>
                  <TableCell align="right">
                    {indicator.value ? `${indicator.value} ` : '-'}{' '}
                  </TableCell>
                  <TableCell align="right">{indicator.unit ? `${indicator.unit}` : '-'}</TableCell>
                  <TableCell align="right" sx={{ color: 'success' }}>
                    {indicator.percentageChange ? Math.round(indicator.percentageChange) : 0}%
                  </TableCell>
                  <TableCell align="right">
                    {indicator.created_at ? moment(indicator.created_at).format('D MMM Y') : '-'}
                  </TableCell>
                  <TableCell align="right">
                    <Label
                      variant="soft"
                      color={
                        getStatus(indicator.range) === 'Optimo'
                          ? 'success'
                          : getStatus(indicator.range) === 'Bajo' ||
                            getStatus(indicator.range) === 'Alto'
                          ? 'warning'
                          : 'error'
                      }
                      sx={{
                        textTransform: 'capitalize',
                        backgroundColor:
                          getStatus(indicator.range) === 'Aceptable'
                            ? 'rgba(255, 171, 0, 0.16)'
                            : undefined,
                        color: getStatus(indicator.range) === 'Aceptable' ? '#D28438' : undefined,
                      }}>
                      {getStatus(indicator.range)}
                    </Label>
                  </TableCell>

                  <TableCell align="right">
                    {indicator.range_title ? indicator.range_title : '-'}
                  </TableCell>
                </TableRow>
              ))}

              <TableNoData isNotFound={isNotFound} />
            </TableBody>
          </Table>
        </TableContainer>

        <TablePaginationCustom
          count={totalRows}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={onChangePage}
          //
        />
      </Card>
    </>
  );
}

import React from 'react';

// @types
import { type IUserProfileAbout } from '../../../../../@types/user';

// components
import Iconify from '../../../../../components/iconify';

// @mui
import { Link, Card, Typography, CardHeader, Stack } from '@mui/material';

import { styled } from '@mui/material/styles';

// ----------------------------------------------------------------------

const StyledIcon = styled(Iconify)(({ theme }) => ({
  width: 20,
  height: 20,
  marginTop: 1,
  flexShrink: 0,
  marginRight: theme.spacing(2),
}));

// ----------------------------------------------------------------------

export default function ProfileAbout({ name, email, genere, phone, cgmMethod }: IUserProfileAbout) {
  return (
    <Card>
      <CardHeader title="User Info" />
      <Stack spacing={2} sx={{ p: 3 }}>
        <Stack direction="row">
          <StyledIcon icon="material-symbols:person" />

          <Typography variant="body2">
            Nombre: &nbsp;
            <Link component="span" variant="subtitle2" color="text.primary">
              {name}
            </Link>
          </Typography>
        </Stack>

        <Stack direction="row">
          <StyledIcon icon="eva:email-fill" />
          <Typography variant="body2">
            Correo: &nbsp;
            <Link component="span" variant="subtitle2" color="text.primary">
              {email}
            </Link>
          </Typography>
        </Stack>

        <Stack direction="row">
          <StyledIcon icon="material-symbols:phone-enabled" />

          <Typography variant="body2">
            Teléfono: &nbsp;
            <Link component="span" variant="subtitle2" color="text.primary">
              {phone}
            </Link>
          </Typography>
        </Stack>
        <Stack direction="row">
          <StyledIcon icon="mdi:people" />
          <Typography variant="body2">
            Género: &nbsp;
            <Link component="span" variant="subtitle2" color="text.primary">
              {genere}
            </Link>
          </Typography>
        </Stack>
        <Stack direction="row">
          <StyledIcon icon="heroicons:signal-20-solid" />
          <Typography variant="body2">
            Método de conexión: &nbsp;
            <Link component="span" variant="subtitle2" color="text.primary">
              {cgmMethod}
            </Link>
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
}

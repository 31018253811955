import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { type RootState } from '../../core';

import * as Yup from 'yup';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// @mui
import { Stack, Alert, Button, MenuItem, Divider } from '@mui/material';

// components
import Iconify from '../../components/iconify';
import FormProvider, { RHFTextField, RHFSelect } from '../../components/hook-form';

// API
import api from '../../services/api';

// variables
import { PATH_ONBOARDING } from '../../routes/paths';

// Analytics
import { trackOrganizationCreated } from '../../services/analytics';
// ----------------------------------------------------------------------

interface FormValuesProps {
  name: string;
  lastname: string;
  organization: string;
  size?: string;
  afterSubmit?: string;
}

export default function AuthRegisterForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accountState = useSelector((state: RootState) => state.account);
  const user = accountState.user;

  const authState = useSelector((state: RootState) => state.auth);
  const auth = authState.auth;

  const { enqueueSnackbar } = useSnackbar();

  const RegisterSchema = Yup.object().shape({
    name: Yup.string().required('Nececitamos tu nombre para crear la cuenta'),
    lastname: Yup.string().required('Nececitamos tu apellido para crear la cuenta'),
    organization: Yup.string().required(
      'Nececitamos el nombre de tu organización para crear la cuenta',
    ),
  });

  const defaultValues = {
    name: '',
    lastname: '',
    organization: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  const STATUS_OPTIONS = ['Soy solo yo', '2 a 10', '10 a 20', '20 a 50', '50+'];

  const onSubmit = async (data: FormValuesProps) => {
    const formData = {
      name: data.organization,
      user: {
        first_name: data.name,
        last_name: data.lastname,
      },
    };

    try {
      const response = await api.post(`admin/organization/${user.id}`, formData);

      // update account to include organization info.
      dispatch.account.getAccountInfo({
        organization: formData.name,
        name: formData.user.first_name,
        lastName: formData.user.last_name,
      });

      trackOrganizationCreated({
        name: data.organization,
        size: data.size,
      });

      // show success message
      enqueueSnackbar('Tu organización ha sido creada');

      navigate(PATH_ONBOARDING.invite);
    } catch (error: any) {
      console.log(error);
      reset();
      setError('afterSubmit', {
        ...error,
        message: 'Una organización con ese nombre ya existe.',
      });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2.5}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <RHFTextField name="name" label="Nombre" placeholder="Escribe tu nombre" />

        <RHFTextField name="lastname" label="Apellido" placeholder="Escribe tu apellido" />

        <RHFTextField
          name="organization"
          label="Nombre de tu organización"
          placeholder="Algunas ponen su propio nombre o el de su equipo"
        />

        <RHFSelect name="size" label="Tamaño de la organización" sx={{ textAlign: 'left' }}>
          <MenuItem value="">Selecciona una opción</MenuItem>
          <Divider sx={{ borderStyle: 'dashed' }} />
          {STATUS_OPTIONS.map(option => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </RHFSelect>

        <Button fullWidth size="large" type="submit" variant="contained">
          Continuar
        </Button>
      </Stack>
    </FormProvider>
  );
}

import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import type { RootState } from '../../../../core';

import { useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';
import moment from 'moment';
import * as d3 from 'd3';

// MUI
import {
  Card,
  Grid,
  Container,
  CardHeader,
  CardContent,
  Stack,
  Box,
  Typography,
  Button,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

// Styles
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import classnames from 'classnames';

// Local imports
import { GLOBAL } from '../../../../constants/variables';
import { API_URL } from 'constants/api';

// Components
import Iconify from '../../../../components/iconify';
import Label from '../../../../components/label';
import { DateFilter } from '../../../../components/date-filter';
import WidgetSummary from './WidgetSummary';
import EmptyContent from '../../../../components/empty-content';
import { type Filter } from '../../../../components/date-filter/types';

// API
import api from '../../../../services/api';
// ----------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  circleIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    color: 'white',
    '&.critico': {
      backgroundColor: '#EA5944',
    },
    '&.optimo': {
      backgroundColor: '#00AD69',
    },
    '&.default': {
      backgroundColor: '#54565A',
    },
    '&.aceptable': {
      backgroundColor: '#FFA043',
    },
  },
}));

const StyledIcon = styled(Iconify)(({ theme }) => ({
  width: '20px',
  height: '20px',
  color: 'white',
}));

const MemoizedWidgetSummary = React.memo(WidgetSummary);

//const CanvasJSChart = CanvasJSReact.CanvasJSChart;

interface GlucoseData {
  blood_glucose_mg_per_dL: number;
  timestamp: string;
}

interface GlucoseDetail {
  promedio: {
    value: number;
    rango: string;
  };
  enRango: {
    value: number;
    rango: string;
  };
  picos: {
    value: number;
    rango: string;
  };
  cv: {
    value: number;
    rango: string;
  };
}

interface EventData {
  category: string;
  date: string;
  title: string;
  user_id: string;
  id: string;
  is_seen: boolean;
  score: string;
  glucoseRange: string;
  glucoseSpike: number;
}

interface GlucoseRange {
  minimum: number;
  acceptable: number;
  maximum: number;
}

const D3LineChart = ({ data, glucoseRange }: { data: GlucoseData[]; glucoseRange: any }) => {
  const ref = useRef<SVGSVGElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  // Spanish locale definition
  const spanishLocale = d3.timeFormatLocale({
    dateTime: '%A, el %e de %B de %Y, %X',
    date: '%d/%m/%Y',
    time: '%H:%M:%S',
    periods: ['AM', 'PM'],
    days: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
    shortDays: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
    months: [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ],
    shortMonths: [
      'Ene',
      'Feb',
      'Mar',
      'Abr',
      'May',
      'Jun',
      'Jul',
      'Ago',
      'Sep',
      'Oct',
      'Nov',
      'Dic',
    ],
  });

  // Create a formatter using the Spanish locale
  const formatTime = spanishLocale.format('%a');

  useEffect(() => {
    // Function to determine the stroke color based on the glucose level
    const getStrokeColor = (glucoseLevel: number) => {
      if (glucoseLevel > glucoseRange.maximum) return 'red';
      if (glucoseLevel > glucoseRange.acceptable) return 'orange';
      if (glucoseLevel < glucoseRange.minimum) return 'red';

      return '#00AC69'; // Default color
    };

    if (data.length === 0 || !ref.current || !ref.current.parentElement) return;

    const svgWidth = ref.current.parentElement.clientWidth;
    const svgHeight = 400;

    const margin = { top: 10, right: 50, bottom: 30, left: -20 },
      width = svgWidth - margin.left - margin.right,
      height = svgHeight - margin.top - margin.bottom;

    const svg = d3.select(ref.current).attr('width', svgWidth).attr('height', svgHeight);

    svg.selectAll('*').remove();

    const x = d3
      .scaleTime()
      .domain(d3.extent(data, d => new Date(d.timestamp)) as [Date, Date])
      .range([0, width]);

    // const y = d3.scaleLinear().domain([50, 170]).range([height, 0]);
    const y = d3
      .scaleLinear()
      .domain([50, glucoseRange.maximum + 20])
      .range([height, 0]);

    svg
      .append('rect')
      .attr('x', 0)
      .attr('y', y(glucoseRange.acceptable)) // Start from the top of the range
      .attr('width', width)
      .attr('height', y(glucoseRange.minimum) - y(glucoseRange.acceptable)) // Height is the difference between the y values
      .attr('fill', '#00AC691A') // Color with opacity
      .style('opacity', 0.9); // Set the opacity to 10%

    svg
      .append('rect')
      .attr('x', 0)
      .attr('y', y(140)) // Start from the top of the range
      .attr('width', width)
      .attr('height', y(glucoseRange.acceptable) - y(glucoseRange.maximum)) // Height is the difference between the y values
      .attr('fill', '#00AC691A') // Color with opacity
      .style('opacity', 0.3); // Set the opacity to 5%

    const line = d3
      .line<GlucoseData>()
      .x(d => x(new Date(d.timestamp)))
      .y(d => y(d.blood_glucose_mg_per_dL));

    // Option A: Draw a single line one color
    /*  
    svg.append("path")
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", "#00AC69")
      .attr("stroke-width", 2)
      .attr("d", line);
      */

    // Option B: Draw a single line colored based on the glucose level
    // For coloring the line out of the ranges use this. It draws individual line segments for each pair of points
    for (let i = 0; i < data.length - 1; i++) {
      svg
        .append('line')
        .attr('x1', x(new Date(data[i].timestamp)))
        .attr('y1', y(data[i].blood_glucose_mg_per_dL))
        .attr('x2', x(new Date(data[i + 1].timestamp)))
        .attr('y2', y(data[i + 1].blood_glucose_mg_per_dL))
        .attr('stroke', getStrokeColor(data[i].blood_glucose_mg_per_dL))
        .attr('stroke-width', 2);
    }

    // Append a tooltip element to the DOM
    const tooltip = d3
      .select(tooltipRef.current)
      .style('opacity', 0)
      .attr('class', 'tooltip')
      .style('background-color', 'black')
      .style('color', 'white')
      .style('border', 'solid')
      .style('border-color', 'white')
      .style('border-width', '1px')
      .style('border-radius', '5px')
      .style('padding', '5px')
      .style('position', 'absolute')
      .style('pointer-events', 'none')
      .style('z-index', '10');

    // Calculate position of tooltip relative to the SVG container
    function getRelativePosition(event: MouseEvent) {
      const svgRect = ref.current?.getBoundingClientRect();
      return {
        x: event.clientX - (svgRect?.left ?? 0),
        y: event.clientY - (svgRect?.top ?? 0),
      };
    }

    // Add invisible circles for tooltip interaction
    svg
      .selectAll('.line-point')
      .data(data)
      .enter()
      .append('circle')
      .attr('class', 'line-point')
      .attr('cx', d => x(new Date(d.timestamp)))
      .attr('cy', d => y(d.blood_glucose_mg_per_dL))
      .attr('r', 5) // The radius of the circle, invisible if set to 0
      .style('opacity', 0) // Hide the circle by setting the opacity to 0
      .on('mouseover', function (event: MouseEvent, d) {
        d3.select(this)
          .style('opacity', 1) // Make the circle visible
          .style('fill', '#00AC69'); // Change the fill color to make it stand out

        tooltip.style('opacity', 1);
        tooltip
          .html(
            `<div style='display: flex; align-items: center; padding: 5px;'>
             <div>
               <div style='font-weight:bold;'>${d.blood_glucose_mg_per_dL}</div>
               <div style='font-size: smaller;'>mg/dL</div>
             </div>
             <div style='border-left: 1px solid white; height: 30px; margin: 0 10px;'></div>
             <div>${d3.timeFormat('%I:%M %p')(new Date(d.timestamp))}</div>
           </div>`,
          )

          .style('left', event.pageX + 5 + 'px')
          .style('top', event.pageY - 28 + 'px');
      })
      .on('mousemove', function (event: MouseEvent, d) {
        const relativePosition = getRelativePosition(event);
        tooltip
          .html(
            `<div style='display: flex; align-items: center; padding: 5px;'>
               <div>
                 <div style='font-weight:bold;'>${d.blood_glucose_mg_per_dL}</div>
                 <div style='font-size: smaller;'>mg/dL</div>
               </div>
               <div style='border-left: 1px solid white; height: 30px; margin: 0 10px;'></div>
               <div>${d3.timeFormat('%I:%M %p')(new Date(d.timestamp))}</div>
             </div>`,
          )
          .style('left', relativePosition.x + 10 + 'px')
          .style('top', relativePosition.y - 10 + 'px');
      })
      .on('mouseout', function () {
        d3.select(this).style('opacity', 0).style('fill', 'none');

        tooltip.style('opacity', 0);
      });

    // Add the X Axis
    svg
      .append('g')
      .attr('transform', `translate(0,${height})`)
      .call(d3.axisBottom(x))
      .attr('color', '#637381')
      .style('font-size', '12px');
    //    const yAxisRight = d3.axisRight(y).tickValues(d3.range(50, 180, 20)); // Set the ticks to be every 20 mg/dL, to do: dynamically set the range

    const yAxisRight = d3.axisRight(y).tickValues(d3.range(50, glucoseRange.maximum + 20 + 1, 20));

    // Add the Right Y Axis
    svg
      .append('g')
      .attr('transform', `translate(${width},0)`)
      .call(yAxisRight)
      .attr('color', '#637381')
      .style('font-size', '12px');
  }, [data]);

  return (
    <div>
      <svg ref={ref}></svg>
      <div ref={tooltipRef} className="tooltip"></div>
    </div>
  );
};

export default function ProfileGlucose() {
  const classes = useStyles();

  const [glucoseData, setGlucoseData] = useState<GlucoseData[]>([]);
  const [glucoseDetail, setGlucoseDetail] = useState<GlucoseDetail | null>(null);
  //const [glucoseRange, setGlucoseRange] = useState({ minimum: 70, acceptable: 110, maximum: 140 });
  const [glucoseRange, setGlucoseRange] = useState<GlucoseRange>({
    minimum: 70,
    acceptable: 110,
    maximum: 140,
  });

  const [eventData, setEventData] = useState<EventData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const userState = useSelector((state: RootState) => state.user);
  //  const filter: Filter = userState.settings.filter;

  const navigate = useNavigate();

  const defaultFilterValue = {
    type: 'days',
    days: 3,
  };
  const filter: Filter = userState?.settings?.filter ?? defaultFilterValue;

  useEffect(() => {
    setLoading(true);

    let urlMetricsString: string;
    let urlEventString: string;
    let averageParam = '';
    let urlUserSetting = '';

    // Include average=true parameter for days filter for greater than 30 days. This is to recieve the average glucose reading per day to make the graph smoother

    if (filter && filter.type === 'days' && (filter.days === 30 || filter.days === 90)) {
      averageParam = '&average=true';
    }
    if (filter && filter.type === 'date') {
      const startDate = moment(filter.date?.start);
      const endDate = moment(filter.date?.end);
      const duration = moment.duration(endDate.diff(startDate));
      if (duration.asMonths() > 1) {
        // Include average=true parameter for date filter when duration is more than a month
        averageParam = '&average=true';
      }
    }

    if (filter && filter.type === 'days') {
      // get glucose data
      urlMetricsString = `${API_URL}glucose/metrics/${userState.user.id}?days=${filter.days}${averageParam}`;

      // get events data
      urlEventString = `${API_URL}events/user/${userState.user.id}?days=${filter.days}${averageParam}`;
    } else {
      // get glucose data
      urlMetricsString = `${API_URL}glucose/metrics/${userState.user.id}?dategte=${filter.date?.start}&datelte=${filter.date?.end}${averageParam}`;

      // get events data
      urlEventString = `${API_URL}events/user/${userState.user.id}?dategte=${filter.date?.start}&datelte=${filter.date?.end}${averageParam}`;
    }
    //console.log("filter", filter)
    const fetchGlucoseData = async () => {
      const res = await api.get(urlMetricsString);
      return res.data;
    };

    const fetchEventsData = async () => {
      const eventResults = await api.get(urlEventString);
      return eventResults.data;
    };

    urlUserSetting = `${API_URL}user-settings/${userState.user.id}`;

    const fetchUserSettings = async () => {
      const settingsResults = await api.get(urlUserSetting);
      //console.log("settingsResults.data", settingsResults.data)
      return settingsResults.data;
    };

    Promise.all([fetchGlucoseData(), fetchEventsData(), fetchUserSettings()])
      .then(([glucoseRes, eventRes, settingsRes]) => {
        setGlucoseData(glucoseRes.graphGlucose);
        // console.log("glucoseRes.graphGlucose", glucoseRes.graphGlucose)
        setGlucoseDetail(glucoseRes.glucoseDetail);
        setEventData(eventRes.data);
        setLoading(false);

        if (settingsRes) {
          setGlucoseRange({
            minimum: settingsRes.minimum_glucose || 70,
            acceptable: settingsRes.acceptable_glucose || 110,
            maximum: settingsRes.maximum_glucose || 140,
          });
        }
      })
      .catch(err => {
        console.error('Error while fetching data: ', err);
        setLoading(false);
      });
  }, [filter]);

  useEffect(() => {
    if (glucoseData && glucoseData.length > 0) {
      const dataPoints = glucoseData.map(data => ({
        x: new Date(data.timestamp),
        y: data.blood_glucose_mg_per_dL,
      }));
    }
  }, [glucoseData]);

  const [options, setOptions] = useState({});

  const handleEventsNavigation = (eventId?: string) => {
    if (eventId) {
      navigate(`/dashboard/user/${userState.user.id}/events/${eventId}`);
    } else {
      navigate(`/dashboard/user/${userState.user.id}/events`);
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="30vh" // This will fill the screen, adjust according to your needs
      >
        <Typography variant="h6" component="h2" sx={{ marginBottom: '30px' }}>
          {' '}
          Cargando...
        </Typography>
        <CircularProgress size={60} />
      </Box>
    );
  } else {
    return (
      <>
        <DateFilter />
        <div id="profile-content">
          {glucoseData.length === 0 && eventData.length === 0 ? (
            <EmptyContent title="Sin Data" sx={{ '& span.MuiBox-root': { height: 160 } }} />
          ) : (
            <>
              {/* Render the glucoseDetail information */}
              {glucoseDetail && (
                <Grid container spacing={3} sx={{ marginTop: '1px', marginBottom: '20px' }}>
                  <Grid item xs={12} sm={6} md={3}>
                    <MemoizedWidgetSummary
                      units="mg/dL"
                      title="Glucosa"
                      total={glucoseDetail.promedio.value}
                      color={
                        glucoseDetail.promedio.rango === 'Optimo'
                          ? 'secondary'
                          : glucoseDetail.promedio.rango === 'Aceptable'
                          ? 'warning'
                          : 'error'
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <MemoizedWidgetSummary
                      units="%"
                      title="Tiempo en Rango"
                      total={glucoseDetail.enRango.value}
                      color={
                        glucoseDetail.enRango.rango === 'Optimo'
                          ? 'secondary'
                          : glucoseDetail.enRango.rango === 'Aceptable'
                          ? 'warning'
                          : 'error'
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <MemoizedWidgetSummary
                      units="picos al dia"
                      title="Picos por dia"
                      total={Math.round(glucoseDetail.picos.value)}
                      color={
                        glucoseDetail.picos.rango === 'Optimo'
                          ? 'secondary'
                          : glucoseDetail.picos.rango === 'Aceptable'
                          ? 'warning'
                          : 'error'
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <MemoizedWidgetSummary
                      units="%"
                      title="Variabilidad"
                      total={glucoseDetail.cv.value}
                      color={
                        glucoseDetail.cv.rango === 'Optimo'
                          ? 'secondary'
                          : glucoseDetail.cv.rango === 'Aceptable'
                          ? 'warning'
                          : 'error'
                      }
                    />
                  </Grid>
                </Grid>
              )}

              <Grid container spacing={3}>
                {/* Render the event data */}
                <Grid item xs={12} md={4} lg={4}>
                  <Card>
                    <CardHeader
                      title="Eventos"
                      subheader={
                        filter.days === 1
                          ? 'Hoy'
                          : filter.type === 'date'
                          ? `Del ${dayjs(filter.date?.start).format('D MMM') ?? ''} - ${
                              dayjs(filter.date?.end).format('D MMM, YYYY') ?? ''
                            }`
                          : `Los últimos ${filter.days} días`
                      }
                    />

                    <Stack spacing={1} p={2}>
                      {eventData
                        ? eventData.map((event, index) => (
                            <Stack
                              direction="row"
                              alignItems="center"
                              key={index}
                              onClick={() => handleEventsNavigation(event.id)}
                              sx={{
                                cursor: 'pointer',
                                '&:hover': { backgroundColor: '#F4F6F8' },
                                p: 2,
                              }}>
                              <div
                                className={classnames(classes.circleIconWrapper, {
                                  critico: event.score === 'Critico',
                                  optimo: event.score === 'Optimo',
                                  aceptable: event.score === 'Aceptable',
                                  default: event.score === 'Empty' || event.score === 'Calibrando',
                                })}>
                                {event.category === 'Ejercicio' ? (
                                  <StyledIcon icon="ic:baseline-directions-run" />
                                ) : event.category === 'Comida' ? (
                                  <StyledIcon icon="ic:baseline-restaurant-menu" />
                                ) : (
                                  <StyledIcon icon="ic:baseline-star" />
                                )}
                              </div>
                              <Box sx={{ flexGrow: 1, ml: 2, minWidth: 100 }}>
                                <Typography variant="subtitle2" sx={{ mb: 0.5 }} noWrap>
                                  {event.title
                                    ? event.title.length > 18
                                      ? event.title.substring(0, 18) + '...'
                                      : event.title
                                    : ''}
                                </Typography>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                                  {dayjs(event.date).format('D MMM - hh:mm a')}
                                </Typography>
                              </Box>
                              <Label
                                variant="soft"
                                color={
                                  (event.score === 'Optimo' && 'primary') ||
                                  (event.score === 'Aceptable' && 'warning') ||
                                  (event.score === 'Critico' && 'error') ||
                                  (event.score === 'Empty' && 'default') ||
                                  'default'
                                }
                                sx={{
                                  opacity: 0.5,
                                  fontSize: '14px',
                                  padding: '10px',
                                }}>
                                {event.glucoseSpike ? `${event.glucoseSpike} mg/dl` : 'calculando'}
                              </Label>
                            </Stack>
                          ))
                        : null}

                      <Button
                        variant="outlined"
                        size="large"
                        color="inherit"
                        sx={{ my: 2 }}
                        onClick={() => handleEventsNavigation()}>
                        Ver todos
                      </Button>
                    </Stack>
                  </Card>
                </Grid>

                {/* Render the chart */}
                <Grid item xs={12} md={8} lg={8}>
                  <Card dir="ltr">
                    <CardHeader
                      title={
                        filter.days === 1
                          ? 'Glucosa hoy'
                          : filter.type === 'date'
                          ? `Glucosa desde ${dayjs(filter.date?.start).format('D MMM') ?? ''} - ${
                              dayjs(filter.date?.end).format('D MMM, YYYY') ?? ''
                            }`
                          : `Glucosa en los últimos ${filter.days} días`
                      }
                    />
                    <CardContent>
                      <D3LineChart data={glucoseData} glucoseRange={glucoseRange} />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </>
          )}
        </div>
      </>
    );
  }
}

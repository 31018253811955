import React from 'react';

import { Link as RouterLink } from 'react-router-dom';

// @mui
import { Alert, Tooltip, Stack, Typography, Link, Box } from '@mui/material';

// components
import AuthWithPhone from './AuthWithPhone';

// routes
import { PATH_AUTH } from '../../routes/paths';
// layouts
import LoginLayout from '../../views/login';
//

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <LoginLayout>
      <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
        <Typography variant="h4">Entra a Somos</Typography>

        <Stack direction="row" spacing={0.5}>
          <Typography variant="body2">¿Nuevo Usuario</Typography>

          <Link href={PATH_AUTH.register} variant="body2" style={{ fontWeight: 700 }}>
            Crear una Cuenta
          </Link>
        </Stack>
      </Stack>

      <Stack spacing={2} sx={{ mb: 5 }}>
        <AuthWithPhone />
      </Stack>
    </LoginLayout>
  );
}

import React, { useState } from 'react';

// @mui
import { Stack, Button, Typography } from '@mui/material';

declare global {
  interface Window {
    Intercom: any;
  }
}

// ----------------------------------------------------------------------

export default function NavFooter() {
  const [openIntercom, setOpenIntercom] = useState(false);

  const launchIntercom = () => {
    if (!openIntercom) {
      window.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'zt3ewpwd',
      });

      window.Intercom('show');

      setOpenIntercom(true);
    } else {
      window.Intercom('hide');
      setOpenIntercom(false);
    }
  };

  return (
    <Stack
      spacing={3}
      sx={{
        px: 5,
        pb: 5,
        mt: 10,
        width: 1,
        display: 'block',
        textAlign: 'center',
      }}>
      <div>
        <Typography variant="body2">{'¿Te podemos ayudar con algo?'}</Typography>
      </div>

      <Button
        id="intercom_launcher"
        variant="contained"
        sx={{
          width: '80%',
        }}
        // onClick={launchIntercom}
      >
        {'Solicitar Ayuda'}
      </Button>
    </Stack>
  );
}

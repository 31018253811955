import { useState, useEffect, useCallback } from 'react';
import { paramCase } from 'change-case';
import { useNavigate } from 'react-router-dom';

import {
  Tab,
  Tabs,
  Card,
  Table,
  Button,
  Tooltip,
  Divider,
  Container,
  MenuItem,
  IconButton,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
} from '@mui/material';

import { PATH_DASHBOARD } from '../../routes/paths';

// components
import Iconify from '../../components/iconify';
import Label, { LabelColor } from '../../components/label';
import MenuPopover from '../../components/menu-popover';
import ConfirmDialog from '../../components/confirm-dialog';

// for api calls
import axios from 'axios';
import { GLOBAL } from '../../constants/variables';
import { API_URL } from 'constants/api';

import { format } from 'date-fns';

import CustomBreadcrumbs from '../../components/custom-breadcrumbs';
import { useSettingsContext } from '../../components/settings';

import RecommendationTableToolbar from './RecommendationTableToolbar';

import {
  useTable,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TablePaginationCustom,
} from '../../components/table';

const token = localStorage.getItem(GLOBAL.TOKEN_KEY);

const TABLE_HEAD = [
  { id: 'title', label: 'Título', align: 'left' },
  { id: 'category', label: 'Categoría', align: 'left' },
  { id: 'helps_with', label: 'Etiquetas', align: 'left' },
  { id: 'description', label: 'Descripción', align: 'left' },
  { id: 'edit', label: '', align: 'left' },
];

interface RecommendationsData {
  description: string;
  title: string;
  helps_with: string;
  _id: string;
  type_string: string;
}

export default function Recommendations({ withBreadcrumbs = true }) {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0); // state variable for total pages
  const [totalRows, setTotalRows] = useState(0); // state variable for total rows
  const [tableData, setTableData] = useState<RecommendationsData[]>([]);
  const [selectedButton, setSelectedButton] = useState<number>(7);

  const [selectedId, setSelectedId] = useState<string | null>(null);

  const [openConfirm, setOpenConfirm] = useState(false);

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const [filterName, setFilterName] = useState('');

  const isFiltered = filterName !== '';

  const handleFilterName = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setPage(0);
    setFilterName(event.target.value);
  };

  const handleResetFilter = () => {
    setFilterName('');
    // setFilterStatus('all');
    // setLoading(false);
  };

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const onEditRow = () => {
    console.log('edit row');
    if (selectedId) {
      // ensure selectedId is not null
      console.log('this way', PATH_DASHBOARD.recommendations.edit(paramCase(selectedId)));

      navigate(PATH_DASHBOARD.recommendations.edit(paramCase(selectedId))); // fix later
    }
  };

  const handleDeleteRow = useCallback((id: string) => {
    // const updatedTableData = tableData.filter(row => row._id !== id);
    // setTableData(updatedTableData);

    const deleteRow = async () => {
      try {
        // Await in here
        await axios.delete(`${API_URL}template-recommendation/${id}`, {
          headers: {
            application: 'application/json',
            authorization: `Bearer ${token}`,
          },
        });
        console.log('deleted');
        setTableData(
          (
            prevTableData, // prevTableData represents the previous state of tableData
          ) => prevTableData.filter(row => row._id !== id),
        );
        setOpenPopover(null);
        setOpenConfirm(false);
      } catch (err) {
        console.error(err);
        // handle the error, maybe show a notification to the user
      }
    };
    // Invoke the function
    deleteRow();
  }, []);

  const {
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  useEffect(() => {
    const getRecommendations = async () => {
      setLoading(true);
      let urlString = `${API_URL}template-recommendation?page=${page + 1}`;
      console.log('urlString ----- ', urlString);

      if (filterName && filterName.trim() !== '') {
        urlString += '&search=' + filterName;
      }

      await axios
        .get(urlString, {
          headers: {
            application: 'application/json',
            authorization: `Bearer ${token}`,
          },
        })
        .then(res => {
          setLoading(false);
          console.log('the user list', res.data);
          setTableData(res.data.data);
          setTotalRows(res.data.count);
          setTotalPages(Math.ceil(res.data.count / 20)); // assuming that you're displaying 20 rows per page
        })
        .catch(err => {
          if (err.message === 'Request failed with status code 401') {
            console.log('Request failed with status code 401');
            // dispatch.auth.logout();
          }
        });
    };
    getRecommendations();
  }, [page, filterName]);

  return (
    <>
      <Container sx={{ my: 5 }}>
        {withBreadcrumbs && (
          <CustomBreadcrumbs
            heading="Recomendaciones"
            links={[
              { name: 'Dashboard', href: PATH_DASHBOARD.root },
              { name: 'Recomendaciones', href: PATH_DASHBOARD.recommendations.root },
            ]}
          />
        )}

        <Card sx={{ marginTop: '30px' }}>
          <Divider />

          <RecommendationTableToolbar
            isFiltered={isFiltered}
            filterName={filterName}
            onFilterName={handleFilterName}
            onResetFilter={handleResetFilter}
          />

          <TableContainer sx={{ mt: 3, overflow: 'unset' }}>
            <Table sx={{ minWidth: 800 }}>
              <TableHeadCustom headLabel={TABLE_HEAD} />
              <TableBody>
                {tableData &&
                  tableData.map(recommendation => (
                    <TableRow key={recommendation._id}>
                      <TableCell>{recommendation.title} </TableCell>
                      <TableCell>
                        {(() => {
                          switch (recommendation.type_string) {
                            case 'Food':
                              return 'Alimento';
                            case 'Habit':
                              return 'Hábito';
                            default:
                              return recommendation.type_string;
                          }
                        })()}
                      </TableCell>

                      <TableCell>
                        {Array.isArray(recommendation.helps_with) &&
                        recommendation.helps_with.length > 0 ? (
                          recommendation.helps_with.map((item, index) => (
                            <Label
                              key={index}
                              variant="soft"
                              color="default"
                              sx={{
                                fontSize: '15px',
                                marginRight: '10px',
                                marginTop: '10px',
                                fontWeight: '600',
                                opacity: '0.6',
                              }}>
                              {item}
                            </Label>
                          ))
                        ) : (
                          <span> </span>
                        )}
                      </TableCell>
                      <TableCell align="right"> {recommendation.description} </TableCell>

                      <TableCell align="right">
                        <IconButton
                          color={openPopover ? 'inherit' : 'default'}
                          onClick={event => {
                            handleOpenPopover(event);
                            setSelectedId(recommendation._id);
                          }}>
                          <Iconify icon="eva:more-vertical-fill" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}

                <MenuPopover
                  open={openPopover}
                  onClose={handleClosePopover}
                  arrow="right-top"
                  sx={{ width: 140 }}>
                  <MenuItem
                    onClick={() => {
                      handleOpenConfirm();
                      handleClosePopover();
                    }}
                    sx={{ color: 'error.main' }}>
                    <Iconify icon="eva:trash-2-outline" />
                    Borrar
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      onEditRow();
                      handleClosePopover();
                    }}>
                    <Iconify icon="eva:edit-fill" />
                    Editar
                  </MenuItem>
                </MenuPopover>

                <ConfirmDialog
                  open={openConfirm}
                  onClose={handleCloseConfirm}
                  title="Borrar"
                  content="¿Estás seguro de que quieres borrar la recomendación?"
                  action={
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => {
                        if (selectedId) {
                          handleDeleteRow(selectedId);
                        }
                      }}>
                      Borrar
                    </Button>
                  }
                />
              </TableBody>
            </Table>
          </TableContainer>
          <TablePaginationCustom
            count={totalRows}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePage}
            //
          />
        </Card>
      </Container>
    </>
  );
}

import React from 'react';

// @mui
import { Divider, IconButton, Stack, Button } from '@mui/material';

// stytch
import { useStytch } from '@stytch/react';
import { STYTCH_REDIRECT_URL } from '../../constants/variables';

// components
import Iconify from '../../components/iconify';

interface AuthWithSocialProps {
  title: string;
}

// ----------------------------------------------------------------------

export default function AuthWithSocial({ title }: AuthWithSocialProps) {
  const stytchClient = useStytch();

  const handleGoogleLogin = () => {
    stytchClient.oauth.google.start({
      login_redirect_url: STYTCH_REDIRECT_URL,
      signup_redirect_url: STYTCH_REDIRECT_URL,
    });
  };

  // TODO: Handle Errors

  return (
    <div>
      <Divider
        sx={{
          my: 2.5,
          typography: 'overline',
          color: 'text.disabled',
          '&::before, ::after': {
            borderTopStyle: 'dashed',
          },
        }}>
        O
      </Divider>

      <Stack direction="row" justifyContent="center" spacing={2}>
        <Button
          data-cy="google-login"
          fullWidth
          size="large"
          onClick={handleGoogleLogin}
          variant="outlined"
          color="info"
          startIcon={<Iconify icon="flat-color-icons:google" />}
          sx={{ color: theme => theme.palette.primary.darker }}>
          {' '}
          {title}{' '}
        </Button>
      </Stack>
    </div>
  );
}

// @mui
import { Stack, InputAdornment, TextField, MenuItem, Button } from '@mui/material';
// components
import Iconify from '../../../../components/iconify';

import { type UserTests } from './IndicatorsListPage';

// ----------------------------------------------------------------------

interface Props {
  filterName: string;
  isFiltered: boolean;
  onResetFilter: VoidFunction;
  onFilterName: (event: React.ChangeEvent<HTMLInputElement>) => void;
  userTests: UserTests[];
  onDateChange: (event: React.ChangeEvent<HTMLInputElement>) => void; // Include the onDateChange prop type
  selectedTest: string | null;
}
export default function IndicatorsTableToolbar({
  isFiltered,
  filterName,
  onFilterName,
  onResetFilter,
  onDateChange, // Destructure onDateChange from the props
  userTests, // Include the userTests prop
  selectedTest,
}: Props) {
  // Calculate the selected date here

  /*  const selectedDate = selectedTest 
    ? new Date(selectedTest.date).toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
    : '';
*/

  return (
    <Stack
      spacing={2}
      alignItems="center"
      direction={{
        xs: 'column',
        sm: 'row',
      }}
      sx={{ px: 2.5, py: 3 }}>
      <TextField
        fullWidth
        select
        label="Fecha de estudio"
        value={selectedTest || ''}
        onChange={onDateChange}
        SelectProps={{
          MenuProps: {
            PaperProps: {
              sx: {
                maxHeight: 260,
              },
            },
          },
        }}
        sx={{
          maxWidth: { sm: 240 },
          textTransform: 'capitalize',
        }}>
        {userTests &&
          userTests.map(option => {
            const date = new Date(option.date);
            const day = date.getDate();
            const month = date.toLocaleString('es-ES', { month: 'short' });
            const year = date.getFullYear();
            const formattedDate = `${day}/${month}/${year}`;

            return (
              <MenuItem
                key={option._id}
                value={option._id}
                sx={{
                  mx: 1,
                  borderRadius: 0.75,
                  typography: 'body2',
                  textTransform: 'capitalize',
                }}>
                {formattedDate}
              </MenuItem>
            );
          })}
      </TextField>
      <TextField
        fullWidth
        value={filterName}
        onChange={onFilterName}
        placeholder="Buscar indicadores ..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          ),
        }}
      />
      {isFiltered && (
        <Button
          color="error"
          sx={{ flexShrink: 0 }}
          onClick={onResetFilter}
          startIcon={<Iconify icon="eva:trash-2-outline" />}>
          Clear
        </Button>
      )}
    </Stack>
  );
}

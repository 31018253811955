import React from 'react';
import { useSelector } from 'react-redux';

import { useSearchParams, useLocation } from 'react-router-dom';

// @mui
import { Link, Typography, Stack } from '@mui/material';

// components
import Logo from '../../components/logo';

// sections
import UpdateInviteForm from '../../sections/onboarding/UpdateInviteForm';

// assets
import { LogoIcon } from '../../assets/icons';

// ----------------------------------------------------------------------

export default function UpdateInvitePage() {
  return (
    <>
      <Stack
        sx={{
          m: 'auto',
          maxWidth: 400,
          minHeight: '100vh',
          textAlign: 'center',
          justifyContent: 'center',
        }}>
        <LogoIcon sx={{ mb: 5, height: 96 }} />

        <Typography variant="h3" paragraph>
          Cuentanos mas sobre ti
        </Typography>

        <Typography sx={{ color: 'text.secondary', mb: 5 }}>
          Ingresa tu información personal para crear tu cuenta
        </Typography>

        <UpdateInviteForm />
      </Stack>
    </>
  );
}

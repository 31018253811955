import React from 'react';

// @mui
import { Typography, Stack } from '@mui/material';

// sections
import AddPatientsForm from './AddPatientsForm';

// assets
import { SentIcon } from '../../assets/icons';

// ----------------------------------------------------------------------

export default function AddUsersPage() {
  return (
    <>
      <Stack
        sx={{
          m: 'auto',
          maxWidth: 400,
          textAlign: 'center',
          justifyContent: 'center',
        }}>
        <SentIcon sx={{ mb: 5, height: 96 }} />

        <Typography variant="h3" paragraph>
          Agrega a tus pacientes
        </Typography>

        <Typography sx={{ color: 'text.secondary', mb: 5 }}>
          Puedes agregarlos con su teléfono o correo electrónico
        </Typography>

        <AddPatientsForm />
      </Stack>
    </>
  );
}

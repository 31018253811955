import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Stack, Typography, Link } from '@mui/material';
// layouts
import LoginLayout from '../../views/login';
// routes
import { PATH_AUTH } from '../../routes/paths';
//
import AuthRegisterForm from './AuthRegisterForm';
import AuthWithSocial from './AuthWithSocial';

// ----------------------------------------------------------------------

export default function Register() {
  return (
    <LoginLayout title="Monitorea la salud de tus pacientes en un mismo lugar">
      <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
        <Typography variant="h4">Empieza ahora. Es gratis.</Typography>

        <Stack direction="row" spacing={0.5}>
          <Typography variant="body2">¿Ya tienes cuenta?</Typography>

          <Link href={PATH_AUTH.login} variant="body2" style={{ fontWeight: 700 }}>
            Entra Aquí
          </Link>
        </Stack>
      </Stack>

      <AuthRegisterForm />

      <Typography
        component="div"
        sx={{ color: 'text.secondary', mt: 3, typography: 'caption', textAlign: 'center' }}>
        {'Al crear mi cuenta estoy de acuerdo con los '}
        <Link underline="always" color="text.primary">
          Términos y condiciones
        </Link>
        {' y la '}
        <Link underline="always" color="text.primary">
          Política de privacidad
        </Link>
        .
      </Typography>

      <AuthWithSocial title="Crear cuenta con google" />
    </LoginLayout>
  );
}

import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useSelector, useDispatch } from 'react-redux';

// components
import Iconify from '../../../../components/iconify';
import { useSnackbar } from '../../../../components/snackbar';

import { useCallback, useEffect, useState, useRef, useMemo } from 'react';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import styles

import { useNavigate, useParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';

import DailyIframe from '@daily-co/daily-js';

import ConfirmDialog from '../../../../components/confirm-dialog';

import type { RootState } from '../../../../core';

// @mui

import {
  Link,
  Card,
  Grid,
  Box,
  Button,
  Typography,
  CardHeader,
  Stack,
  TextField,
} from '@mui/material';

import FormProvider, { RHFTextField, RHFEditor } from '../../../../components/hook-form';

import { useForm, Controller } from 'react-hook-form';

import { styled } from '@mui/material/styles';

import { PATH_DASHBOARD } from '../../../../routes/paths';
import { GLOBAL } from '../../../../constants/variables';
import { API_URL } from 'constants/api';
import axios from 'axios';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers';

// ----------------------------------------------------------------------

const StyledIcon = styled(Iconify)(({ theme }) => ({
  width: 20,
  height: 20,
  marginTop: 1,
  flexShrink: 0,
  marginRight: theme.spacing(2),
}));

// ----------------------------------------------------------------------

type FormValuesProps = IConsultation;

interface CreateEditConsultationProps {
  consultationId?: string;
  onConsultationBackButtonClick?: () => void; // Add the callback function
}

interface IConsultation {
  _id: string;
  consultation: string;
  user_id: string;
  staff_id: string;
  motive: string;
  patient_notes: string;
  diagnostic_code: string;
  scheduled_date: string;
}

const createDailyRoom = async (roomId: string, token: string): Promise<void> => {
  try {
    const response = await axios.post(
      'https://api.daily.co/v1/rooms',
      {
        name: roomId,
        properties: {
          enable_recording: 'cloud',
        },
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error('Error creating room:', error);
    throw error;
  }
};

const checkIfDailyRoomExists = async (roomId: string, token: string) => {
  try {
    console.log('token--', token);
    console.log('roomId--', roomId);

    const response = await axios.get(`https://api.daily.co/v1/rooms/${roomId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (
      typeof error === 'object' &&
      error !== null &&
      'response' in error &&
      (error as any).response.status === 404
    ) {
      return null;
    }
    console.error('Error checking room:', error);
    throw error;
  }
};

export default function CreateEditConsultation({
  consultationId,
  onConsultationBackButtonClick,
}: CreateEditConsultationProps) {
  const { id } = useParams(); // Get the userId from the URL
  const token = localStorage.getItem(GLOBAL.TOKEN_KEY);

  const accountStore = useSelector((state: RootState) => state.account);
  const userState = useSelector((state: RootState) => state.user);
  const user = userState.user;

  const userAccount = accountStore.user;
  //console.log("userAccount-----", userAccount)

  const [videoStarted, setVideoStarted] = useState(false);
  const [callFrame, setCallFrame] = useState<any | null>(null); // Use state to manage callFrame
  const [value, setValue] = useState<Date | null>(new Date());
  const [recordingId, setRecordingId] = useState<string | null>(null);
  const [instanceId, setInstanceId] = useState<string | null>(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [consultationIdState, setConsultationIdState] = useState<string | null>(null);

  const navigate = useNavigate();

  const ExpedienteSchema = Yup.object().shape({
    text_body: Yup.string(),
    _id: Yup.string().required('ID is required'),
    consultation: Yup.string().required('Consultation is required'),
    user_id: Yup.string().required('User ID is required'),
    staff_id: Yup.string().required('Staff ID is required'),
    motive: Yup.string().required('Motive is required'),
    patient_notes: Yup.string().required('Patient notes are required'),
    diagnostic_code: Yup.string().required('Diagnostic code is required'),
    scheduled_date: Yup.string().required('Scheduled date is required'),
  });

  const { enqueueSnackbar } = useSnackbar();

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };
  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(ExpedienteSchema),
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
    control,
  } = methods;

  const handleRecordingStopped = (event: any) => {
    console.log('handleRecordingStopped callback');

    // Extract the recording ID from the event and save it to state
    const { recording_id } = event?.recordings?.[0] ?? {};
    if (recording_id) {
      setRecordingId(recording_id);
      console.log('recording_id', recording_id);
      // save later to video_url
    }
  };

  const handleCreateVideo = async () => {
    const dailyApiKey = process.env.REACT_APP_DAILY_KEY;
    //console.log("dailyApiKey---", dailyApiKey);
    const roomId = `room-${userAccount.id}`;

    let room; // Declare it here

    try {
      // Check if room already exists
      if (roomId && dailyApiKey) {
        room = await checkIfDailyRoomExists(roomId!, dailyApiKey!); // Use the non-null assertion operator
      } else {
        console.error('roomId or dailyApiKey is not defined');
      }

      if (!room) {
        // If room doesn't exist, create it
        await createDailyRoom(roomId!, dailyApiKey!); // Use the non-null assertion operator
      }
      handleOpenConfirm();
      const frame = DailyIframe.createFrame({
        iframeStyle: {
          position: 'fixed',
          border: '1px solid black',
          width: '50%',
          height: '50%',
          right: '1em',
          bottom: '1em',
        },
        showLeaveButton: true,
        showFullscreenButton: true,
      });

      frame
        .join({ url: `https://siempresomos.daily.co/${roomId}`, lang: 'es' })
        .then(() => {
          frame.on('recording-started', event => {
            console.log('recording-started', event);

            setRecordingId(event?.recordingId ?? null);
          });
          frame.startRecording(); // Start recording once the call is joined

          frame.on('recording-stopped', event => {
            console.log('recording-stopped', event);

            setInstanceId(event?.instanceId ?? null);

            frame.startTranscription({
              language: 'es',
              model: 'general',
            });
          });

          //  frame.startTranscription({
          //    language: 'es',
          //    model: 'general',
          //  });
        })
        .catch(error => console.error('Error joining the call:', error));

      setVideoStarted(true);
      setCallFrame(frame);
    } catch (error) {
      console.error('Error setting up video call:', error);
    }
  };

  const handleEndVideo = () => {
    //if (frame) {
    //  frame.startTranscription();
    //}

    // Code to end the video call
    if (callFrame) {
      callFrame.leave(); // End the video call
    }
    console.log('removing iframe');
    const iframe = document.querySelector('iframe');

    if (iframe) {
      console.log('now removing ');

      iframe.remove();
    }

    //setVideoStarted(false);  // Set videoStarted back to false
    setCallFrame(null);
    // Hide the "Empezar video" button
    const startVideoButton = document.getElementById('endVideoButton');
    if (startVideoButton) {
      startVideoButton.style.display = 'none';
    }

    const startTranscription = document.getElementById('generate_transcription');
    if (startTranscription) {
      startTranscription.style.display = 'block';
    }
  };

  useEffect(() => {
    const fetchConsultation = async () => {
      const urlExpediente = `${API_URL}consultation/${consultationId}`;

      console.log('fetchConsultation --');

      await axios
        .get(urlExpediente, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then(results => {
          reset(results.data);
          console.log('results --', results.data);
        })
        .catch(err => {
          console.log('error message', err.message);
          if (err.message === 'Request failed with status code 401') {
            console.log('Failed request');
          }
        });
    };

    // Call fetchConsultation only if consultationId is defined
    if (consultationId) {
      fetchConsultation();
    }
  }, []);

  const onSubmit = async (data: FormValuesProps) => {
    try {
      const payload = {
        user_id: data.user_id,
        // staff_id: data.staff_id,
        consultation: data.consultation,
        motive: data.motive,
        patient_notes: data.patient_notes,
        diagnostic_code: data.diagnostic_code,
        scheduled_date: data.scheduled_date,
      };

      let apiUrl;

      if (consultationIdState) {
        // If consultationIdState is defined, use it to construct the API URL for PATCH
        console.log('Updating consulta ---');

        apiUrl = `${API_URL}consultation/${consultationIdState}`;
      } else if (consultationId) {
        // If consultationId (the prop) is defined, use it to construct the API URL for PATCH
        console.log('Updating consulta (prop) ---');

        apiUrl = `${API_URL}consultation/${consultationId}`;
      } else {
        console.log('Creating consulta ---');

        apiUrl = `${API_URL}consultation`;
      }

      const response = await axios({
        method: consultationId ? 'PATCH' : 'POST', // Use PATCH if consultationId is defined, otherwise use POST
        url: apiUrl,
        data: payload,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      //const consultationIdR = response.data._id;

      const consultationIdR = response.data._id;

      setConsultationIdState(consultationIdR);

      console.log('Consultation ID:', consultationIdR);

      //setConsultationIdResponse(consultationIdR);

      enqueueSnackbar('Cambiado exitosamente');
      //onConsultationBackButtonClick();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <style>
        {`
        .ql-container {
          height: 200px !important;
        }
      `}
      </style>
      <Button
        // onClick={onConsultationBackButtonClick}
        onClick={() => navigate(-1)}
        sx={{ marginTop: '20px', marginBottom: '20px' }}
        variant="outlined"
        size="large"
        color="inherit">
        <Iconify icon="fe:arrow-left" style={{ marginRight: '10px' }} />
        Regresar
      </Button>

      <Card>
        <CardHeader title="Crear Consulta" />
        <Stack spacing={2} sx={{ p: 3 }}>
          <Stack direction="row"></Stack>

          <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            {!videoStarted && (
              <Button
                onClick={handleCreateVideo}
                id="startVideoButton"
                variant="contained"
                sx={{
                  width: '20%',
                }}>
                {'Empezar video'}
              </Button>
            )}
            {videoStarted && (
              <Button
                onClick={handleEndVideo}
                id="endVideoButton"
                variant="contained"
                color="secondary"
                sx={{
                  width: '20%',
                }}>
                {'Terminar video'}
              </Button>
            )}

            <Button
              id="generate_transcription"
              variant="contained"
              color="secondary"
              sx={{
                display: 'none',
              }}>
              {'Generar Transcripcion'}
            </Button>
          </Grid>

          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item sx={{ width: '100%' }}>
                <Stack>
                  {/* Hidden Input for User ID */}

                  <Controller
                    name="user_id"
                    control={control}
                    defaultValue={user.id} // Use the ID you get from useParams
                    render={({ field }) => <input type="hidden" {...field} />}
                  />
                  {/*
                <Controller
                    name="staff_id"
                    control={control}
                    defaultValue={userAccount.id} // Use the ID you get from useParams
                    render={({ field }) => <input type="hidden" {...field} />}
                  />
            */}
                  <Block>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: 'text.secondary', marginBottom: '10px', fontSize: '14px' }}>
                      Motivo
                    </Typography>
                    <RHFTextField name="motive" sx={{ marginBottom: '20px' }} />
                  </Block>

                  <Block>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: 'text.secondary', marginBottom: '10px', fontSize: '14px' }}>
                      Diagnóstico
                    </Typography>
                    <RHFTextField name="diagnostic_code" sx={{ marginBottom: '20px' }} />
                  </Block>

                  <Block>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: 'text.secondary', marginBottom: '10px', fontSize: '14px' }}>
                      Fecha
                    </Typography>
                    {/* <RHFTextField name="schedule_date" sx={{ marginBottom: '20px' }} />  */}

                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                      <Controller
                        name="scheduled_date"
                        control={control}
                        defaultValue={value ? value.toISOString() : ''} // Use the ID you get from useParams
                        render={({ field }) => <input type="hidden" {...field} />}
                      />

                      <DateTimePicker
                        renderInput={props => <TextField {...props} />}
                        value={value}
                        onChange={setValue}
                      />
                    </LocalizationProvider>
                  </Block>

                  {/* WYSIWYG Editor for Medical History */}

                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: 'text.secondary',
                      marginBottom: '10px',
                      marginTop: '20px',
                      fontSize: '14px',
                    }}>
                    Historial Clinica
                  </Typography>
                  <Block>
                    <Controller
                      name="consultation"
                      control={control}
                      render={({ field }) => (
                        <ReactQuill value={field.value} onChange={field.onChange} />
                      )}
                    />
                  </Block>

                  <Block>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        color: 'text.secondary',
                        marginBottom: '10px',
                        marginTop: '20px',
                        fontSize: '14px',
                      }}>
                      Notas para paciente
                    </Typography>

                    <Controller
                      name="patient_notes"
                      control={control}
                      render={({ field }) => (
                        <ReactQuill value={field.value} onChange={field.onChange} />
                      )}
                    />
                  </Block>

                  <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                    Guardar cambios
                  </LoadingButton>
                </Stack>
              </Grid>
            </Grid>
          </FormProvider>
        </Stack>
      </Card>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Mandar notificación"
        content="¿Quieres mandar notificacion al paciente?"
        action={
          <Button variant="contained" color="error">
            Mandar notificación
          </Button>
        }
      />
    </div>
  );
}

interface BlockProps {
  sx?: any;
  children: React.ReactNode;
}

function Block({ sx, children }: BlockProps) {
  return <Box sx={{ mb: 2, ...sx }}>{children}</Box>;
}

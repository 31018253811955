import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// @mui
import { Grid, Stack, InputAdornment, TextField, MenuItem, Button, Box, Card } from '@mui/material';

// @types
import type { RootState } from '../../../../core';

import axios from 'axios';

// Variables
import { GLOBAL } from '../../../../constants/variables';
import { API_URL } from 'constants/api';
import EmptyContent from '../../../../components/empty-content';
import { DateFilterWearables } from '../../../../components/date-filter';
import { type Filter } from '../../../../components/date-filter/types';
import Chips from '../../../../sections/_examples/mui/Chips';
import Iconify from '../../../../components/iconify';
import Label from '../../../../components/label';

import { useParams } from 'react-router-dom';

import CanvasJSReact from '@canvasjs/react-charts';
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

// ----------------------------------------------------------------------

interface SleepDataItem {
  type: string;
  data: Array<{
    metadata: {
      start_time: string;
    };
    sleep_durations_data?: {
      other?: {
        duration_in_bed_seconds?: number;
      };
    };
  }>;
}

interface ActivityDataItem {
  data: Array<{
    metadata: {
      start_time: string;
    };
    calories_data?: {
      total_burned_calories?: number;
    };
  }>;
}

interface DailyDataItem {
  data: Array<{
    metadata: {
      start_time: string;
    };
    heart_rate_data?: {
      summary?: {
        avg_hr_bpm?: number;
      };
    };
  }>;
}

interface DailyStepsItem {
  data: Array<{
    metadata: {
      start_time: string;
    };
    distance_data?: {
      steps?: number;
    };
  }>;
}

interface StepsGraphDataPoint {
  x: Date;
  y: number | null;
}

interface SleepGraphDataPoint {
  x: Date;
  y: number | null;
}

interface ActivityGraphDataPoint {
  x: Date;
  y: number | null;
}

interface DailyDataDataPoint {
  x: Date;
  y: number | null;
}

type DateFilterValue =
  | { type: 'days'; value: number }
  | { type: 'date'; value: { start: Date; end: Date } };

export default function Wearable() {
  const dispatch = useDispatch();
  const userState = useSelector((state: RootState) => state.user);
  const user = userState.user;

  const [isLoading, setIsLoading] = useState(false);

  const { userId } = useParams(); // Get the userId from the URL
  const token = localStorage.getItem(GLOBAL.TOKEN_KEY);
  const [terraId, setTerraId] = useState('');
  const [sleepData, setSleepData] = useState<SleepGraphDataPoint[]>([]);
  const [activityData, setActivityData] = useState<ActivityGraphDataPoint[]>([]);
  const [dailyData, setDailyData] = useState<DailyDataDataPoint[]>([]);
  const [stepsData, setStepsData] = useState<StepsGraphDataPoint[]>([]);

  const [wearableProvider, setWearableProvider] = useState<string | null>(null);

  const [averageLabel, setAverageLabel] = useState<string | null>(null);
  const [averageMetric, setAverageMetric] = useState<string | null>(null);
  const [averageUnit, setAverageUnit] = useState<string | null>(null);

  const [dateFilter, setDateFilter] = useState<DateFilterValue>({ type: 'days', value: 7 }); // Default to last 7 days

  const [selectedOption, setSelectedOption] = useState('sleep'); // useState<string | null>(null);

  // Define the type for DateFilterValue
  type DateFilterValue =
    | { type: 'days'; value: number }
    | { type: 'date'; value: { start: Date; end: Date } };

  // Define the type for metric data points
  interface MetricDataPoint {
    x: Date;
    y: number | null;
  }

  function calculateDailyAverage(
    selectedOption: string,
    dateFilter: DateFilterValue,
    metricData: MetricDataPoint[],
  ) {
    // Initialize a variable to store the total sum
    let total = 0;

    // Calculate the total sum of the metric data
    for (const dataPoint of metricData) {
      total += dataPoint.y || 0;
    }

    // Calculate the daily average based on the count of items in metricData
    let dailyAverage = 0;

    if (metricData.length > 0) {
      dailyAverage = total / metricData.length;
    }

    return Math.round(dailyAverage); // You can adjust the number of decimal places as needed
  }

  {
    /*
  function calculateDailyAverage(
    selectedOption: string,
    dateFilter: DateFilterValue,
    metricData: MetricDataPoint[],
  ) {
    // Initialize a variable to store the total sum
    let total = 0;

    // Loop through the metric data and calculate the total sum
    total = metricData.reduce((acc, dataPoint) => acc + (dataPoint.y || 0), 0);

    // Calculate the daily average based on dateFilter.type
    let dailyAverage = 0;
    if (dateFilter.type === 'days') {
      dailyAverage = total / dateFilter.value;
    } else if (dateFilter.type === 'date') {
      const startDate = dateFilter.value.start.getTime(); // Convert to timestamp
      const endDate = dateFilter.value.end.getTime(); // Convert to timestamp
      const differenceInDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));
      dailyAverage = total / differenceInDays;
    }

    return Math.round(dailyAverage); // You can adjust the number of decimal places as needed
  }
*/
  }

  const onOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);

    const selectedOption = event.target.value;

    console.log('selectedId ---', selectedOption);
    setSelectedOption(selectedOption);
  };

  useEffect(() => {
    let userInfoUrl: string;
    //  userInfoUrl = `${API_URL}glucose/wearables/user/${userId}?days=30&type=${selectedOption}`;

    // Use dateFilter.type to check which kind of value we have and construct userInfoUrl accordingly.
    if (dateFilter.type === 'days') {
      userInfoUrl = `${API_URL}glucose/wearables/user/${userId}?days=${dateFilter.value}&type=${selectedOption}`;
    } else {
      // Here we are sure that dateFilter.value is of the form { start: Date, end: Date }.
      const startDate = dateFilter.value.start.toISOString().split('T')[0];
      const endDate = dateFilter.value.end.toISOString().split('T')[0];
      userInfoUrl = `${API_URL}glucose/wearables/user/${userId}?dategte=${startDate}&datelte=${endDate}&type=${selectedOption}`;
    }

    console.log('userInfoUrl ---', userInfoUrl);
    if (selectedOption === 'sleep') {
      const getSleepInfo = async () => {
        await axios
          .get(userInfoUrl, {
            headers: {
              application: 'application/json',
              Authorization: `Bearer ${token}`,
            },
          })
          .then(res => {
            console.log(res.data);
            setAverageUnit('horas / día');

            if (res.data[0] && res.data[0].user && res.data[0].user.provider) {
              setWearableProvider(res.data[0].user.provider);
            }

            let sleepData = (res.data as SleepDataItem[])
              .flatMap((item: SleepDataItem) =>
                item.data.map(d => {
                  const duration = d.sleep_durations_data?.other?.duration_in_bed_seconds;
                  return {
                    x: new Date(d.metadata.start_time),
                    y: duration ? Math.round((duration / 3600) * 10) / 10 : null, // Round to nearest tenth
                  };
                }),
              )
              .filter(d => d.y !== null);

            // Create a map to hold the maximum value for each day
            const sleepDataMap: Record<string, SleepGraphDataPoint> = {};

            // Loop over all data and keep only the maximum value for each day
            for (const dataPoint of sleepData) {
              const dateStr = dataPoint.x.toISOString().split('T')[0];
              if (
                !sleepDataMap[dateStr] ||
                (dataPoint.y !== null && dataPoint.y > (sleepDataMap[dateStr].y || 0))
              ) {
                sleepDataMap[dateStr] = dataPoint;
              }
            }

            // Convert the map back to an array
            sleepData = Object.values(sleepDataMap);

            sleepData.sort((a, b) => a.x.getTime() - b.x.getTime());

            setSleepData(sleepData);
            setAverageMetric(
              calculateDailyAverage(selectedOption, dateFilter, sleepData).toString(),
            );
            console.log('sleepData ---', sleepData);
            setIsLoading(false);
          })
          .catch(err => {
            if (err.message === 'Request failed with status code 401') {
              console.log('failed request ');
            }
            setIsLoading(false);
          });
      };
      getSleepInfo();
      {
        /*
    } else if (selectedOption === 'activity') {
      console.log('activity');

      const getActivityInfo = async () => {
        await axios
          .get(userInfoUrl, {
            headers: {
              application: 'application/json',
              Authorization: `Bearer ${token}`,
            },
          })
          .then(res => {
            console.log(' Activity data', res.data);

            if (res.data[0] && res.data[0].user && res.data[0].user.provider) {
              setWearableProvider(res.data[0].user.provider);
            }

            const activityData = (res.data as ActivityDataItem[])
              .flatMap((item: ActivityDataItem) =>
                item.data.map(d => ({
                  x: new Date(d.metadata.start_time),
                  y: d.calories_data?.total_burned_calories
                    ? d.calories_data.total_burned_calories
                    : null,
                })),
              )
              .filter(d => d.y !== null);
            setActivityData(activityData);
            setIsLoading(false);
          })
          .catch(err => {
            if (err.message === 'Request failed with status code 401') {
              console.log('failed request ');
            }
            setIsLoading(false);
          });
      };
      getActivityInfo();

    */
      }
    } else if (selectedOption === 'daily') {
      console.log('daily');

      const getDailyInfo = async () => {
        await axios
          .get(userInfoUrl, {
            headers: {
              application: 'application/json',
              Authorization: `Bearer ${token}`,
            },
          })
          .then(res => {
            console.log('daily results---', res.data);

            if (res.data[0] && res.data[0].user && res.data[0].user.provider) {
              setWearableProvider(res.data[0].user.provider);
            }

            const dailyData = (res.data as DailyDataItem[])
              .flatMap((item: DailyDataItem) =>
                item.data.map(d => ({
                  x: new Date(d.metadata.start_time),
                  y: d.heart_rate_data?.summary?.avg_hr_bpm
                    ? Math.round(d.heart_rate_data.summary.avg_hr_bpm)
                    : null,
                })),
              )
              .filter(d => d.y !== null);

            // Create a map to hold the maximum value for each day
            const dailyDataMap: Record<string, DailyDataDataPoint> = {};

            // Loop over all data and keep only the maximum value for each day
            for (const dataPoint of dailyData) {
              const dateStr = dataPoint.x.toISOString().split('T')[0];
              if (
                !dailyDataMap[dateStr] ||
                (dataPoint.y !== null && dataPoint.y > (dailyDataMap[dateStr].y || 0))
              ) {
                dailyDataMap[dateStr] = dataPoint;
              }
            }

            // Convert the map back to an array
            const uniqueDailyData = Object.values(dailyDataMap);

            uniqueDailyData.sort((a, b) => a.x.getTime() - b.x.getTime());
            setAverageMetric(
              calculateDailyAverage(selectedOption, dateFilter, uniqueDailyData).toString(),
            );

            setDailyData(uniqueDailyData);
            setAverageUnit('PPM');

            setIsLoading(false);
          })
          .catch(err => {
            if (err.message === 'Request failed with status code 401') {
              console.log('failed request ');
            }
            setIsLoading(false);
          });
      };
      getDailyInfo();
    } else if (selectedOption === 'steps') {
      console.log('steps');

      userInfoUrl = `${API_URL}glucose/wearables/user/${userId}?days=30&type=daily`;

      const getStepInfo = async () => {
        await axios
          .get(userInfoUrl, {
            headers: {
              application: 'application/json',
              Authorization: `Bearer ${token}`,
            },
          })
          .then(res => {
            console.log('Steps Raw ---', res.data);

            if (res.data[0] && res.data[0].user && res.data[0].user.provider) {
              setWearableProvider(res.data[0].user.provider);
            }

            const stepsData = (res.data as DailyStepsItem[])
              .flatMap((item: DailyStepsItem) =>
                item.data.map(d => ({
                  x: new Date(d.metadata.start_time),
                  y: d.distance_data?.steps ? d.distance_data.steps : null,
                })),
              )
              .filter(d => d.y !== null);

            // console.log('Steps ---', stepsData);

            // Sort the data by date in ascending order
            stepsData.sort((a, b) => a.x.getTime() - b.x.getTime());

            const maxStepsData = stepsData.reduce(
              (acc: StepsGraphDataPoint[], current: StepsGraphDataPoint) => {
                const len = acc.length;

                if (
                  len === 0 ||
                  acc[len - 1].x.toISOString().split('T')[0] !==
                    current.x.toISOString().split('T')[0]
                ) {
                  acc.push(current);
                } else if (current.y !== null && current.y > (acc[len - 1].y || 0)) {
                  acc[len - 1] = current;
                }

                return acc;
              },
              [],
            );

            setStepsData(maxStepsData);
            setAverageUnit('Pasos / día');
            setAverageMetric(
              calculateDailyAverage(selectedOption, dateFilter, maxStepsData).toString(),
            );

            console.log('Steps ---', maxStepsData);

            setIsLoading(false);
          })
          .catch(err => {
            if (err.message === 'Request failed with status code 401') {
              console.log('failed request ');
            }
            setIsLoading(false);
          });
      };
      getStepInfo();
    }
    setIsLoading(false); // Ensure isLoading is set to false even if an error occurs
  }, [selectedOption, dateFilter]);

  const options = {
    animationEnabled: true,
    exportEnabled: false,
    theme: 'light2',
    axisY: {
      title:
        selectedOption === 'sleep'
          ? 'Horas'
          : selectedOption === 'activity'
          ? 'kJ'
          : selectedOption === 'steps'
          ? ' '
          : ' ', // Add title for BPM
      includeZero: false,
    },
    axisX: {
      intervalType: 'day',
      valueFormatString: 'MM/DD',
    },
    data: [
      {
        type: 'line',
        toolTipContent:
          selectedOption === 'sleep'
            ? '{x}: {y} horas'
            : selectedOption === 'activity'
            ? '{x}: {y} kJ'
            : selectedOption === 'steps'
            ? '{x}: {y} Pasos'
            : '{x}: {y} PPM',
        dataPoints:
          selectedOption === 'sleep'
            ? sleepData
            : selectedOption === 'activity'
            ? activityData
            : selectedOption === 'steps'
            ? stepsData
            : dailyData, // Add dailyData
        color: 'black',
      },
    ],
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid key="1" item xs={6}>
          <DateFilterWearables
            onChange={(filter: Filter) => {
              if (filter.type === 'date' && filter.date) {
                const startDate = new Date(filter.date.start);
                const endDate = new Date(filter.date.end);
                setDateFilter({ type: 'date', value: { start: startDate, end: endDate } });
              } else if (filter.type === 'days' && filter.days) {
                setDateFilter({ type: 'days', value: filter.days });
                const selectedOption = filter.type === 'days' ? filter.days.toString() : 'date';
              }
            }}
          />
        </Grid>
        <Grid key="2" item xs={6}>
          <Box display="flex" justifyContent="flex-end">
            {wearableProvider && (
              <Label
                color="primary"
                sx={{ mr: 2, fontSize: '14px', padding: '15px' }}
                endIcon={<Iconify icon="eva:checkmark-fill" />}>
                {wearableProvider}
              </Label>
            )}

            <TextField
              select
              label="Tipo"
              value={selectedOption || ''}
              onChange={onOptionChange}
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    sx: {
                      maxHeight: 260,
                    },
                  },
                },
              }}
              sx={{
                maxWidth: { sm: 240, width: '170px', fontSize: '1.6em' },
                textTransform: 'capitalize',
              }}>
              <MenuItem
                key="sleep"
                value="sleep"
                sx={{
                  mx: 1,
                  borderRadius: 0.75,
                  typography: 'body2',
                  textTransform: 'capitalize',
                }}>
                Sueño
              </MenuItem>

              {/*
              <MenuItem
                key="activity"
                value="activity"
                sx={{
                  mx: 1,
                  borderRadius: 0.75,
                  typography: 'body2',
                  textTransform: 'capitalize',
                }}>
                Actividad
              </MenuItem>
              */}

              <MenuItem
                key="daily"
                value="daily"
                sx={{
                  mx: 1,
                  borderRadius: 0.75,
                  typography: 'body2',
                  textTransform: 'capitalize',
                }}>
                Ritmo Cardiaco
              </MenuItem>

              <MenuItem
                key="steps"
                value="steps"
                sx={{
                  mx: 1,
                  borderRadius: 0.75,
                  typography: 'body2',
                  textTransform: 'capitalize',
                }}>
                Pasos
              </MenuItem>
            </TextField>
          </Box>
        </Grid>
      </Grid>

      <Card style={{ width: '100%', marginTop: '30px' }}>
        {isLoading ? (
          <p>Cargando...</p> // Replace with your preferred loading indicator
        ) : options.data[0].dataPoints.length > 0 ? (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}>
              <h3 style={{ paddingTop: '20px', paddingLeft: '20px', paddingBottom: '20px' }}>
                {selectedOption === 'sleep'
                  ? 'Sueño'
                  : selectedOption === 'activity'
                  ? 'Calorías'
                  : selectedOption === 'steps'
                  ? 'Pasos'
                  : 'PPM'}
              </h3>

              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <Label
                  color="info"
                  sx={{ mr: 2, fontSize: '14px', marginTop: '20px', padding: '15px' }}>
                  {averageMetric} {averageUnit}
                </Label>
              </div>
            </div>
            <CanvasJSChart options={options} />
          </>
        ) : (
          <EmptyContent title="Sin Data" sx={{ '& span.MuiBox-root': { height: 160 } }} />
        )}
      </Card>
    </>
  );
}

import { createModel } from '@rematch/core';
import api from '../../services/api';
import { type RootModel } from '.';

export const healthCategories = createModel<RootModel>()({
  state: {
    categories: [],
    generalCategory: null,
    users: [],
    sleep: {
      symptom: [],
      indicator: [],
    },
    energy: {
      symptom: [],
      indicator: [],
    },
    digestion: {
      symptom: [],
      indicator: [],
    },
    mood: {
      symptom: [],
      indicator: [],
    },
    aparence: {
      symptom: [],
      indicator: [],
    },
    all: {
      symptom: [],
      indicator: [],
    },
  },
  reducers: {
    storeCategories(state, payload) {
      return {
        ...state,
        categories: payload,
      };
    },
    storeGeneralCategory(state, payload) {
      return {
        ...state,
        generalCategory: payload,
      };
    },
    storePilars(state, payload) {
      return {
        ...state,
        sleep: payload.sleep.data,
        energy: payload.energy.data,
        digestion: payload.digestion.data,
        mood: payload.mood.data,
        aparence: payload.aparence.data,
        all: payload.all.data,
      };
    },
  },
  effects: dispatch => ({
    async getCategories(payload, state) {
      try {
        const { data } = await api.get(`/healthCategories/${state.users.selectedUserId}`, {
          headers: { authorization: `${state.auth.auth}` },
        });
        dispatch.healthCategories.storeCategories(data.user);

        const { healthCategory } = data;
        const generalCategory = healthCategory.find(
          (category: any) => category.name === 'Salud General',
        );
        dispatch.healthCategories.storeGeneralCategory(generalCategory);

        const [all, sleep, energy, digestion, mood, aparence] = await Promise.all([
          api.get(
            `healthCategories/symptom&IndicatorDetail/${generalCategory._id}/${state.users.selectedUserId}/todos`,
            { headers: { authorization: `${state.auth.auth}` } },
          ),
          api.get(
            `healthCategories/symptom&IndicatorDetail/${generalCategory._id}/${state.users.selectedUserId}/sueño`,
            { headers: { authorization: `${state.auth.auth}` } },
          ),
          api.get(
            `healthCategories/symptom&IndicatorDetail/${generalCategory._id}/${state.users.selectedUserId}/energia`,
            { headers: { authorization: `${state.auth.auth}` } },
          ),
          api.get(
            `healthCategories/symptom&IndicatorDetail/${generalCategory._id}/${state.users.selectedUserId}/digestion`,
            { headers: { authorization: `${state.auth.auth}` } },
          ),
          api.get(
            `healthCategories/symptom&IndicatorDetail/${generalCategory._id}/${state.users.selectedUserId}/animo`,
            { headers: { authorization: `${state.auth.auth}` } },
          ),
          api.get(
            `healthCategories/symptom&IndicatorDetail/${generalCategory._id}/${state.users.selectedUserId}/apariencia`,
            { headers: { authorization: `${state.auth.auth}` } },
          ),
        ]);

        dispatch.healthCategories.storePilars({
          sleep,
          energy,
          digestion,
          mood,
          aparence,
          all,
        });
      } catch (error) {
      } finally {
        setTimeout(() => {
          dispatch.loading.setLoadingStatus(false);
        }, 3000);
      }
    },
  }),
});

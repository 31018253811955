import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { API_URL } from '../../constants/api';
import { CircularProgress } from '@mui/material';

import { PATH_ROOT } from '../../routes/paths';

const ValidateSesion = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const token = new URLSearchParams(window.location.search).get('token');
    if (token !== null) {
      getLogin(token);
    }
  }, []);

  const getLogin = async (token: string) => {
    localStorage.setItem('Token Stytch', token);

    console.log('Token Stytch', token);

    axios
      .get(`${API_URL}admin/login`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async res => {
        // Saves Token
        const { sessionToken } = res.data;
        await dispatch.auth.getAuth(sessionToken);

        // Saves Account Info
        const { data } = res.data;
        await dispatch.account.getAccountInfo(data);

        // Sets Admin Status
        dispatch.account.getAdminStatus(true);

        navigate(PATH_ROOT);
      })
      .catch(err => {
        console.log('error ->', err);

        navigate('/register');
      });
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <CircularProgress color="success" />
    </div>
  );
};

export default ValidateSesion;

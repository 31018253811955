import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { GLOBAL } from '../../../../constants/variables';
import { API_URL } from 'constants/api';
import Chart, { useChart } from '../../../../components/chart';
import Iconify from '../../../../components/iconify';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import type { RootState } from '../../../../core';

import moment from 'moment';

// MUI
import {
  TextField,
  Card,
  Stack,
  CardHeader,
  Grid,
  Container,
  Button,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
} from '@mui/material';

// Components
import {
  TableHeadCustom,
  useTable,
  TableNoData,
  TableEmptyRows,
  TablePaginationCustom,
} from '../../../../components/table';
import IndicatorsTableToolbar from './IndicatorsTableToolbar';
import Label from '../../../../components/label';
import IndicatorDetailWidget from './IndicatorDetailWidget';
import IndicatorRange from './IndicatorRange';
import { DateFilterIndicatorPage } from '../../../../components/date-filter';

// API
import api from '../../../../services/api';

// Styles
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  circleIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',
    float: 'left',
    height: '30px',
    borderRadius: '50%',
    color: 'white',
    '&.critico': {
      backgroundColor: 'red',
    },
    '&.optimo': {
      backgroundColor: 'green',
    },
    '&.default': {
      backgroundColor: 'orange',
    },
  },
  text: {
    marginLeft: '50px',
    display: 'flex', // Add display flex
    alignItems: 'center', // Align items vertically in the flex container
  },
}));

const StyledIcon = styled(Iconify)(({ theme }) => ({
  width: '20px',
  height: '20px',
  color: 'white',
}));

interface IndicatorData {
  _id: string;
  name: string;
  value: number;
  unit: string;
  range: string;
  created_at: string;
}

export interface RangeData {
  _id: string;
  title: string;
  range: string;
  interpretations: string;
  foodRecommendations: string;
}

const TABLE_HEAD = [
  { id: 'name', label: 'Nombre' },
  { id: 'value', label: 'Valor', align: 'right' },
  { id: 'unit', label: 'Unidades', align: 'right' },
  { id: 'created_at', label: 'Fecha', align: 'right' },
  { id: 'status', label: 'Estatus', align: 'right' },
];

//improve and debug this function
const getStatus = (range: string) => {
  // Check if the range contains 'f' or 'm' and strip them if present
  if (range.includes('f') || range.includes('m')) {
    range = range.replace('f', '').replace('m', '');
  }

  switch (range) {
    case '1':
      return 'Critico';
    case '5':
      return 'Critico';
    case '2':
      return 'Aceptable';
    case '4':
      return 'Aceptable';
    case '3':
      return 'Optimo';
    default:
      return 'Sin Rango'; // Add a default case for unknown values
  }
};

const determineColor = (range: number, indicatorRange: number) => {
  if (range === 5 && (indicatorRange === 5 || indicatorRange === 1)) {
    return 'error';
  }
  if (range === 3 && (indicatorRange === 1 || indicatorRange === 5)) {
    return 'error';
  }
  if (range === 3 && indicatorRange === 3) {
    return 'success';
  }
  if (range === 5 && indicatorRange === 3) {
    return 'success';
  }
  if (range === 3 && indicatorRange === 2) {
    return 'success';
  }
  if (range === 5 && (indicatorRange === 2 || indicatorRange === 4)) {
    return 'warning';
  }
};

// ------------------------------

export default function IndicatorDetailPage() {
  const classes = useStyles();
  const { templateIndicatorId: templateIndicator, userId } = useParams(); // Get the userId from the URL
  const token = localStorage.getItem(GLOBAL.TOKEN_KEY);
  const [recentIndicatorData, setRecentIndicatorData] = useState<IndicatorData | null>(null);

  const [allIndicatorData, setAllIndicatorData] = useState<IndicatorData[]>([]);
  const [lastFourData, setLastFourData] = useState<IndicatorData[]>([]);
  const [range, setRange] = useState<RangeData[]>([]);
  const [rangeArray, setRangeArray] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0); // state variable for total pages
  const [totalRows, setTotalRows] = useState(0); // state variable for total rows
  const [chartData, setChartData] = useState<IndicatorData[]>([]);

  const [chartInstance, setChartInstance] = useState(null);
  const [isChartRendered, setIsChartRendered] = useState(false);

  const navigate = useNavigate();

  const dateFilter = useSelector((state: RootState) => state.user.settings.filter);

  const {
    page,
    rowsPerPage,
    setPage,
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  useEffect(() => {
    // set  page to 1 always
    const urlIndicatorString = `users/indicators/${userId}/templateIndicator/${templateIndicator}?page=1`;
    fetchIndicatorData(urlIndicatorString);
  }, []);

  useEffect(() => {
    let urlIndicatorString: string;

    if (dateFilter.type === 'date') {
      urlIndicatorString = `users/indicators/${userId}/templateIndicator/${templateIndicator}?dategte=${dateFilter.date?.start}&datelte=${dateFilter.date?.end}`;
    } else {
      urlIndicatorString = `users/indicators/${userId}/templateIndicator/${templateIndicator}?page=1`;
    }

    fetchIndicatorChartData(urlIndicatorString);
  }, [dateFilter]);

  useEffect(() => {
    const urlIndicatorString = `users/indicators/${userId}/templateIndicator/${templateIndicator}?page=${
      page + 1
    }`;

    fetchIndicatorDataforTable(urlIndicatorString);
  }, [page]);

  // ====== API Calls ======
  const fetchIndicatorChartData = async (urlIndicatorString: string) => {
    await api
      .get(urlIndicatorString)
      .then(indicatorResults => {
        const sortedData = sortIndicatorsByDate(indicatorResults.data.data);
        setChartData(sortedData);
      })
      .catch(err => {
        console.log('err', err);
      });
  };

  const fetchIndicatorData = async (urlIndicatorString: string) => {
    await api
      .get(urlIndicatorString)
      .then(indicatorResults => {
        const lastResult = indicatorResults.data.data[0];

        setRecentIndicatorData(lastResult);
        setLastFourData(indicatorResults.data.data.slice(0, 4));

        // Handle ranges
        if (lastResult.template_indicator_id && lastResult.template_indicator_id.ranges) {
          const ranges = lastResult.template_indicator_id.ranges;
          setRange(ranges);
          // setRangeArray(extractNumbers(ranges));
        } else {
          // console.log("not defined ---- ");
        }
      })
      .catch(err => {
        console.log('err', err);
      });
  };

  const fetchIndicatorDataforTable = async (urlIndicatorString: string) => {
    // console.log("urlIndicatorString ---- ", urlIndicatorString);
    await api
      .get(urlIndicatorString)
      .then(indicatorResults => {
        setAllIndicatorData(indicatorResults.data.data);

        setTotalRows(indicatorResults.data.data.length);
        setTotalPages(Math.ceil(indicatorResults.data.count / 20)); // assuming that you're displaying 20 rows per page
      })
      .catch(err => {
        console.log('err', err);
      });
  };

  // === Helper Functions =====
  const calculatePercentageDifference = (currentValue: number, prevValue: number): number => {
    // const currentValue = parseFloat(value);
    // const prevValue = parseFloat(previousValue);

    if (isNaN(currentValue) || isNaN(prevValue) || prevValue === 0) {
      return 0;
    }
    return ((currentValue - prevValue) / prevValue) * 100;
  };

  const sortIndicatorsByDate = (indicatorData: IndicatorData[]) => {
    const sortedArray = indicatorData.sort((a, b) => {
      return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
    });
    return sortedArray;
  };

  // ==== Chart =====
  const series = [
    {
      name: recentIndicatorData ? recentIndicatorData.name : '',
      data: chartData ? chartData.map(data => data.value) : [],
    },
  ];

  const chartOptions = useChart({
    xaxis: {
      categories: chartData?.map(data => format(new Date(data.created_at), 'dd/MM/yyyy')) ?? [],
      labels: {
        show: true,
        hideOverlappingLabels: true,
      },
    },
    // ...
  });

  return (
    <div>
      <Button
        onClick={() => navigate(-1)}
        sx={{ marginTop: '20px' }}
        variant="outlined"
        size="large"
        color="inherit">
        <Iconify icon="fe:arrow-left" style={{ marginRight: '10px' }} />
        Todos los indicadores
      </Button>

      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Card dir="ltr" sx={{ marginTop: '40px' }}>
            <h3 style={{ paddingBottom: '20px', paddingTop: '20px', paddingLeft: '20px' }}>
              Ultimas mediciones
            </h3>
            <Stack
              style={{ paddingBottom: '20px', paddingLeft: '20px', paddingRight: '20px' }}
              direction={{ xs: 'column', sm: 'row' }}
              spacing={3}>
              {lastFourData &&
                lastFourData.map((indicator, index) => (
                  <IndicatorDetailWidget
                    key={indicator._id}
                    title={
                      indicator.created_at ? moment(indicator.created_at).format('D MMM Y') : '-'
                    }
                    icon={
                      determineColor(
                        range.length,
                        parseInt(indicator.range ? indicator.range : '1'),
                      ) === 'error'
                        ? 'emojione-monotone:exclamation-mark'
                        : determineColor(
                            range.length,
                            parseInt(indicator.range ? indicator.range : '1'),
                          ) === 'success'
                        ? 'carbon:checkmark-outline'
                        : 'ph:bell-bold'
                    }
                    percent={
                      index < lastFourData.length - 1
                        ? calculatePercentageDifference(
                            indicator.value,
                            lastFourData[index + 1].value,
                          )
                        : 0
                    }
                    units={indicator.unit}
                    total={indicator.value}
                    color={determineColor(
                      range.length,
                      parseInt(indicator.range ? indicator.range : '1'),
                    )}
                    style={{
                      backgroundColor:
                        determineColor(
                          range.length,
                          parseInt(indicator.range ? indicator.range : '1'),
                        ) === 'warning'
                          ? '#FFEBD7'
                          : '',
                    }}
                  />
                ))}
            </Stack>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Card dir="ltr" sx={{ height: '120px', marginTop: '40px' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                float: 'left',
                padding: '20px',
              }}>
              <h3>Rangos</h3>
              <span style={{ color: '#637381', fontSize: '14px' }}>
                {' '}
                {recentIndicatorData?.name} {recentIndicatorData ? recentIndicatorData.unit : ''}
              </span>
            </div>

            <IndicatorRange
              range={range}
              rangeValue={recentIndicatorData ? recentIndicatorData.range : ''}
            />
          </Card>
        </Grid>
      </Grid>

      <Grid item xs={12} md={12} lg={12}>
        <Card dir="ltr" sx={{ marginTop: '40px' }}>
          <h3 style={{ paddingTop: '20px', paddingLeft: '20px' }}>Medición más reciente</h3>
          <TableContainer sx={{ mt: 3, height: '120px' }}>
            <Table sx={{ minWidth: 800 }}>
              <TableHeadCustom headLabel={TABLE_HEAD} />

              {recentIndicatorData ? (
                <TableBody>
                  <TableRow>
                    <TableCell>{recentIndicatorData.name} </TableCell>
                    <TableCell align="right">
                      {recentIndicatorData.value ? `${recentIndicatorData.value} ` : '-'}{' '}
                    </TableCell>
                    <TableCell align="right">
                      {recentIndicatorData.unit ? `${recentIndicatorData.unit}` : '-'}
                    </TableCell>
                    <TableCell align="right">
                      {recentIndicatorData.created_at
                        ? format(new Date(recentIndicatorData.created_at), 'yyyy-MM-dd')
                        : '-'}
                    </TableCell>
                    <TableCell align="right">
                      <Label
                        variant="soft"
                        color={
                          getStatus(recentIndicatorData.range) === 'Optimo'
                            ? 'success'
                            : getStatus(recentIndicatorData.range) === 'Aceptable'
                            ? 'warning'
                            : 'error'
                        }
                        sx={{
                          textTransform: 'capitalize',
                          backgroundColor:
                            getStatus(recentIndicatorData.range) === 'Aceptable'
                              ? 'rgba(255, 171, 0, 0.16)'
                              : undefined,
                          color:
                            getStatus(recentIndicatorData.range) === 'Aceptable'
                              ? '#D28438'
                              : undefined,
                        }}>
                        {getStatus(recentIndicatorData.range)}
                      </Label>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : null}
            </Table>
          </TableContainer>
        </Card>
      </Grid>

      <Grid item xs={12} md={12} lg={12}>
        <Card dir="ltr" sx={{ marginTop: '40px' }}>
          <h3 style={{ paddingTop: '20px', paddingLeft: '20px', paddingBottom: '20px' }}>
            {' '}
            {recentIndicatorData?.name} en el tiempo{' '}
          </h3>

          <DateFilterIndicatorPage />

          <Chart type="line" series={series} options={chartOptions} height={320} />
        </Card>
      </Grid>

      {allIndicatorData ? (
        <Card sx={{ marginTop: '40px' }}>
          <h3 style={{ paddingTop: '20px', paddingLeft: '20px' }}>Todas las mediciones</h3>

          <TableContainer sx={{ mt: 3, overflow: 'unset' }}>
            <Table sx={{ minWidth: 800 }}>
              <TableHeadCustom headLabel={TABLE_HEAD} />

              <TableBody>
                {allIndicatorData &&
                  allIndicatorData.map(indicator => (
                    <TableRow key={indicator._id}>
                      <TableCell>{indicator.name} </TableCell>
                      <TableCell align="right">
                        {indicator.value ? `${indicator.value} ` : '-'}
                      </TableCell>
                      <TableCell align="right">
                        {indicator.unit ? `${indicator.unit}` : '-'}
                      </TableCell>
                      <TableCell align="right">
                        {indicator.created_at
                          ? moment(indicator.created_at).format('D MMM Y')
                          : '-'}
                      </TableCell>
                      <TableCell align="right">
                        <Label
                          variant="soft"
                          color={
                            getStatus(indicator.range ? indicator.range : '') === 'Optimo'
                              ? 'success'
                              : getStatus(indicator.range ? indicator.range : '') === 'Aceptable'
                              ? 'warning'
                              : 'error'
                          }
                          sx={{
                            textTransform: 'capitalize',
                            backgroundColor:
                              getStatus(indicator.range ? indicator.range : '') === 'Aceptable'
                                ? 'rgba(255, 171, 0, 0.16)'
                                : undefined,
                            color:
                              getStatus(indicator.range ? indicator.range : '') === 'Aceptable'
                                ? '#D28438'
                                : undefined,
                          }}>
                          {getStatus(indicator.range ? indicator.range : '')}
                        </Label>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePaginationCustom
            count={totalRows}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePage}
          />
        </Card>
      ) : null}
    </div>
  );
}

import React from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from '../../../../core';

import api from '../../../../services/api';

// MUI
import { Stack, Typography } from '@mui/material';

// Components
import InviteUsersForm from 'sections/onboarding/InviteUsersForm';

// Assets
import { SentIcon } from 'assets/icons';

export default function OrganizationSettings() {
  const accountState = useSelector((state: RootState) => state.account);
  const { user } = accountState;

  console.log(user);

  return (
    <>
      <Stack
        sx={{
          m: 'auto',
          maxWidth: 400,
          textAlign: 'center',
          justifyContent: 'center',
        }}>
        {/* <SentIcon sx={{ mb: 5, height: 96 }} /> */}

        <Typography variant="h3" paragraph>
          Agrega a tus colegas
        </Typography>

        <Typography sx={{ color: 'text.secondary', mb: 5 }}>
          Invita a tus colegas para que trabajen todos juntos en:{' '}
          <span style={{ fontWeight: 700 }}>{user.organization}</span>
        </Typography>

        <InviteUsersForm />
      </Stack>
    </>
  );
}

import React from 'react';
import { Grid } from '@mui/material';
import Label from '../../../../components/label';
import { type RangeData } from './IndicatorDetailPage';
import { useSelector } from 'react-redux';
import type { RootState } from '../../../../core';

interface IndicatorRangeProps {
  range: RangeData[];
  rangeValue: string;
}

const IndicatorRange = ({ range, rangeValue }: IndicatorRangeProps) => {
  const stackStyle: React.CSSProperties = {
    float: 'right',
    height: '70px',
    padding: '20px',
  };

  const labelStyle = {
    backgroundColor: 'transparent',
    width: '100px',
    height: '18px',
    fontWeight: '400',
    fontSize: '12px',
  };
  const labelRangeStyle = {
    backgroundColor: 'transparent',
    width: '100px',
    color: '#919EAB',
    justifyContent: 'center',
    fontSize: '13px',
  };

  const userState = useSelector((state: RootState) => state.user);
  const gender = userState.user.genere;

  let filteredRange = range;

  if (rangeValue) {
    const lowerRangeValue = rangeValue.toLowerCase();
    if (lowerRangeValue.startsWith('m') || lowerRangeValue.startsWith('f')) {
      filteredRange = range.filter(singleRange =>
        singleRange.range.toLowerCase().startsWith(lowerRangeValue.charAt(0)),
      );
    }
  }

  const displayRange = filteredRange.map((singleRange, index) => (
    <Label key={index} sx={labelRangeStyle}>
      {' '}
      {singleRange.title}{' '}
    </Label>
  ));

  return (
    <div style={stackStyle}>
      {(range.length === 5 || range.length === 10) && (
        <>
          <Grid>
            <Label sx={{ ...labelStyle, color: '#F97C6A' }}> Bajo </Label>
            <Label sx={{ ...labelStyle, color: '#FFA043' }}> Rango Bajo </Label>
            <Label sx={{ ...labelStyle, color: '#01D985' }}> Optimo </Label>
            <Label sx={{ ...labelStyle, color: '#FFA043' }}> Rango Alto </Label>
            <Label sx={{ ...labelStyle, color: '#F97C6A' }}> Alto</Label>
          </Grid>

          <Grid>
            <svg
              width="101"
              height="11"
              viewBox="0 0 101 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M100.5 5.5C100.5 2.73858 98.2614 0.5 95.5 0.5L5.5 0.5C2.73858 0.5 0.5 2.73858 0.5 5.5C0.5 8.26142 2.73858 10.5 5.5 10.5L95.5 10.5C98.2614 10.5 100.5 8.26142 100.5 5.5Z"
                fill="#FFADA2"
              />
            </svg>
            <svg
              width="101"
              height="11"
              viewBox="0 0 101 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M100.5 5.5C100.5 2.73858 98.2614 0.5 95.5 0.5L5.5 0.5C2.73858 0.5 0.5 2.73858 0.5 5.5V5.5C0.5 8.26142 2.73858 10.5 5.5 10.5L95.5 10.5C98.2614 10.5 100.5 8.26142 100.5 5.5V5.5Z"
                fill="#FFD3A8"
              />
            </svg>
            <svg
              width="101"
              height="11"
              viewBox="0 0 101 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M100.5 5.5C100.5 2.73858 98.2614 0.5 95.5 0.5L5.5 0.5C2.73858 0.5 0.5 2.73858 0.5 5.5C0.5 8.26142 2.73858 10.5 5.5 10.5L95.5 10.5C98.2614 10.5 100.5 8.26142 100.5 5.5Z"
                fill="#CDF4E5"
              />
            </svg>
            <svg
              width="101"
              height="11"
              viewBox="0 0 101 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M100.5 3.21878C100.5 1.71724 99.2828 0.5 97.7812 0.5L5.5 0.5C2.73858 0.5 0.5 2.73858 0.5 5.5C0.5 8.26142 2.73858 10.5 5.5 10.5L95.5 10.5C98.2614 10.5 100.5 8.26142 100.5 5.5V3.21878Z"
                fill="#FFD3A8"
              />
            </svg>
            <svg
              width="101"
              height="11"
              viewBox="0 0 101 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M100.5 5.5C100.5 2.73858 98.2614 0.5 95.5 0.5L5.5 0.5C2.73858 0.5 0.5 2.73858 0.5 5.5C0.5 8.26142 2.73858 10.5 5.5 10.5L95.5 10.5C98.2614 10.5 100.5 8.26142 100.5 5.5Z"
                fill="#FFADA2"
              />
            </svg>
          </Grid>

          <Grid>{displayRange}</Grid>
        </>
      )}

      {range.length === 3 &&
        range[0] &&
        range[2] &&
        parseInt(range[0].range, 10) === 1 &&
        parseInt(range[2].range, 10) === 5 && (
          <>
            <Grid>
              <Label sx={{ ...labelStyle, color: '#F97C6A' }}> Bajo </Label>
              <Label sx={{ ...labelStyle, color: '#01D985' }}> Optimo </Label>
              <Label sx={{ ...labelStyle, color: '#F97C6A' }}> Alto </Label>
            </Grid>

            <Grid>
              <svg
                width="101"
                height="11"
                viewBox="0 0 101 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M100.5 5.5C100.5 2.73858 98.2614 0.5 95.5 0.5L5.5 0.5C2.73858 0.5 0.5 2.73858 0.5 5.5C0.5 8.26142 2.73858 10.5 5.5 10.5L95.5 10.5C98.2614 10.5 100.5 8.26142 100.5 5.5Z"
                  fill="#FFADA2"
                />
              </svg>
              <svg
                width="101"
                height="11"
                viewBox="0 0 101 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M100.5 5.5C100.5 2.73858 98.2614 0.5 95.5 0.5L5.5 0.5C2.73858 0.5 0.5 2.73858 0.5 5.5C0.5 8.26142 2.73858 10.5 5.5 10.5L95.5 10.5C98.2614 10.5 100.5 8.26142 100.5 5.5Z"
                  fill="#CDF4E5"
                />
              </svg>
              <svg
                width="101"
                height="11"
                viewBox="0 0 101 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M100.5 5.5C100.5 2.73858 98.2614 0.5 95.5 0.5L5.5 0.5C2.73858 0.5 0.5 2.73858 0.5 5.5C0.5 8.26142 2.73858 10.5 5.5 10.5L95.5 10.5C98.2614 10.5 100.5 8.26142 100.5 5.5Z"
                  fill="#FFADA2"
                />
              </svg>
            </Grid>

            <Grid>
              {range &&
                range.map((singleRange, index) => (
                  <Label key={index} sx={labelRangeStyle}>
                    {' '}
                    {singleRange.title}{' '}
                  </Label>
                ))}
            </Grid>
          </>
        )}

      {range.length === 3 &&
        range[0] &&
        range[2] &&
        parseInt(range[0].range, 10) === 3 &&
        parseInt(range[2].range, 10) === 5 && (
          <>
            <Grid>
              <Label sx={{ ...labelStyle, color: '#01D985' }}> Optimo </Label>
              <Label sx={{ ...labelStyle, color: '#FFA043' }}> Aceptable </Label>
              <Label sx={{ ...labelStyle, color: '#F97C6A' }}> Critico </Label>
            </Grid>

            <Grid>
              <svg
                width="101"
                height="11"
                viewBox="0 0 101 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M100.5 5.5C100.5 2.73858 98.2614 0.5 95.5 0.5L5.5 0.5C2.73858 0.5 0.5 2.73858 0.5 5.5C0.5 8.26142 2.73858 10.5 5.5 10.5L95.5 10.5C98.2614 10.5 100.5 8.26142 100.5 5.5Z"
                  fill="#CDF4E5"
                />
              </svg>
              <svg
                width="101"
                height="11"
                viewBox="0 0 101 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M100.5 3.21878C100.5 1.71724 99.2828 0.5 97.7812 0.5L5.5 0.5C2.73858 0.5 0.5 2.73858 0.5 5.5C0.5 8.26142 2.73858 10.5 5.5 10.5L95.5 10.5C98.2614 10.5 100.5 8.26142 100.5 5.5V3.21878Z"
                  fill="#FFD3A8"
                />
              </svg>
              <svg
                width="101"
                height="11"
                viewBox="0 0 101 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M100.5 5.5C100.5 2.73858 98.2614 0.5 95.5 0.5L5.5 0.5C2.73858 0.5 0.5 2.73858 0.5 5.5C0.5 8.26142 2.73858 10.5 5.5 10.5L95.5 10.5C98.2614 10.5 100.5 8.26142 100.5 5.5Z"
                  fill="#FFADA2"
                />
              </svg>
            </Grid>

            <Grid>
              {range &&
                range.map((singleRange, index) => (
                  <Label key={index} sx={labelRangeStyle}>
                    {' '}
                    {singleRange.title}{' '}
                  </Label>
                ))}
            </Grid>
          </>
        )}
    </div>
  );
};

export default IndicatorRange;

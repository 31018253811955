import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/es';

import Iconify from '../iconify';

import dayjs from 'dayjs';

import type { RootState } from '../../core';
import { type Filter } from './types';
import { log } from 'console';
import { date } from 'yup';
import { set } from 'lodash';

interface UIState {
  dateButtonSelected: boolean;
  openModal: boolean;
}

const initialUIState = { dateButtonSelected: false, openModal: false };

export default function DateFilterIndicatorPage() {
  const [UIState, setUIState] = useState<UIState>(initialUIState);
  const [dateRangeStart, setDateRangeStart] = useState<string | null>(null);
  const [dateRangeEnd, setDateRangeEnd] = useState<string | null>(null);
  const dispatch = useDispatch();
  const userState = useSelector((state: RootState) => state.user.settings);
  //const filter: Filter = userState.filter;
  const filter: Filter = userState.filter || { type: 'all' };

  useEffect(() => {
    // Overriding filter to type 'all'
    dispatch.user.getSettingsInfo({ type: 'all' });
  }, []);

  const handleClickYear = () => {
    const startYear = dayjs().startOf('year').format('YYYY-MM-DD');
    const endYear = dayjs().format('YYYY-MM-DD');

    const newFilter = {
      type: 'date',
      date: {
        start: startYear,
        end: endYear,
      },
    };
    dispatch.user.getSettingsInfo(newFilter);

    setUIState({
      ...UIState,
      dateButtonSelected: false,
    });

    setDateRangeStart(null);
    setDateRangeEnd(null);
  };

  const handleClose = () => {
    setUIState(prevState => ({
      ...prevState,
      openModal: false,
      dateButtonSelected: false,
    }));
  };

  const handleApply = () => {
    if (
      (dateRangeStart !== null && dateRangeEnd !== null) ||
      (filter.date?.start !== null && filter.date?.end !== null)
    ) {
      const newFilter = {
        type: 'date',
        date: {
          start: dateRangeStart ? dayjs(dateRangeStart).format('YYYY-MM-DD') : filter.date?.start,
          end: dateRangeEnd ? dayjs(dateRangeEnd).format('YYYY-MM-DD') : filter.date?.end,
        },
      };

      dispatch.user.getSettingsInfo(newFilter);

      setUIState({
        ...UIState,
        openModal: false,
      });

      setDateRangeStart(null);
      setDateRangeEnd(null);
    } else {
      console.warn('Please select both the start and end dates.');
    }
  };

  return (
    <Container style={{ display: 'flex', gap: '8px' }}>
      <Button
        variant="contained"
        color={filter.type === 'all' ? 'primary' : 'inherit'}
        onClick={() => {
          // Handle logic for fetching all data without any filter
          dispatch.user.getSettingsInfo({ type: 'all' });
        }}>
        Todas las mediciones
      </Button>
      <Button
        variant="contained"
        color={
          filter.type === 'date' &&
          (filter.date?.start ?? '') === dayjs().startOf('year').format('YYYY-MM-DD')
            ? 'primary'
            : 'inherit'
        }
        onClick={handleClickYear}>
        Este año
      </Button>
      <Button
        variant="contained"
        color={
          filter.type === 'date' &&
          (filter.date?.start ?? '') !== dayjs().startOf('year').format('YYYY-MM-DD')
            ? 'primary'
            : 'inherit'
        }
        style={{ display: 'flex', alignItems: 'center' }}
        onClick={() => {
          setUIState(prevState => ({
            ...prevState,
            dateButtonSelected: true,
            openModal: true,
          }));
        }}>
        Fecha exacta
        <Iconify icon="mdi:calendar-outline" style={{ marginLeft: '10px' }} />
      </Button>

      <Dialog
        open={UIState.openModal}
        onClose={() => {
          setUIState(prevState => ({ ...prevState, openModal: false }));
        }}>
        <DialogTitle> Selecciona las fechas</DialogTitle>
        <DialogContent style={{ marginTop: 20 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
            <DatePicker
              label="Start"
              value={dateRangeStart ?? filter.date?.start ?? null}
              onChange={newValue => {
                if (newValue !== null) {
                  setDateRangeStart(newValue);
                }
              }}
              renderInput={params => <TextField {...params} />}
            />
            <DatePicker
              label="End"
              value={dateRangeEnd ?? filter.date?.end ?? null}
              onChange={newValue => {
                if (newValue !== null) {
                  setDateRangeEnd(newValue);
                }
              }}
              disableFuture
              renderInput={params => <TextField {...params} />}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleApply} color="primary" autoFocus>
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

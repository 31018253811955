import {
  type AxiosError,
  type AxiosInstance,
  type AxiosRequestConfig,
  type AxiosResponse,
} from 'axios';

import { store } from '../../core';

import { API_URL } from '../../constants/api';

interface CustomError extends Error {
  code?: string; // or number, depending on what you need
}

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const TOKEN = store.getState().auth.auth;

  return {
    ...config,
    baseURL: API_URL,
    headers: {
      authorization: `Bearer ${TOKEN}`,
      'content-type': 'application/json',
    },
  };
};

const onRequestError = async (error: AxiosError): Promise<AxiosError> => {
  return await Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const onResponseError = async (error: AxiosError): Promise<never> => {
  // Check if error has a response and status
  if (error.response && error.response.status === 401) {
    console.log('401 Unauthorized from Axios');
    store.dispatch.auth.logout();
    window.location.href = '/';
  }

  if (error.response && error.response.status === 403) {
    console.log('403 Forbidden from Axios');

    window.location.href = '/onboarding/create-organization';
  }

  // Create a new Error object
  const customError: CustomError = new Error(error.message);

  // Attach additional properties
  customError.code = error.code;

  return await Promise.reject(customError);
};

export function SET_UP_AXIOS(axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
}

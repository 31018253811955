import { AnalyticsWindow } from './types';

declare let window: AnalyticsWindow;

/**
 * Basic Analytics calls
 */
// export const pageViewed = (name: string, category = "App") => {
//   window.analytics.page(category, name)
// }

// export const trackButtonClicked = (title: string) => {
//   window.analytics.track("Button Clicked", {
//     title,
//   })
// }

export const identifyUser = (user: any) => {
  window.analytics.identify(user.id, {
    name: user.name,
    email: user.email,
    company: user.organization,
  });
};

/**
 * B2B SaaS calls
 */

export const trackRegisterUser = (user: any) => {
  const eventName = 'Signed Up';
  window.analytics.track(eventName, {
    email: user.email,
  });
};

export const trackOrganizationCreated = (organization: any) => {
  const eventName = 'Account Created';
  window.analytics.track(eventName, {
    organization_name: organization.name,
    size: organization.size,
  });
};

// ===== Example Calls =====

// export const defaultInviteSentProperties = {
//   invitee_first_name: "FIRST_NAME",
//   invitee_last_name: "LAST_NAME",
//   invitee_role: "ROLE",
// }
// export const trackInviteSent = (email: string) => {
//   const eventName = "Invite Sent"
//   window.analytics.track(eventName, {
//     invitee_email: email,
//     ...defaultInviteSentProperties,
//   })

// }

// export const defaultTrialStartedProperties = {
//   trial_end_date: "END_DATE",
//   trial_plan_name: "PLAN NAME",
// }
// export const trackTrialStarted = (startDate: string) => {
//   const eventName = "Trial Started"
//   window.analytics.track(eventName, {
//     trial_start_date: startDate,
//     ...defaultTrialStartedProperties,
//   })

// }

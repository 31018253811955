import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// @mui
import {
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  MenuItem,
  TableCell,
  IconButton,
  Typography,
} from '@mui/material';
// @types
import { IUserAccountGeneral } from '../../../../@types/user';
// components
import Label from '../../../../components/label';
import Iconify from '../../../../components/iconify';
import MenuPopover from '../../../../components/menu-popover';
import ConfirmDialog from '../../../../components/confirm-dialog';

// variables
import { PATH_DASHBOARD } from '../../../../routes/paths';

// ----------------------------------------------------------------------

type Props = {
  row: IUserAccountGeneral;
  selected: boolean;
  onEditRow: VoidFunction;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export default function UserTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
}: Props) {
  const { name, email, averageGlucose, glucoseSpike, status, id, sensorLifeDays, hoursInRange } =
    row;

  const [openConfirm, setOpenConfirm] = useState(false);

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleClick = (selectedUser: IUserAccountGeneral) => {
    //Set selected user in state
    dispatch.user.getUserInfo(selectedUser);

    navigate(PATH_DASHBOARD.user.profile(selectedUser.id));
  };

  return (
    <>
      <TableRow hover selected={selected} onClick={() => handleClick(row)}>
        {/*<TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>*/}

        <TableCell>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar
              alt={name}
              src={
                'https://res.cloudinary.com/siempresomos/image/upload/v1683228294/defaulUser.png'
              }
            />

            <Stack>
              <Typography variant="subtitle2" noWrap>
                {name}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                {email}
              </Typography>
            </Stack>
          </Stack>
        </TableCell>

        <TableCell align="center">{averageGlucose} mg/dL</TableCell>
        <TableCell align="center">{hoursInRange} horas </TableCell>
        <TableCell align="center" sx={{ textTransform: 'capitalize' }}>
          {glucoseSpike}
        </TableCell>

        <TableCell align="center">{sensorLifeDays} dias</TableCell>

        <TableCell align="center">
          <Label
            variant="soft"
            color={
              (status === 'Optimo' && 'primary') ||
              (status === 'Aceptable' && 'warning') ||
              (status === 'Critico' && 'error') ||
              (status === 'Empty' && 'default') ||
              'default'
            }
            sx={{ textTransform: 'capitalize' }}>
            {status !== 'Empty' ? status : 'Inactivo'}
          </Label>
        </TableCell>

        <TableCell align="right">
          {/*
          <IconButton
            color={openPopover ? "inherit" : "default"}
            onClick={handleOpenPopover}
          >
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
*/}
        </TableCell>
      </TableRow>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-top"
        sx={{ width: 140 }}>
        <MenuItem
          onClick={() => {
            handleOpenConfirm();
            handleClosePopover();
          }}
          sx={{ color: 'error.main' }}>
          <Iconify icon="eva:trash-2-outline" />
          Delete
        </MenuItem>

        <MenuItem
          onClick={() => {
            onEditRow();
            handleClosePopover();
          }}>
          <Iconify icon="eva:edit-fill" />
          Edit
        </MenuItem>
      </MenuPopover>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />
    </>
  );
}

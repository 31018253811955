import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import type { RootState } from '../../../../core';

import { useTheme } from '@mui/material/styles';

import axios from 'axios';

import dayjs from 'dayjs';
import moment from 'moment';
import { format } from 'date-fns';

import { useNavigate } from 'react-router-dom';

// MUI
import {
  Card,
  Alert,
  Grid,
  Container,
  CardHeader,
  CardContent,
  Stack,
  Box,
  Typography,
  Button,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
} from '@mui/material';

import CircularProgress from '@mui/material/CircularProgress';

import {
  TableHeadCustom,
  useTable,
  TableNoData,
  TableEmptyRows,
  TablePaginationCustom,
} from '../../../../components/table';
// Styles
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import classnames from 'classnames';

// replace with commercial version
import CanvasJSReact from '@canvasjs/react-charts';

// Local imports
import { GLOBAL } from '../../../../constants/variables';
import { API_URL } from 'constants/api';

// Components
import Iconify from '../../../../components/iconify';
import Label from '../../../../components/label';
import { DateFilterResumen } from '../../../../components/date-filter';
import WidgetSummary from './WidgetSummary';
import EmptyContent from '../../../../components/empty-content';
import { type Filter } from '../../../../components/date-filter/types';

import { BookingCheckInWidgets } from '../../../../sections/@dashboard/general/booking';
import { FileGeneralStorageOverview } from '../../../../sections/@dashboard/general/file';
// API
import api from '../../../../services/api';
// ----------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  circleIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    color: 'white',
    '&.critico': {
      backgroundColor: '#EA5944',
    },
    '&.optimo': {
      backgroundColor: '#00AD69',
    },
    '&.default': {
      backgroundColor: '#54565A',
    },
    '&.aceptable': {
      backgroundColor: '#FFA043',
    },
  },
}));

const StyledIcon = styled(Iconify)(({ theme }) => ({
  width: '20px',
  height: '20px',
  color: 'white',
}));

const MemoizedWidgetSummary = React.memo(WidgetSummary);

interface GlucoseData {
  blood_glucose_mg_per_dL: number;
  timestamp: string;
}

interface GlucoseDetail {
  promedio: {
    value: number;
    rango: string;
  };
  enRango: {
    value: number;
    rango: string;
  };
  picos: {
    value: number;
    rango: string;
  };
  cv: {
    value: number;
    rango: string;
  };
}

interface EventData {
  category: string;
  date: string;
  title: string;
  user_id: string;
  id: string;
  is_seen: boolean;
  score: string;
  glucoseRange: string;
  glucoseSpike: number;
}

const TABLE_HEAD = [
  { id: 'name', label: 'Nombre' },
  { id: 'value', label: 'Valor', align: 'right' },
  { id: 'unit', label: 'Unidades', align: 'left' },
  { id: 'status', label: 'Estatus', align: 'right' },
  { id: 'range', label: 'Rango', align: 'right' },
];

const STATUS_OPTIONS = [
  {
    label: 'Todos',
    color: 'info',
  },
  {
    label: 'Optimo',
    color: 'primary',
  },
  {
    label: 'Aceptable',
    color: 'warning',
  },
  {
    label: 'Critico',
    color: 'error',
  },
];

interface IndicatorData {
  _id: string;
  name: string;
  value: string;
  unit: number;
  range: string;
  created_at: string;
  template_indicator_id?: {
    values: {
      title?: string;
    };
  };
}

const getStatus = (range: string) => {
  switch (range) {
    case '1':
    case 'm1':
    case 'f1':
    case '5':
    case 'm5':
    case 'f5':
      return 'Critico';
    case '2':
    case 'm2':
    case 'f2':
    case '4':
    case 'm4':
    case 'f4':
      return 'Aceptable';
    case '3':
    case 'm3':
    case 'f3':
      return 'Optimo';
    default:
      return range; // Add a default case for unknown values
  }
};

export default function Resumen() {
  const classes = useStyles();

  const [glucoseData, setGlucoseData] = useState<GlucoseData[]>([]);
  const [glucoseDetail, setGlucoseDetail] = useState<GlucoseDetail | null>(null);

  const token = localStorage.getItem(GLOBAL.TOKEN_KEY);
  const [tableData, setTableData] = useState<IndicatorData[]>([]);

  const [allIndicatorResults, setAllIndicatorResults] = useState<IndicatorData[]>([]);
  const [eventData, setEventData] = useState<EventData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [filterStatus, setFilterStatus] = useState('Critico');

  const [totalPages, setTotalPages] = useState(0); // state variable for total pages
  const [totalRows, setTotalRows] = useState(0); // state variable for total rows

  const [openaiIndicatorResponse, setOpenaiIndicatorResponse] = useState('');

  const theme = useTheme();

  const userState = useSelector((state: RootState) => state.user);
  //  const filter: Filter = userState.settings.filter;

  const navigate = useNavigate();

  const {
    page,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const defaultFilterValue = {
    type: 'days',
    days: 3,
  };
  const filter: Filter = userState?.settings?.filter ?? defaultFilterValue;

  const GB = 1000000000 * 24;

  useEffect(() => {
    let urlIndicatorString: string;

    urlIndicatorString = `${API_URL}users/indicators/${userState.user.id}`;

    if (filterStatus && filterStatus.trim() !== '') {
      urlIndicatorString = urlIndicatorString + '?range=' + filterStatus;
    } else {
      urlIndicatorString = urlIndicatorString + '?range=Critico';
    }
    /*
    if (filter.type === 'days') {
      // Calculate today's date
      const today = dayjs().format('YYYY-MM-DD');
      // Calculate the date six months ago
      const sixMonthsAgo = dayjs().subtract(6, 'months').format('YYYY-MM-DD');
      urlIndicatorString = urlIndicatorString + `&dategte=${sixMonthsAgo}&datelte=${today}`;
    } else if (filter.date?.start && filter.date?.end) {
      urlIndicatorString =  urlIndicatorString + `&dategte=${filter.date?.start}&datelte=${filter.date?.end}`;
    }
*/
    const fetchIndicatorData = async () => {
      console.log('urlIndicatorString ---- ', urlIndicatorString);
      await axios
        .get(urlIndicatorString, {
          headers: {
            application: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then(indicatorResults => {
          console.log('indicatorResults.data --- ', indicatorResults.data);

          const data = indicatorResults.data.data;
          const modifiedData = data.map((indicator: IndicatorData) => {
            const { title } = indicator.template_indicator_id?.values || {};
            return { ...indicator, title };
          });

          const first4Indicators = modifiedData.slice(0, 5);
          setAllIndicatorResults(modifiedData);
          setTableData(first4Indicators);
          setTotalRows(indicatorResults.data.count);
          setTotalPages(Math.ceil(indicatorResults.data.count / 20)); // assuming that you're displaying 20 rows per page
        })
        .catch(err => {
          if (err.message === 'Request failed with status code 401') {
            console.log('failed request ');
          }
        });
    };

    let urlStatusString: string;

    urlStatusString = `${API_URL}users/indicators/range-count/${userState.user.id}`;

    fetchIndicatorData();
  }, [filterStatus]);

  useEffect(() => {
    const indicatorsText = allIndicatorResults
      .map(indicator => `${indicator.name} ${indicator.value} ${indicator.unit}`)
      .join(', ');

    const fetchIndicatorInsight = async () => {
      try {
        const response = await axios.post(
          'https://api.openai.com/v1/chat/completions',
          {
            model: 'gpt-3.5-turbo',
            messages: [
              {
                role: 'user',
                content:
                  'help understand the risk factors for a patient with these blood markers:  ${indicatorsText} Also detect any current diagnosis and any impending trends towards a diagnosis in the future. The patient is male, 40 years old, has a sedentary lifestyle: CRP 5mg/dL, TSH 5.02µUI/mL, Ácido Úrico 5.83 (mg/dL), Triglicéridos 236.7(mg/dL), HOMA 3.84, Colesterol Total 141.4(mg/dL), Creatinina 1.37(mg/dL). Summarize your response in less than 3 sentences in spanish.',
              },
            ],
            temperature: 0.7,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${process.env.REACT_APP_OPENAI_KEY}`,
            },
          },
        );

        const assistantMessage = response.data.choices[0].message.content;
        setOpenaiIndicatorResponse(assistantMessage);
        console.log('Open AI Response', response.data);
        // You can replace the content in the 'indicator-insight' element here
        //const indicatorInsightElement = document.getElementById('indicator-insight');
        // if (indicatorInsightElement) {
        //   indicatorInsightElement.innerText = assistantMessage;
        // }
      } catch (error) {
        console.error('Error calling OpenAI API:', error);
      }
    };

    fetchIndicatorInsight();
  }, []);

  //clean up later

  const renderStorageOverview = (
    <FileGeneralStorageOverview
      total={GB}
      chart={{
        series: 76,
      }}
      data={[
        {
          name: 'Images',
          usedStorage: GB / 2,
          filesCount: 223,
          icon: <Box component="img" src="/assets/icons/files/ic_img.svg" />,
        },
        {
          name: 'Media',
          usedStorage: GB / 5,
          filesCount: 223,
          icon: <Box component="img" src="/assets/icons/files/ic_video.svg" />,
        },
        {
          name: 'Documents',
          usedStorage: GB / 5,
          filesCount: 223,
          icon: <Box component="img" src="/assets/icons/files/ic_document.svg" />,
        },
        {
          name: 'Other',
          usedStorage: GB / 10,
          filesCount: 223,
          icon: <Iconify icon="eva:file-fill" width={24} sx={{ color: 'text.disabled' }} />,
        },
      ]}
    />
  );

  useEffect(() => {
    setLoading(true);

    let urlMetricsString: string;

    if (filter && filter.type === 'days') {
      // get glucose data
      urlMetricsString = `${API_URL}glucose/metrics/${userState.user.id}?days=${filter.days}`;
    } else {
      // get glucose data
      urlMetricsString = `${API_URL}glucose/metrics/${userState.user.id}?dategte=${filter.date?.start}&datelte=${filter.date?.end}`;
    }

    const fetchGlucoseData = async () => {
      const res = await api.get(urlMetricsString);
      return res.data;
    };

    Promise.all([fetchGlucoseData()])
      .then(([glucoseRes]) => {
        setGlucoseData(glucoseRes.graphGlucose);
        setGlucoseDetail(glucoseRes.glucoseDetail);
        // setEventData(eventRes.data);
        setLoading(false);
      })
      .catch(err => {
        console.error('Error while fetching data: ', err);
        setLoading(false);
      });
  }, [filter]);

  const [options, setOptions] = useState({});

  const isNotFound = !tableData.length;

  const getRandomIcon = () => {
    const icons = ['eva:trending-down-fill', 'eva:trending-up-fill', 'ci:arrow-right-lg'];
    const randomIndex = Math.floor(Math.random() * icons.length);
    return icons[randomIndex];
  };

  if (loading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="30vh" // This will fill the screen, adjust according to your needs
      >
        <Typography variant="h6" component="h2" sx={{ marginBottom: '30px' }}>
          {' '}
          Cargando...
        </Typography>
        <CircularProgress size={60} />
      </Box>
    );
  } else {
    return (
      <>
        <DateFilterResumen />
        <div id="profile-content">
          <Grid container spacing={3} sx={{ marginTop: 5 }}>
            <Grid item xs={12} md={3} lg={3}>
              {renderStorageOverview}

              {/*}
              <Card dir="ltr">
                <CardHeader title="Signos vitales" />
                <CardContent>
{renderStorageOverview}

                </CardContent>
              </Card>
              */}
            </Grid>

            <Grid item xs={12} md={9} lg={9}>
              <Card dir="ltr">
                <CardHeader
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                  title={`${totalRows} Indicadores ${filterStatus}s`}
                  action={
                    <Button
                      onClick={() =>
                        navigate(`/dashboard/user/${userState.user.id}/indicators?filter=Critico`)
                      }
                      variant="outlined"
                      color="primary"
                      style={{ marginLeft: '0px' }} // Adjust the margin as needed
                    >
                      Ver más
                    </Button>
                  }
                />

                <CardContent>
                  <TableContainer sx={{ mt: 0, overflow: 'unset' }}>
                    <Table sx={{ minWidth: 800 }}>
                      {/*
                      <TableHeadCustom headLabel={TABLE_HEAD} />
*/}
                      <TableBody>
                        {tableData?.map(indicator => (
                          <TableRow key={indicator._id}>
                            <TableCell style={{ padding: '8px', textAlign: 'left' }}>
                              {indicator.name}{' '}
                            </TableCell>
                            <TableCell style={{ padding: '8px', textAlign: 'right' }}>
                              {indicator.value ? `${indicator.value} ` : '-'}{' '}
                            </TableCell>
                            <TableCell style={{ padding: '8px', textAlign: 'left' }}>
                              {indicator.unit ? `${indicator.unit}` : '-'}
                            </TableCell>

                            <TableCell style={{ padding: '8px', textAlign: 'left' }}>
                              <Iconify icon={getRandomIcon()} />
                            </TableCell>

                            {/*} <TableCell align="right">
                    {indicator.created_at
                      ? format(new Date(indicator.created_at), 'yyyy-MM-dd')
                      : '-'}
                    </TableCell> */}

                            <TableCell style={{ padding: '8px', textAlign: 'right' }}>
                              <Label
                                variant="soft"
                                color={
                                  getStatus(indicator.range) === 'Optimo'
                                    ? 'success'
                                    : getStatus(indicator.range) === 'Aceptable'
                                    ? 'warning'
                                    : 'error'
                                }
                                sx={{
                                  textTransform: 'capitalize',
                                  backgroundColor:
                                    getStatus(indicator.range) === 'Aceptable'
                                      ? 'rgba(255, 171, 0, 0.16)'
                                      : undefined,
                                  color:
                                    getStatus(indicator.range) === 'Aceptable'
                                      ? '#D28438'
                                      : undefined,
                                }}>
                                {getStatus(indicator.range)}
                              </Label>
                            </TableCell>

                            <TableCell style={{ padding: '8px', textAlign: 'right' }}>
                              {indicator.template_indicator_id?.values
                                ? indicator.template_indicator_id.values.title || '-'
                                : '-'}
                            </TableCell>
                          </TableRow>
                        ))}

                        <TableNoData isNotFound={isNotFound} />
                      </TableBody>
                      {/*
                      <TableRow>
                        <TableCell style={{ padding: '2px' }} colSpan={5} align="center">
                          <Button
                            onClick={() =>
                              navigate(`/dashboard/user/${userState.user.id}/indicators`)
                            }>
                            Ver más
                          </Button>
                        </TableCell>
                      </TableRow>

                          */}
                    </Table>
                  </TableContainer>

                  {/*
                  <Grid container spacing={3}>

                    <Grid item xs={12} sm={4} md={4}>
                      <MemoizedWidgetSummary units="" title="Critico" total={4} color="error" />
                    </Grid>

                    <Grid item xs={12} sm={4} md={4}>
                      <MemoizedWidgetSummary units="" title="Aceptable" total={5} color="warning" />
                    </Grid>

                    <Grid item xs={12} sm={4} md={4}>
                      <MemoizedWidgetSummary
                        units=""
                        title="Optimos"
                        total={12}
                        color="secondary"
                      />
                    </Grid>
                  </Grid>
                  */}

                  <Stack sx={{ marginTop: 2 }}>
                    {openaiIndicatorResponse && openaiIndicatorResponse.trim() !== '' && (
                      <Alert
                        key="info"
                        id="indicator-insight"
                        severity="info"
                        sx={{ fontSize: '15px' }}>
                        {openaiIndicatorResponse}
                      </Alert>
                    )}
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          {glucoseData.length === 0 && eventData.length === 0 ? (
            <EmptyContent title="Sin Data" sx={{ '& span.MuiBox-root': { height: 160 } }} />
          ) : (
            <>
              <Grid container sx={{ marginTop: 5 }}>
                <Grid item xs={12} md={12} lg={12}>
                  <Card dir="ltr">
                    <CardHeader title="Salud metabólica" />
                    <CardContent>
                      {/* Render the glucoseDetail information */}
                      {glucoseDetail && (
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6} md={3}>
                            <MemoizedWidgetSummary
                              units="mg/dL"
                              title="Glucosa"
                              total={glucoseDetail.promedio.value}
                              color={
                                glucoseDetail.promedio.rango === 'Optimo'
                                  ? 'secondary'
                                  : glucoseDetail.promedio.rango === 'Aceptable'
                                  ? 'warning'
                                  : 'error'
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={6} md={3}>
                            <MemoizedWidgetSummary
                              units="%"
                              title="Tiempo en Rango"
                              total={glucoseDetail.enRango.value}
                              color={
                                glucoseDetail.enRango.rango === 'Optimo'
                                  ? 'secondary'
                                  : glucoseDetail.enRango.rango === 'Aceptable'
                                  ? 'warning'
                                  : 'error'
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={6} md={3}>
                            <MemoizedWidgetSummary
                              units="%"
                              title="Hb1ac est"
                              total={5}
                              color="secondary"
                            />
                          </Grid>

                          <Grid item xs={12} sm={6} md={3}>
                            <MemoizedWidgetSummary
                              units="%"
                              title="Variabilidad"
                              total={glucoseDetail.cv.value}
                              color={
                                glucoseDetail.cv.rango === 'Optimo'
                                  ? 'secondary'
                                  : glucoseDetail.cv.rango === 'Aceptable'
                                  ? 'warning'
                                  : 'error'
                              }
                            />
                          </Grid>
                        </Grid>
                      )}

                      <Stack sx={{ marginTop: 4 }}>
                        <Alert key="info" severity="info" sx={{ fontSize: '15px' }}>
                          Los niveles de glucosa promedio de este paciente son altos y, basándonos
                          en la variabilidad y el tiempo fuera de rango, este paciente es
                          prediabético
                        </Alert>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>

              <Grid container sx={{ marginTop: 5 }}>
                <Grid item xs={12} md={12} lg={12}>
                  <Card dir="ltr">
                    <CardHeader title="Wearables" />
                    <CardContent>
                      <BookingCheckInWidgets
                        chart={{
                          colors: [theme.palette.warning.main],
                          series: [
                            { label: '3,000 pasos / dia', percent: 72, total: 'Actividad' },
                            { label: '8 horas / dia', percent: 64, total: 'Sueño' },
                            { label: '50 HRV', percent: 80, total: 'Estrés' },
                          ],
                        }}
                      />

                      <Stack sx={{ marginTop: 4 }}>
                        <Alert key="info" severity="info" sx={{ fontSize: '15px' }}>
                          El sueño interrumpido o insuficiente puede afectar la sensibilidad a la
                          insulina y el metabolismo de la glucosa
                        </Alert>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>

              <Alert
                severity="info"
                sx={{ marginTop: 3 }}
                variant="filled"
                action={
                  <>
                    <Button
                      color="inherit"
                      size="small"
                      variant="outlined"
                      sx={{
                        mr: 1,
                        border: theme => `1px solid grey.800`,
                      }}>
                      Mandar prueba
                    </Button>

                    <Button
                      size="small"
                      color="inherit"
                      variant="contained"
                      sx={{
                        bgcolor: 'common.white',
                      }}>
                      Realizar plan
                    </Button>
                  </>
                }>
                Se recomienda realizar más estudios y hacer un protocolo para la salud
                cardiovascular.
              </Alert>
            </>
          )}
        </div>
      </>
    );
  }
}

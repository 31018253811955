import React from 'react';
// @mui
import { Stack, Typography } from '@mui/material';

// components
import AuthLoginForm from './AuthLoginForm';
import AuthWithSocial from './AuthWithSocial';

// layouts
import LoginLayout from '../../views/login';
//

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <LoginLayout>
      <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
        <Typography variant="h4">Entra al Admin de Somos</Typography>
      </Stack>

      <Stack spacing={2} sx={{ mb: 5 }}>
        <AuthLoginForm />
        <AuthWithSocial title="Iniciar con google" />
        {/* <Stytch /> */}
      </Stack>
    </LoginLayout>
  );
}

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// @mui
import { Grid, Stack } from '@mui/material';

// @types
import type { RootState } from '../../../../core';
import { useSnackbar } from '../../../../components/snackbar';

import axios from 'axios';

// Variables
import { GLOBAL } from '../../../../constants/variables';
import { API_URL } from 'constants/api';

import { useParams } from 'react-router-dom';

import { FileGeneralRecentCard } from '../../../../sections/@dashboard/general/file';

import { FilePanel, FileNewFolderDialog } from '../../../../sections/@dashboard/file';

type FileItem = {
  id: string;
  urlFile: string;
  name: string;
  size: number;
  type: string; // the file type e.g. "image/jpeg"
  url: string;
  // Add other properties of a file as needed
};

//import { _files } from '../../../../_mock/arrays';

// ----------------------------------------------------------------------

export default function Files() {
  const dispatch = useDispatch();
  const userState = useSelector((state: RootState) => state.user);
  const user = userState.user;

  const id = user.id; // Get the userId from the URL
  const token = localStorage.getItem(GLOBAL.TOKEN_KEY);

  const [openUploadFile, setOpenUploadFile] = useState(false);

  const [openNewFolder, setOpenNewFolder] = useState(false);

  const [folderName, setFolderName] = useState('');

  //const [files, setFiles] = useState([]);  // State for storing retrieved files
  const [files, setFiles] = useState<FileItem[]>([]);

  const { enqueueSnackbar } = useSnackbar();

  const handleOpenUploadFile = () => {
    //console.log('clicked');
    setOpenUploadFile(true);
  };

  const handleCloseNewFolder = () => {
    setOpenNewFolder(false);
  };

  const handleCloseUploadFile = () => {
    setOpenUploadFile(false);
  };

  const handleCreateNewFolder = () => {
    handleCloseNewFolder();
    setFolderName('');
    console.log('CREATE NEW FOLDER', folderName);
  };

  const handleChangeFolderName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFolderName(event.target.value);
  };

  const getFiles = async () => {
    const urlStringFiles = `${API_URL}medical-history/files/user/${id}`;
    console.log('urlStringFiles', urlStringFiles);

    try {
      const response = await axios.get(urlStringFiles, {
        headers: {
          application: 'application/json',
          authorization: `Bearer ${token}`,
        },
      });
      console.log('results', response);
      // setFiles(response.data);  // assuming the API returns the files directly in the data field.
      setFiles(
        response.data.map((f: any) => ({
          id: f.id,
          name: f.fileName, //f.someNameField,  // adjust this
          size: 'size', //f.someSizeField,  // adjust this
          type: 'pdf', // f.someTypeField,  // adjust this
          url: f.urlFile,
          // ... Add mapping for other fields
        })),
      );
    } catch (error) {
      if (error instanceof Error && error.message === 'Request failed with status code 401') {
        console.log('Request failed with status code 401');
        // dispatch.auth.logout();
      }
    }
  };

  useEffect(() => {
    getFiles();

    if (location.search.includes('upload=success')) {
      enqueueSnackbar('Archivo subido');
    }
  }, []);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <FilePanel title="Estudios recientes" onOpen={handleOpenUploadFile} sx={{ mt: 2 }} />

          <Stack spacing={2}>
            {files &&
              files.length > 0 &&
              files.map(file => (
                <FileGeneralRecentCard
                  key={file.id}
                  file={file}
                  onDelete={() => {
                    console.log('DELETE', file.id);
                  }}
                />
              ))}

            {/*}   
            {_files.slice(0, 5).map(file => (
              <FileGeneralRecentCard
                key={file.id}
                file={file}
                onDelete={() => { console.log('DELETE', file.id); }}
              />
            ))}
*/}
          </Stack>
        </Grid>
      </Grid>

      <FileNewFolderDialog open={openUploadFile} onClose={handleCloseUploadFile} />

      <FileNewFolderDialog
        open={openNewFolder}
        onClose={handleCloseNewFolder}
        title="New Folder"
        folderName={folderName}
        onChangeFolderName={handleChangeFolderName}
        onCreate={handleCreateNewFolder}
      />
    </>
  );
}

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { type RootState } from '../../core';

import api from '../../services/api';

// MUI
import { Container, Typography, Stack, Box } from '@mui/material';

// Components
import { useSettingsContext } from 'components/settings';
import { UserTable, UserTableEmptyState } from 'sections/@dashboard/user/list';

const Home = () => {
  const { themeStretch } = useSettingsContext();

  const authState = useSelector((state: RootState) => state.auth);
  const auth = authState.auth;

  const accountStore = useSelector((state: RootState) => state.account);
  const { user, settings } = accountStore;

  const dispatch = useDispatch();

  useEffect(() => {
    getUserList();
  }, []);

  const getUserList = () => {
    api
      .get('users')
      .then(response => {
        const data = response.data.data;

        if (data.length === 0) {
          dispatch.account.getEmptyState(true);
        } else {
          dispatch.account.getEmptyState(false);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <Container maxWidth={themeStretch ? false : 'lg'}>
      <Stack spacing={6}>
        <Container maxWidth={themeStretch ? false : 'lg'}>
          <Typography
            variant="h4"
            sx={{ textTransform: 'capitalize' }}>{`Hola ${user.name}`}</Typography>
        </Container>
        {settings.isEmpty ? (
          <UserTableEmptyState />
        ) : (
          <Box>
            <Typography variant="h6" sx={{ px: 3, pb: 2 }}>
              Pacientes
            </Typography>
            <UserTable />
          </Box>
        )}
      </Stack>
    </Container>
  );
};

export default Home;

import React from 'react';

import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Link, Typography } from '@mui/material';
// routes
import { PATH_AUTH } from '../../routes/paths';
// components
import Iconify from '../../components/iconify';
// sections
import VerifyCodeForm from './VerifyCodeForm';
// assets
import { PasswordIcon } from '../../assets/icons';

// ----------------------------------------------------------------------

export default function VerifyLoginPage() {
  return (
    <>
      <PasswordIcon sx={{ mb: 5, height: 96 }} />

      <Typography variant="h3" paragraph>
        Verifica tu número
      </Typography>

      <Typography sx={{ color: 'text.secondary', mb: 5 }}>
        Te enviaremos un código de verificación a tú número celular para que puedas iniciar sesión
      </Typography>

      <VerifyCodeForm />

      <Typography variant="body2" sx={{ my: 3 }}>
        No me llegó. &nbsp;
        <Link variant="subtitle2">Solicitar otro código</Link>
      </Typography>

      <Link
        component={RouterLink}
        to={PATH_AUTH.login}
        color="inherit"
        variant="subtitle2"
        sx={{
          mx: 'auto',
          alignItems: 'center',
          display: 'inline-flex',
        }}>
        <Iconify icon="eva:chevron-left-fill" width={16} />
        Regresar
      </Link>
    </>
  );
}

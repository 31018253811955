import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { type RootState } from '../../core';
// @mui
import { Link, Typography, Stack, Stepper, Step, StepLabel } from '@mui/material';
// routes
import { PATH_AUTH } from '../../routes/paths';
// components
import Iconify from '../../components/iconify';

// sections
import InviteUsersForm from '../../sections/onboarding/InviteUsersForm';

// assets
import { SentIcon } from '../../assets/icons';

// ----------------------------------------------------------------------

const steps = ['Paso 1', 'Paso 2'];

export default function InviteUsersPage() {
  const accountState = useSelector((state: RootState) => state.account);
  const { user } = accountState;

  return (
    <>
      <Stack
        sx={{
          m: 'auto',
          maxWidth: 400,
          minHeight: '100vh',
          textAlign: 'center',
          justifyContent: 'center',
        }}>
        <SentIcon sx={{ mb: 5, height: 96 }} />

        <Typography variant="h3" paragraph>
          Agrega a tus colegas
        </Typography>

        <Typography sx={{ color: 'text.secondary', mb: 5 }}>
          Invita a tus colegas para que trabajen todos juntos en:{' '}
          <span style={{ fontWeight: 700 }}>{user.organization}</span>
        </Typography>

        <InviteUsersForm isOnboarding={true} />

        <Stepper sx={{ mt: 4 }} activeStep={1} alternativeLabel>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Stack>
    </>
  );
}

import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Link, Typography, Stack, Stepper, Step, StepLabel } from '@mui/material';

// sections
import CreateOrganizationForm from '../../sections/onboarding/CreateOrganizationForm';
// assets
import { LogoIcon } from '../../assets/icons';

// ----------------------------------------------------------------------

const steps = ['Paso 1', 'Paso 2'];

export default function CreateOrganizationPage() {
  return (
    <>
      <Stack
        sx={{
          m: 'auto',
          maxWidth: 400,
          minHeight: '100vh',
          textAlign: 'center',
          justifyContent: 'center',
        }}>
        <LogoIcon sx={{ mb: 5, height: 96 }} />

        <Typography variant="h3" paragraph>
          Crea tu organización
        </Typography>

        <Typography sx={{ color: 'text.secondary', mb: 5 }}>
          Una organización es el espacio donde tú y tus colegas pueden administrar a sus pacientes.
        </Typography>

        <CreateOrganizationForm />

        <Stepper sx={{ mt: 4 }} alternativeLabel>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Stack>
    </>
  );
}

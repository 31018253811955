import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from '../../core';
import { Outlet, useLocation } from 'react-router-dom';
// @mui
import { Tab, Card, Tabs, Container, Box } from '@mui/material';

// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// _mock_
import { _userAbout } from '../../_mock/arrays';
// components
import Iconify from '../../components/iconify';
import CustomBreadcrumbs from '../../components/custom-breadcrumbs';
import { useSettingsContext } from '../../components/settings';
import { useParams, useNavigate } from 'react-router-dom';

// sections
import {
  Profile,
  Wearable,
  Files,
  ProfileCover,
  ProfileGlucose,
  EventDetailPage,
  IndicatorDetailPage,
  EventListPage,
  Consultations,
  IndicatorsListPage,
  Resumen,
} from '../../sections/@dashboard/user/profile';

// ----------------------------------------------------------------------

export default function UserProfilePage() {
  const navigate = useNavigate();
  const location = useLocation();

  const { themeStretch } = useSettingsContext();
  const lastSegment = location.pathname.split('/').pop();

  const userState = useSelector((state: RootState) => state.user);
  const user = userState.user;
  const [currentTab, setCurrentTab] = useState(lastSegment || 'glucose');

  const accountStore = useSelector((state: RootState) => state.account);
  const userAccount = accountStore.user;
  const adminStatus = accountStore.admin;

  const handleTabsChange = (event: React.ChangeEvent<{}>, value: string) => {
    navigate(`${value}`);

    setCurrentTab(value);
  };

  const TABS = [
    {
      value: 'glucose',
      label: 'Glucosa',
      icon: <Iconify icon="material-symbols:glucose" />,
      component: <ProfileGlucose />,
    },
    {
      value: 'events',
      label: 'Eventos',
      icon: <Iconify icon="eva:clock-fill" />,
      component: <EventListPage />,
    },
    {
      value: 'indicators',
      label: 'Indicadores',
      icon: <Iconify icon="healthicons:blood-drop" />,
      component: <IndicatorsListPage />,
    },
    {
      value: 'wearables',
      label: 'Wearables',
      icon: <Iconify icon="material-symbols:devices-wearables" />,
      component: <Wearable />,
    },
    {
      value: 'files',
      label: 'Estudios',
      icon: <Iconify icon="ph:files-fill" />,
      component: <Files />,
    },
    {
      value: 'profile',
      label: 'Perfil',
      icon: <Iconify icon="ic:round-account-box" />,
      component: <Profile />,
    },
  ];

  // Conditionally add the consultations tab if the userAccount email matches
  if (userAccount?.email === 'cristina@somos.me') {
    TABS.push({
      value: 'consultation',
      label: 'Consultas',
      icon: <Iconify icon="mdi-calendar" />,
      component: <Consultations />,
    });
    TABS.push({
      value: 'resumen',
      label: 'Resumen',
      icon: <Iconify icon="mdi-chart-line" />,
      component: <Resumen />,
    });
  }

  return (
    <>
      <Container maxWidth={themeStretch ? false : 'lg'} sx={{ paddingBottom: 6 }}>
        {adminStatus && (
          <CustomBreadcrumbs
            heading={'Perfil de ' + user.name}
            links={[
              { name: 'Dashboard', href: PATH_DASHBOARD.root },
              { name: 'Users', href: PATH_DASHBOARD.root },
              { name: user.name },
            ]}
          />
        )}
        <Card
          sx={{
            mb: 3,
            height: 210,
            position: 'relative',
          }}>
          <ProfileCover
            photoURL={user?.photoURL}
            id={user?.id}
            name={user?.name}
            role={user?.email}
            cover={_userAbout.cover}
          />

          <Tabs
            value={currentTab}
            onChange={(event, newValue) => handleTabsChange(event, newValue)}
            sx={{
              width: 1,
              bottom: 0,
              zIndex: 9,
              position: 'absolute',
              bgcolor: 'background.paper',
              '& .MuiTabs-flexContainer': {
                pr: { md: 3 },
                justifyContent: {
                  sm: 'center',
                  md: 'flex-end',
                },
              },
            }}>
            {TABS.map(tab => (
              <Tab key={tab.value} value={tab.value} icon={tab.icon} label={tab.label} />
            ))}
          </Tabs>
        </Card>
        <Outlet />
      </Container>
    </>
  );
}

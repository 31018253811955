import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../core/';
import { BrowserRouter } from 'react-router-dom';

// Components
import LoggedOutNavigation from './loggedout.navigator';
import LoggedInNavigation from './loggedin.navigator';

// Analytics
import { identifyUser } from '../services/analytics';

const Navigator = () => {
  const auth = useSelector((state: RootState) => state.auth);

  const accountState = useSelector((state: RootState) => state.account);
  const user = accountState.user;
  const adminStatus = accountState.admin;

  useEffect(() => {
    if (auth.auth && user) {
      window.Intercom('boot', {
        app_id: 'zt3ewpwd',
        name: user.name,
        email: user.email,
        user_id: user.id,
        // company: {
        //   name: user.organization,
        //   id: //TODO Add organization id
        // },
      });

      identifyUser(user);
    }
  }, [user]);

  return (
    <BrowserRouter>
      {auth.auth ? (
        <LoggedInNavigation isAdmin={adminStatus} userId={user.id} />
      ) : (
        <LoggedOutNavigation />
      )}
    </BrowserRouter>
  );
};

export default Navigator;

import { useState, useEffect, useCallback } from 'react';

import { useNavigate } from 'react-router-dom';
import { paramCase } from 'change-case';

import {
  Tab,
  Tabs,
  Card,
  Table,
  Button,
  Tooltip,
  Divider,
  Container,
  IconButton,
  TableRow,
  TableBody,
  MenuItem,
  TableCell,
  TableContainer,
} from '@mui/material';

import { PATH_DASHBOARD } from '../../routes/paths';

// components
import Iconify from '../../components/iconify';
import Label, { LabelColor } from '../../components/label';

// for api calls
import axios from 'axios';
import { GLOBAL } from '../../constants/variables';
import { API_URL } from 'constants/api';

import { format } from 'date-fns';

import CustomBreadcrumbs from '../../components/custom-breadcrumbs';
import { useSettingsContext } from '../../components/settings';

import MenuPopover from '../../components/menu-popover';
import ConfirmDialog from '../../components/confirm-dialog';

import {
  useTable,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TablePaginationCustom,
} from '../../components/table';

const token = localStorage.getItem(GLOBAL.TOKEN_KEY);

const TABLE_HEAD = [
  { id: 'name', label: 'Nombre', align: 'left' },
  { id: 'unit', label: 'Unidad', align: 'left' },
  { id: 'ranges', label: 'Rangos', align: 'left' },
  { id: 'edit', label: '', align: 'left' },
];

interface RangeItem {
  _id: string;
  title: string;
  range: string;
  interpretations: string;
  foodRecommendations: string;
  foodRestrictions: string;
  habitRecommendations: string;
  habitRestrictions: string;
  supplementRecommendations: string;
  supplementRestrictions: string;
}

interface RangeData {
  unit: string;
  name: string;
  _id: string;
  ranges: RangeItem[];
}

export default function Ranges({ withBreadcrumbs = true }) {
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0); // state variable for total pages
  const [totalRows, setTotalRows] = useState(0); // state variable for total rows
  const [tableData, setTableData] = useState<RangeData[]>([]);
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  const [openConfirm, setOpenConfirm] = useState(false);

  const navigate = useNavigate();

  const [selectedId, setSelectedId] = useState<string | null>(null);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const onEditRow = () => {
    console.log('edit row');
    if (selectedId) {
      // ensure selectedId is not null
      // console.log('this way', PATH_DASHBOARD.recommendations.edit(paramCase(selectedId)));

      navigate(PATH_DASHBOARD.ranges.edit(paramCase(selectedId))); // fix later
    }
  };

  function getColorForRangeValue(totalValues: number, currentIndex: number) {
    if (totalValues === 5) {
      if (currentIndex === 0 || currentIndex === 4) {
        return 'error';
      } else if (currentIndex === 1 || currentIndex === 3) {
        return 'default';
      } else if (currentIndex === 2) {
        return 'success';
      }
    } else if (totalValues === 3) {
      if (currentIndex === 0 || currentIndex === 2) {
        return 'error';
      } else if (currentIndex === 1) {
        return 'default';
      }
    } else if (totalValues === 10) {
      if (currentIndex === 0 || currentIndex === 4 || currentIndex === 5 || currentIndex === 9) {
        return 'error';
      } else if (currentIndex === 2 || currentIndex === 7) {
        return 'success';
      }
    }
    return 'default'; // Default color for other cases
  }

  const handleDeleteRow = useCallback((id: string) => {
    const deleteRow = tableData.filter(row => row._id !== id);
    setSelected([]);
    setTableData(deleteRow);

    const dataInPage = tableData.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

    if (page > 0) {
      if (dataInPage.length < 2) {
        setPage(page - 1);
      }
    }
  }, []);

  const {
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  useEffect(() => {
    const getRanges = async () => {
      setLoading(true);
      const urlString = `${API_URL}template-indicator?page=${page + 1}`;
      console.log('urlString ----- ', urlString);

      await axios
        .get(urlString, {
          headers: {
            application: 'application/json',
            authorization: `Bearer ${token}`,
          },
        })
        .then(res => {
          setLoading(false);
          console.log('the ranges list', res.data);
          setTableData(res.data.data);
          setTotalRows(res.data.count);
          setTotalPages(Math.ceil(res.data.count / 20));
        })
        .catch(err => {
          if (err.message === 'Request failed with status code 401') {
            console.log('Request failed with status code 401');
            // dispatch.auth.logout();
          }
        });
    };
    getRanges();
  }, [page]);

  return (
    <>
      <Container sx={{ my: 5 }}>
        {withBreadcrumbs && (
          <CustomBreadcrumbs
            heading="Rangos"
            links={[
              { name: 'Dashboard', href: PATH_DASHBOARD.root },
              { name: 'Rangos', href: PATH_DASHBOARD.ranges.root },
            ]}
          />
        )}

        <Card sx={{ marginTop: '30px' }}>
          <TableContainer sx={{ mt: 3, overflow: 'unset' }}>
            <Table sx={{ minWidth: 800 }}>
              <TableHeadCustom headLabel={TABLE_HEAD} />

              <TableBody>
                {tableData &&
                  tableData.map(range => (
                    <TableRow key={range._id}>
                      <TableCell>{range.name} </TableCell>
                      <TableCell align="left"> {range.unit} </TableCell>

                      <TableCell align="left">
                        {range.ranges.map((rangeValue, index) => (
                          <Label
                            variant="soft"
                            color={getColorForRangeValue(range.ranges.length, index)}
                            sx={{
                              fontSize: '15px',
                              marginRight: '20px',
                              fontWeight: '600',
                              opacity: '0.6',
                            }}>
                            {rangeValue.title}
                          </Label>
                        ))}
                      </TableCell>

                      <TableCell align="right">
                        <IconButton
                          color={openPopover ? 'inherit' : 'default'}
                          onClick={event => {
                            handleOpenPopover(event);
                            setSelectedId(range._id);
                          }}>
                          <Iconify icon="eva:more-vertical-fill" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}

                <MenuPopover
                  open={openPopover}
                  onClose={handleClosePopover}
                  arrow="right-top"
                  sx={{ width: 140 }}>
                  {/*
                  <MenuItem
                    onClick={() => {
                      handleOpenConfirm();
                      handleClosePopover();
                    }}
                    sx={{ color: 'error.main' }}>
                    <Iconify icon="eva:trash-2-outline" />
                    Delete
                  </MenuItem>
                  */}
                  <MenuItem
                    onClick={() => {
                      onEditRow();
                      handleClosePopover();
                    }}>
                    <Iconify icon="eva:edit-fill" />
                    Editar
                  </MenuItem>
                </MenuPopover>

                <ConfirmDialog
                  open={openConfirm}
                  onClose={handleCloseConfirm}
                  title="Delete"
                  content="Are you sure want to delete?"
                  action={
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => {
                        if (selectedId) {
                          handleDeleteRow(selectedId);
                        }
                      }}>
                      Borrar
                    </Button>
                  }
                />
              </TableBody>
            </Table>
          </TableContainer>
          <TablePaginationCustom
            count={totalRows}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePage}
            //
          />
        </Card>
      </Container>
    </>
  );
}

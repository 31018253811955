// @mui
import { alpha, useTheme } from '@mui/material/styles';
import { Card, Typography, CardProps } from '@mui/material';
// utils
import { bgGradient } from '../../../../utils/cssStyles';
import { fShortenNumber } from '../../../../utils/formatNumber';
// theme
import { ColorSchema } from '../../../../theme/palette';

// ----------------------------------------------------------------------

interface Props extends CardProps {
  title: string;
  units: string;
  total: number;
  color?: ColorSchema;
}

export default function WidgetSummary({
  title,
  units,
  total,
  color = 'primary',
  sx,
  ...other
}: Props) {
  const theme = useTheme();

  return (
    <Card
      sx={{
        py: 5,
        boxShadow: 0,
        textAlign: 'center',
        color: theme.palette[color].darker,
        bgcolor: theme.palette[color].lighter,
        ...sx,
      }}
      {...other}>
      <Typography variant="subtitle2" sx={{ opacity: 0.64 }}>
        {title}
      </Typography>

      <Typography variant="h3">{total}</Typography>

      <Typography variant="subtitle2" sx={{ opacity: 0.64 }}>
        {units}
      </Typography>
    </Card>
  );
}

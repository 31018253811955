import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import Login from '../sections/auth/Login';
import VerifyLoginPage from 'sections/auth/VerifyLoginPage';
import LoginAdmin from '../sections/auth/LoginAdmin';
import Register from '../sections/auth/Register';
import ResetPasswordPage from 'views/auth/ResetPasswordPage';
import NewPasswordPage from 'views/auth/NewPasswordPage';
import ValidateSesion from '../views/login/ValidateSesion';
import Page404 from '../views/Page404';

// Views
import AcceptInvitePage from '../views/onboarding/AcceptInvitePage';

// Layouts
import CompactLayout from 'layout/compact/CompactLayout';

// Routes if user is logged out.
const LogOutNavigation = () => {
  return (
    <Routes>
      <Route path="/onboarding" element={<CompactLayout />}>
        <Route path="accept-invite" element={<AcceptInvitePage />} />
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="/login" element={<CompactLayout />}>
        <Route path="/login/verify" element={<VerifyLoginPage />} />
      </Route>
      <Route path="/register" element={<Register />} />
      <Route path="/reset-password" element={<ResetPasswordPage />} />
      <Route path="/new-password" element={<NewPasswordPage />} />
      <Route path="/validateSesion" element={<ValidateSesion />} />
      {/* <Route path="/404" element={<Page404 />} /> */}

      <Route path="/admin" element={<LoginAdmin />} />

      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default LogOutNavigation;

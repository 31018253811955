import React from 'react';

// form
import { Controller, useFormContext } from 'react-hook-form';

// @mui
import { MuiTelInput, matchIsValidTel, MuiTelInputProps } from 'mui-tel-input';
import { TextFieldProps } from '@mui/material';

type Props = {
  name: string;
  helperText?: string;
};

export default function RHFPhoneInput({ name, helperText, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <MuiTelInput
          {...field}
          defaultCountry="MX"
          placeholder="(123) 456 7890"
          value={field.value === 0 ? null : field.value}
          error={!!error}
          helperText={error ? error?.message : helperText}
          {...other}
        />
      )}
    />
  );
}

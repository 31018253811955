import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { type RootState } from '../../core';

import * as Yup from 'yup';
import axios, { type AxiosError } from 'axios';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// @mui
import { Stack, Alert, Button, InputAdornment, IconButton } from '@mui/material';

// components
import FormProvider, { RHFTextField, RHFSelect } from '../../components/hook-form';
import Iconify from '../../components/iconify';

// variables
import { API_URL } from '../../constants/api';
// ----------------------------------------------------------------------

interface FormValuesProps {
  email: string;
  password: string;
  afterSubmit?: string;
}

export default function AuthRegisterForm() {
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const tokenEmail = searchParams.get('token');

  const RegisterSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Este correo no es valido'),
    password: Yup.string()
      .required('Nos hace falta una contraseña')
      .min(
        8,
        'Tu contraseña debe de ser de 8 caracteres y tener al menos una mayúscula, una minúscula, un número y un caracter especial',
      )
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,}$/,
        'Tu contraseña debe de ser de 8 caracteres y tener al menos una mayúscula, una minúscula, un número y un caracter especial',
      ),
  });

  const defaultValues = {
    email: email ?? '',
    password: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      const response = await axios.post(`${API_URL}admin`, data);

      const { token, user } = response.data;
      localStorage.setItem('Token Stytch', token);
      // Handles Token
      dispatch.auth.getAuth(token);

      // Handles UserAccount
      dispatch.account.getAccountInfo(user);

      navigate(`/onboarding/update-account?token=${tokenEmail}`);
    } catch (error: any) {
      // TODO: handle error
      console.log(error);
      setError('afterSubmit', {
        ...error,
        message: error.message || error,
      });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2.5}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <RHFTextField name="email" label="Correo electronico" disabled={true} />

        <RHFTextField
          name="password"
          label="Constraseña"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                  edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Button fullWidth size="large" type="submit" variant="contained">
          Continuar
        </Button>
      </Stack>
    </FormProvider>
  );
}

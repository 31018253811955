import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import axios from 'axios';
import classnames from 'classnames';
import moment from 'moment';

// MUI
import {
  Card,
  Tab,
  Tabs,
  Container,
  Table,
  Divider,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';

// Components
import Iconify from '../../../../components/iconify';
import { DateFilter } from '../../../../components/date-filter';
// import Label from '../../../../components/label';
import Label, { type LabelColor } from '../../../../components/label';
import {
  useTable,
  TableHeadCustom,
  TablePaginationCustom,
  TableNoData,
} from '../../../../components/table';
import EventTableToolbar from './EventTableToolbar';

// Variables
import { GLOBAL } from '../../../../constants/variables';
import { API_URL } from 'constants/api';

// Types
import type { Filter } from '../../../../components/date-filter/types';
import type { RootState } from '../../../../core';

// to do: move to static global stylesheet

const useStyles = makeStyles(theme => ({
  hoverRow: {
    '&:hover': {
      backgroundColor: '#F4F6F8',
      cursor: 'pointer',
    },
  },
  circleIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',
    float: 'left',
    height: '30px',
    borderRadius: '50%',
    color: 'white',
    '&.critico': {
      backgroundColor: '#EA5944',
    },
    '&.optimo': {
      backgroundColor: '#00AD69',
    },
    '&.default': {
      backgroundColor: '#54565A',
    },
    '&.aceptable': {
      backgroundColor: '#FFA043',
    },
  },
  text: {
    marginLeft: '50px',
    display: 'flex', // Add display flex
    alignItems: 'center', // Align items vertically in the flex container
  },
}));

const StyledIcon = styled(Iconify)(({ theme }) => ({
  width: '20px',
  height: '20px',
  color: 'white',
}));

const TABLE_HEAD = [
  { id: 'name', label: 'Evento', align: 'center' },
  { id: 'created_at', label: 'Fecha', align: 'center' },
  { id: 'glucoseSpike', label: 'Glucosa maxima', align: 'center' },
  { id: 'minimumGlucose', label: 'Glucosa minima', align: 'center' },
  { id: 'intensityChange', label: 'Intensidad', align: 'center' },
  { id: 'outRange', label: 'Fuera de rango', align: 'center' },
  { id: 'score', label: 'Score', align: 'center' },
];

const STATUS_OPTIONS = [
  {
    label: 'Todos',
    color: 'info',
  },
  {
    label: 'Optimo',
    color: 'primary',
  },
  {
    label: 'Aceptable',
    color: 'warning',
  },
  {
    label: 'Critico',
    color: 'error',
  },
  {
    label: 'Calibrando',
    color: 'default',
  },
];

interface EventData {
  id: string;
  title: string;
  glucoseSpike: number;
  minimumGlucose: number;
  intensityChange: number;
  outRange: number;
  score: string;
  created_at: string;
  category: string;
  image: string;
  date: string;
}

export default function EventListPage() {
  const classes = useStyles();
  const { userId } = useParams(); // Get the userId from the URL
  const token = localStorage.getItem(GLOBAL.TOKEN_KEY);
  const [tableData, setTableData] = useState<EventData[]>([]);
  const [filterName, setFilterName] = useState('');
  const [totalRows, setTotalRows] = useState(0); // state variable for total rows

  const { page, rowsPerPage, onChangePage, setPage } = useTable();
  const [filterStatus, setFilterStatus] = useState('Todos');

  const [aceptableCount, setAceptableCount] = useState(0);
  const [criticoCount, setCriticoCount] = useState(0);
  const [optimoCount, setOptimoCount] = useState(0);
  const [calibrandoCount, setCalibrandoCount] = useState(0);
  const [totalEventCount, setTotalEventCount] = useState(0);

  const userState = useSelector((state: RootState) => state.user);
  const filter: Filter = userState.settings.filter;

  const navigate = useNavigate();

  const isFiltered = filterName !== '';

  const handleFilterStatus = (event: React.SyntheticEvent<Element, Event>, newValue: string) => {
    setPage(0);
    setFilterStatus(newValue);
  };

  useEffect(() => {
    const getStatusCount = async () => {
      let urlCountString: string;

      urlCountString = `${API_URL}events/score-count/${userId}`;

      if (filter.type === 'days') {
        urlCountString = urlCountString + `?days=${filter.days}`;
      } else {
        urlCountString =
          urlCountString + `?dategte=${filter.date?.start}&datelte=${filter.date?.end}`;
      }

      await axios
        .get(urlCountString, {
          headers: {
            application: 'application/json',
            authorization: `Bearer ${token}`,
          },
        })
        .then(res => {
          setAceptableCount(res.data.Aceptable);
          setCriticoCount(res.data.Critico);
          setOptimoCount(res.data.Optimo);
          setCalibrandoCount(res.data.Calibrando);
        })
        .catch(err => {
          if (err.message === 'Request failed with status code 401') {
            console.log('Request failed with status code 401');
            // dispatch.auth.logout();
          }
        });
    };

    getStatusCount();
  }, [filter]);

  useEffect(() => {
    let urlEventString: string;

    if (filter.type === 'days') {
      urlEventString = `${API_URL}events/user/${userId}?days=${filter.days}&page=${page + 1}`;
    } else {
      urlEventString = `${API_URL}events/user/${userId}?dategte=${filter.date?.start}&datelte=${filter.date?.end}`;
    }
    // setLoading(true);

    if (filterName && filterName.trim() !== '') {
      urlEventString += '&search=' + filterName;
    }

    if (filterStatus !== 'Todos') {
      urlEventString = urlEventString + '&score=' + filterStatus;
    }

    const fetchEventsData = async () => {
      await axios
        .get(urlEventString, {
          headers: {
            application: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then(eventResults => {
          setTableData(eventResults.data.data);
          setTotalEventCount(eventResults.data.count);
          setTotalRows(eventResults.data.count);
          // setLoading(false);
        })
        .catch(err => {
          if (err.message === 'Request failed with status code 401') {
            console.log('failed request ');
          }
        });
    };

    fetchEventsData();
  }, [filter, filterName, page, filterStatus]);

  const handleResetFilter = () => {
    setFilterName('');
    // setFilterStatus('all');
    // setLoading(false);
  };

  const handleSelectedEventNavigation = (eventId: string) => {
    navigate(eventId);
  };

  const handleFilterName = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setPage(0);
    setFilterName(event.target.value);
  };

  const isNotFound = !tableData.length;

  return (
    <>
      <DateFilter />

      <Card style={{ marginTop: '30px' }}>
        <h3 style={{ paddingTop: '20px', paddingLeft: '20px', paddingBottom: '20px' }}>Eventos</h3>

        <Tabs
          value={filterStatus}
          onChange={handleFilterStatus}
          sx={{
            px: 2,
            bgcolor: 'background.neutral',
          }}>
          {STATUS_OPTIONS.map(tab => (
            <Tab
              key={tab.label}
              label={tab.label}
              value={tab.label}
              icon={
                <Label color={(tab.color as LabelColor) ?? 'default'}>
                  {tab.label === 'Aceptable' && aceptableCount}
                  {tab.label === 'Critico' && criticoCount}
                  {tab.label === 'Optimo' && optimoCount}
                  {tab.label === 'Todos' && totalEventCount}
                  {tab.label === 'Calibrando' && calibrandoCount}
                </Label>
              }
            />
          ))}
        </Tabs>

        <Divider />

        <EventTableToolbar
          isFiltered={isFiltered}
          filterName={filterName}
          onFilterName={handleFilterName}
          onResetFilter={handleResetFilter}
        />

        <TableContainer sx={{ mt: 3, overflow: 'unset' }}>
          <Table sx={{ minWidth: 800 }}>
            <TableHeadCustom headLabel={TABLE_HEAD} />

            <TableBody>
              {tableData.length > 0 &&
                tableData.map(event => (
                  <TableRow
                    key={event.id}
                    className={classes.hoverRow}
                    onClick={() => handleSelectedEventNavigation(event.id)}>
                    <TableCell>
                      <div
                        className={classnames(classes.circleIconWrapper, {
                          critico: event.score === 'Critico',
                          optimo: event.score === 'Optimo',
                          aceptable: event.score === 'Aceptable',
                          default: event.score === 'Empty' || event.score === 'Calibrando',
                        })}>
                        {event.category === 'Ejercicio' ? (
                          <StyledIcon icon="ic:baseline-directions-run" />
                        ) : event.category === 'Comida' ? (
                          <StyledIcon icon="ic:baseline-restaurant-menu" />
                        ) : (
                          <StyledIcon icon="ic:baseline-star" />
                        )}
                      </div>

                      <span className={classes.text}>{event.title}</span>
                    </TableCell>
                    <TableCell align="right">
                      {event.date ? moment(event.date).format('D MMM - HH:mm') : '-'}
                    </TableCell>
                    <TableCell align="right">
                      {event.glucoseSpike ? `${event.glucoseSpike} mg/dL` : '-'}
                    </TableCell>
                    <TableCell align="right">
                      {event.minimumGlucose ? `${event.minimumGlucose} mg/dL` : '-'}{' '}
                    </TableCell>
                    <TableCell align="right">
                      {event.intensityChange ? `${event.intensityChange} mg/dL` : '-'}{' '}
                    </TableCell>
                    <TableCell align="right">
                      {event.outRange ? `${event.outRange.toFixed(0)} minutos` : '-'}{' '}
                    </TableCell>
                    <TableCell align="right">
                      <Label
                        variant="soft"
                        color={
                          (event.score === 'Optimo' && 'primary') ||
                          (event.score === 'Aceptable' && 'warning') ||
                          (event.score === 'Critico' && 'error') ||
                          (event.score === 'Empty' && 'default') ||
                          'default'
                        }
                        sx={{ textTransform: 'capitalize' }}>
                        {event.score}
                      </Label>
                    </TableCell>
                  </TableRow>
                ))}
              <TableNoData isNotFound={isNotFound} />
            </TableBody>
          </Table>
        </TableContainer>

        <TablePaginationCustom
          count={totalRows}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={onChangePage}
          //
        />
      </Card>
    </>
  );
}

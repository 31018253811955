import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useSnackbar } from 'notistack';

import { type RootState } from '../../core';

import * as Yup from 'yup';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// @mui
import { Stack, Alert, Button } from '@mui/material';

// components
import FormProvider, { RHFTextField } from '../../components/hook-form';

// variables
import { API_URL } from '../../constants/api';
import { PATH_DASHBOARD } from 'routes/paths';

// API
import api from '../../services/api';

// ----------------------------------------------------------------------

interface FormValuesProps {
  firstName: string;
  lastName: string;
  afterSubmit?: string;
}

export default function UpdateInviteForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const accountState = useSelector((state: RootState) => state.account);
  const user = accountState.user;

  const [searchParams] = useSearchParams();
  const tokemEmail = searchParams.get('token');

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().required('Nececitamos tu nombre para crear la cuenta'),
    lastName: Yup.string().required('Nececitamos tu apellido para crear la cuenta'),
  });

  const defaultValues = {
    firstName: '',
    lastName: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    const formData = {
      email: user.email,
      token: tokemEmail,
      user: {
        first_name: data.firstName,
        last_name: data.lastName,
      },
    };

    try {
      const response = await api.post(
        `${API_URL}admin/organization-invitation/user/${user.id}`,
        formData,
      );

      dispatch.account.getAccountInfo({
        name: formData.user.first_name,
        lastName: formData.user.last_name,
      });

      enqueueSnackbar('Cuenta creada');

      navigate(PATH_DASHBOARD.root);
    } catch (error: any) {
      // TODO: handle error
      console.log(error);
      setError('afterSubmit', {
        ...error,
        message: error.message || error,
      });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2.5}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <RHFTextField name="firstName" label="Nombre" />
        <RHFTextField name="lastName" label="Apellido" />

        <Button fullWidth size="large" type="submit" variant="contained">
          Ingresar
        </Button>
      </Stack>
    </FormProvider>
  );
}

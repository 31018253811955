import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';

// @mui

import {
  Grid,
  Card,
  CardHeader,
  Button,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
} from '@mui/material';

import '../../../../App.css';

import {
  useTable,
  TableHeadCustom,
  TablePaginationCustom,
  TableNoData,
} from '../../../../components/table';

import axios, { type AxiosError } from 'axios';
import { GLOBAL } from '../../../../constants/variables';
import { API_URL } from 'constants/api';

// Components

// @types
import type { RootState } from '../../../../core';

// ----------------------------------------------------------------------
interface ConsultationData {
  _id: string;
  created_at: string;
  updated_at: string;
  motive: string;
}

const TABLE_HEAD = [
  { id: 'motive', label: 'Motivo', align: 'left' },
  { id: 'created_at', label: 'Fecha de consulta', align: 'center' },
  { id: 'updated_at', label: 'Actualizacion', align: 'right' },
];

export default function Consultations() {
  const dispatch = useDispatch();
  const userState = useSelector((state: RootState) => state.user);
  const user = userState.user;
  const token = localStorage.getItem(GLOBAL.TOKEN_KEY);
  //const { id } = useParams(); // Get the userId from the URL

  const id = user.id;
  const [totalRows, setTotalRows] = useState(0); // state variable for total rows
  const { page, rowsPerPage, onChangePage, setPage } = useTable();
  //const [tableData, setTableData] = useState<ConsultationData | null>(null);
  const [tableData, setTableData] = useState<ConsultationData[]>([]); // Notice the [] at the end
  const [totalPages, setTotalPages] = useState(0); // state variable for total pages

  const navigate = useNavigate();

  useEffect(() => {
    const getConsultations = async () => {
      const urlString = `${API_URL}consultation/user/${id}`;

      console.log('urlString----', urlString);

      await axios
        .get(urlString, {
          headers: {
            application: 'application/json',
            authorization: `Bearer ${token}`,
          },
        })
        .then(res => {
          setTableData(res.data.data);
          const totalCount = res.data.count;
          //console.log('consulations----', res.data);
          setTotalRows(totalCount);
          setTotalPages(Math.ceil(res.data.count / 20)); // assuming that you're displaying 20 rows per page
          // setPage(page);
          const totalPages = Math.ceil(totalCount / 20);
          console.log('TableData-----', tableData);
        })
        .catch(err => {
          if (err.message === 'Request failed with status code 401') {
            console.log('Request failed with status code 401');
            // dispatch.auth.logout();
          }
        });
    };

    getConsultations();
  }, []);

  const isNotFound = !tableData.length;

  const handleConsultationClick = (consultationId: string) => {};

  const handleCreateConsultation = () => {
    // 3. Use the navigate function to go to the desired route
    navigate(`/dashboard/user/${id}/consultation/new`);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          onClick={handleCreateConsultation}
          variant="contained"
          sx={{
            width: '20%',
          }}>
          {'Crear consulta'}
        </Button>
      </Grid>

      <Grid item xs={12} md={12}>
        <Card>
          <CardHeader title="Consultas" />

          <TableContainer sx={{ mt: 3, overflow: 'unset' }}>
            <Table sx={{ minWidth: 800 }}>
              <TableHeadCustom headLabel={TABLE_HEAD} />

              <TableBody>
                {tableData.length > 0 &&
                  tableData.map(consultation => (
                    <TableRow
                      key={consultation._id}
                      className="hoverRow"
                      onClick={() => {
                        handleConsultationClick(consultation._id);
                      }}>
                      <TableCell align="left">
                        <span> {consultation.motive} </span>
                      </TableCell>
                      <TableCell align="center">
                        {consultation?.created_at
                          ? format(new Date(consultation.created_at), 'MM-dd HH:mm')
                          : '-'}
                      </TableCell>
                      <TableCell align="right">
                        {consultation?.updated_at
                          ? format(new Date(consultation.updated_at), 'MM-dd HH:mm')
                          : '-'}
                      </TableCell>
                    </TableRow>
                  ))}
                <TableNoData isNotFound={isNotFound} />
              </TableBody>
            </Table>
          </TableContainer>

          <TablePaginationCustom
            count={totalRows}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePage}
            //
          />
        </Card>
      </Grid>
    </Grid>
  );
}

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// @mui
import { Grid } from '@mui/material';

// Components
import ProfileAbout from './ProfileAbout';
import Expediente from './Expediente';

// @types
import type { RootState } from '../../../../../core';

// ----------------------------------------------------------------------

export default function Profile() {
  const dispatch = useDispatch();
  const userState = useSelector((state: RootState) => state.user);
  const user = userState.user;

  useEffect(() => {
    dispatch.user.getProfileInfo(user.id);
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <ProfileAbout
          name={user.name}
          email={user.email}
          phone={user.phone ? user.phone : ''} // Conditionally render phone number
          weight={user.weight}
          genere={user.genere}
          cgmMethod={user.cgmMethod}
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <Expediente />
      </Grid>
    </Grid>
  );
}

import React, { useState, useEffect } from 'react';
import { paramCase } from 'change-case';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { type RootState } from '../../../../core';

// @mui
import { Tab, Tabs, Table, Tooltip, Divider, TableBody, TableContainer, Card } from '@mui/material';

// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';

// @types
import { type IUserAccountGeneral } from '../../../../@types/user';

// components
import Iconify from '../../../../components/iconify';
import Scrollbar from '../../../../components/scrollbar';
import ConfirmDialog from '../../../../components/confirm-dialog';
import Label, { type LabelColor } from '../../../../components/label';

import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from '../../../../components/table';

// sections
import { UserTableToolbar, UserTableRow } from '../list';

// for api calls
import api from '../../../../services/api';
import axios from 'axios';

// ----------------------------------------------------------------------

const STATUS_OPTIONS = [
  {
    label: 'Todos',
    color: 'info',
  },
  {
    label: 'Activos',
    color: 'blue',
  },
  {
    label: 'Optimo',
    color: 'primary',
  },
  {
    label: 'Aceptable',
    color: 'warning',
  },
  {
    label: 'Critico',
    color: 'error',
  },
];

const TABLE_HEAD = [
  { id: 'name', label: 'Nombre', align: 'left' },
  { id: 'glucose', label: 'Glucosa Promedio', align: 'center' },
  { id: 'hoursInRange', label: 'Horas en rango', align: 'center' },
  { id: 'glucoseSpike', label: 'Picos al dia', align: 'center' },
  { id: 'sensorLifeDays', label: 'Vida del sensor', align: 'center' },
  { id: 'status', label: 'Estatus', align: 'center' },
  { id: '' },
];

// ----------------------------------------------------------------------

interface UserData {
  id: string;
  email: string;
  glucoseSpike: string;
  createdAt: string;
  hoursInRange: string;
  name: string;
  status: string;
  sensorLifeDays: string;
  averageGlucose: string;
}

interface Data {
  data: Array<{
    id: string;
    email: string;
    glucoseSpike: string;
    createdAt: string;
    hoursInRange: string;
    name: string;
    averageGlucose: string;
    plan: {
      status: string;
    };
  }>;
}

interface StatusCountProps {
  Aceptable: number;
  Critico: number;
  Optimo: number;
  active: number;
  inactive: number;
}

const initalStatusCount = {
  Optimo: 0,
  Aceptable: 0,
  Critico: 0,
  active: 0,
  inactive: 0,
};

function transformData(data: Data) {
  const transformed = data.data.map(
    ({
      id,
      email,
      createdAt,
      name,
      plan,
      ...rest
    }: {
      id: string;
      email: string;
      createdAt: string;
      name: string;
      plan: { status: string };
      [key: string]: any;
    }) => {
      const transformedCreatedAt = createdAt || '';
      const transformedName = name || '';
      const transformedSensorLifeDays = rest.hasOwnProperty('sensorLifeDays')
        ? rest.sensorLifeDays
        : '';
      const transformedAverageGlucose = rest.hasOwnProperty('averageGlucose')
        ? rest.averageGlucose.value
        : '';
      const status = rest.hasOwnProperty('averageGlucose') ? rest.averageGlucose.range : '';
      const transformedGlucoseSpike = rest.hasOwnProperty('glucoseSpike') ? rest.glucoseSpike : '';
      const transformedHoursInRange = rest.hasOwnProperty('hoursInRange')
        ? rest.hoursInRange.hour
        : '';

      return {
        id,
        email,
        createdAt: transformedCreatedAt,
        name: transformedName,
        glucoseSpike: transformedGlucoseSpike,
        sensorLifeDays: transformedSensorLifeDays,
        averageGlucose: transformedAverageGlucose,
        hoursInRange: transformedHoursInRange,
        status,
      };
    },
  );

  return transformed;
}

export default function UserTable({ withBreadcrumbs = true }) {
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0); // state variable for total pages
  const [totalRows, setTotalRows] = useState(0); // state variable for total rows

  const [tableData, setTableData] = useState<UserData[]>([]);
  const [filterName, setFilterName] = useState('');
  const [openConfirm, setOpenConfirm] = useState(false);
  const [filterStatus, setFilterStatus] = useState('Todos');

  const [statusCount, setStatusCount] = useState<StatusCountProps>(initalStatusCount);
  const [totalUserCount, setTotalUserCount] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  useEffect(() => {
    getUserList();
    getStatusCount();
  }, [page, filterName, filterStatus]);

  // Gets the status count for the user Status tabs
  const getStatusCount = async () => {
    setLoading(true);
    const urlString = `/users/range-count`;

    await api
      .get(urlString)
      .then(res => {
        setLoading(false);

        setStatusCount({
          ...statusCount,
          ...res.data,
        });
      })
      .catch(err => {
        if (err) {
          console.log(err);
        }
      });
  };

  // Get Users List for table
  const getUserList = async () => {
    setLoading(true);
    let urlString = `/users?page=${page + 1}`;

    if (filterName && filterName.trim() !== '') {
      urlString = urlString + '&search=' + filterName;
    }
    if (filterStatus !== 'Todos') {
      if (filterStatus === 'Activos') {
        urlString = urlString + '&active=true';
      } else {
        urlString = urlString + '&status=' + filterStatus;
      }
    }

    await api
      .get(urlString)
      .then(res => {
        setLoading(false);
        const totalCount = res.data.count;
        const transformedData = transformData(res.data);
        setTableData(transformedData);
        setTotalRows(res.data.count);
        setTotalPages(Math.ceil(res.data.count / 20)); // assuming that you're displaying 20 rows per page
        // setPage(page);
        const totalPages = Math.ceil(totalCount / 20);

        if (filterStatus == 'Todos') {
          setTotalUserCount(totalCount);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(order, orderBy),
    filterName,
    filterStatus,
  });

  const dataInPage = dataFiltered;

  const denseHeight = dense ? 52 : 72;
  const isFiltered = filterName !== '' || filterStatus !== 'Todos';

  const isNotFound =
    (!dataFiltered.length && !!filterName) || (!dataFiltered.length && !!filterStatus);

  const handleFilterStatus = (event: React.SyntheticEvent<Element, Event>, newValue: string) => {
    setPage(0);
    // console.log("setting page to zero and setting value to ", newValue);
    setFilterStatus(newValue);
  };

  const handleFilterName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleDeleteRow = (id: string) => {
    const deleteRow = tableData.filter(row => row.id !== id);
    setSelected([]);
    setTableData(deleteRow);

    if (page > 0) {
      if (dataInPage.length < 2) {
        setPage(page - 1);
      }
    }
  };

  const handleDeleteRows = (selectedRows: string[]) => {
    const deleteRows = tableData.filter(row => !selectedRows.includes(row.id));
    setSelected([]);
    setTableData(deleteRows);

    if (page > 0) {
      if (selectedRows.length === dataInPage.length) {
        setPage(page - 1);
      } else if (selectedRows.length === dataFiltered.length) {
        setPage(0);
      } else if (selectedRows.length > dataInPage.length) {
        const newPage = Math.ceil((tableData.length - selectedRows.length) / rowsPerPage) - 1;
        setPage(newPage);
      }
    }
  };

  const handleEditRow = (id: string) => {
    navigate(PATH_DASHBOARD.user.edit(paramCase(id)));
  };

  const handleResetFilter = () => {
    setFilterName('');
    setFilterStatus('Todos');
  };

  return (
    <>
      <Card>
        <Tabs
          value={filterStatus}
          onChange={handleFilterStatus}
          sx={{
            px: 2,
            bgcolor: 'background.neutral',
          }}>
          {STATUS_OPTIONS.map(tab => (
            <Tab
              key={tab.label}
              label={tab.label}
              value={tab.label}
              icon={
                <Label color={(tab.color as LabelColor) ?? 'default'}>
                  {tab.label === 'Aceptable' && statusCount.Aceptable}
                  {tab.label === 'Critico' && statusCount.Critico}
                  {tab.label === 'Optimo' && statusCount.Optimo}
                  {tab.label === 'Activos' && statusCount.active}
                  {tab.label === 'Todos' && totalUserCount}
                </Label>
              }
            />
          ))}
        </Tabs>

        <Divider />

        <UserTableToolbar
          isFiltered={isFiltered}
          filterName={filterName}
          onFilterName={handleFilterName}
          onResetFilter={handleResetFilter}
        />

        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
          <TableSelectedAction
            dense={dense}
            numSelected={selected.length}
            rowCount={tableData.length}
            onSelectAllRows={checked => {
              onSelectAllRows(
                checked,
                tableData.map(row => row.id),
              );
            }}
            action={
              <Tooltip title="Delete">
                <Iconify icon="eva:trash-2-outline" />
              </Tooltip>
            }
          />

          {/* <Scrollbar> */}
          <Table size={dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
            <TableHeadCustom
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={tableData.length}
              numSelected={selected.length}
              onSort={onSort}
              onSelectAllRows={checked => {
                onSelectAllRows(
                  checked,
                  tableData.map(row => row.id),
                );
              }}
            />

            <TableBody>
              {dataFiltered.map(row => (
                <UserTableRow
                  key={row.id}
                  row={row}
                  selected={selected.includes(row.id)}
                  onSelectRow={() => {
                    onSelectRow(row.id);
                  }}
                  onDeleteRow={() => {
                    handleDeleteRow(row.id);
                  }}
                  onEditRow={() => {
                    handleEditRow(row.name);
                  }}
                />
              ))}

              <TableEmptyRows
                height={denseHeight}
                emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
              />
              <TableNoData isNotFound={isNotFound} />
            </TableBody>
          </Table>
          {/* </Scrollbar> */}
        </TableContainer>

        <TablePaginationCustom
          count={totalRows}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={onChangePage}
          //
        />
      </Card>
    </>
  );
}

// ----------------------------------------------------------------------

// TODO: update

function applyFilter({
  inputData,
  comparator,
  filterName,
  filterStatus,
}: {
  inputData: IUserAccountGeneral[];
  comparator: (a: any, b: any) => number;
  filterName: string;
  filterStatus: string;
}) {
  const stabilizedThis = inputData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map(el => el[0]);

  /*
  if (filterName) {
    inputData = inputData.filter(
      (user) => user.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

if (inputData && filterName) {
  inputData = inputData.filter((user) => {
    const name = user.name ? user.name.toLowerCase() : "";
    const filter = filterName.toLowerCase();
    return name.indexOf(filter) !== -1;
  });
}

*/

  return inputData;
}

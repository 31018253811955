import { m } from 'framer-motion';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';

// hooks
import useOffSetTop from '../hooks/useOffSetTop';
// config
import { HEADER } from '../config-global';
// @mui
import { Button, Typography, Container, Stack } from '@mui/material';
// components
import { MotionContainer, varBounce } from '../components/animate';
// assets
import { PageNotFoundIllustration } from '../assets/illustrations';

import Header from '../components/header/Header';

// ----------------------------------------------------------------------

export default function Page404() {
  const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);

  return (
    <>
      <title> Pagina 404 </title>

      <Header isOffset={isOffset} />
      <Stack
        sx={{
          m: 'auto',
          maxWidth: 400,
          textAlign: 'center',
          justifyContent: 'center',
        }}>
        <MotionContainer>
          <m.div variants={varBounce().in}>
            <Typography variant="h3" paragraph>
              Pagina no encontrada
            </Typography>
          </m.div>

          <m.div variants={varBounce().in}>
            <Typography sx={{ color: 'text.secondary' }}>
              No econtramos lo que buscabas. Revisa la URL
            </Typography>
          </m.div>

          <m.div variants={varBounce().in}>
            <PageNotFoundIllustration
              sx={{
                height: 260,
                my: { xs: 5, sm: 10 },
              }}
            />
          </m.div>

          <Button component={RouterLink} to="/" size="large" variant="contained">
            Regresar al inicio
          </Button>
        </MotionContainer>
      </Stack>
    </>
  );
}

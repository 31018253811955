import { createModel } from '@rematch/core';
import { type RootModel } from '.';

import api from '../../services/api';

export const users: any = createModel<RootModel>()({
  state: {
    // selectedUserId: '61fd895e60aefd259de4e9db',
    selectedUserId: '',
    profile: {
      name: '',
      lastName: '',
      secondLastname: '',
    },
    users: [],
    form: null,
  },
  reducers: {
    storeSeletedUserId(state, payload) {
      return {
        ...state,
        selectedUserId: payload,
      };
    },
    storeUserProfile(state, payload) {
      return {
        ...state,
        profile: payload,
      };
    },
    storeForm(state, payload) {
      return {
        ...state,
        form: payload,
      };
    },
    storeUserInfo(state, payload: any) {
      return {
        ...state,
        user_info: payload,
      };
    },
  },
  effects: dispatch => ({
    async getUserById(payload, state) {
      try {
        // const { data } = await AXIOS.get(`/users/profile/${state.users.selectedUserId}`);
        const { data } = await api.get(`/users/${state.users.form.email}`, {
          headers: { authorization: `${state.auth.auth}` },
        });
        dispatch.users.storeSeletedUserId(data.users[0]._id);
        dispatch.users.storeUserProfile(data.users[0]);
        setTimeout(() => {
          dispatch.loading.setLoadingStatus(false);
        }, 3000);
      } catch (error) {}
    },
    saveForm(payload, state) {
      dispatch.users.storeForm(payload);
    },
    // 	async getUserSesion(payload) {
    //   try {
    //     // const { data } = await axios.get(`${API_URL}admin/login`);

    //     dispatch.user.storeUserInfo(payload);
    //     localStorage.setItem(GLOBAL.USER_INFO, payload);
    //   } catch (error) {
    //     console.log("ERROR AQUI", error);
    //   }

    //   const userData = await localStorage.getData(GLOBAL.USER_INFO);
    //   dispatch.user.storeUserInfo(userData);
    // },
  }),
});

import React from 'react';

// State Management
import { Provider as ReduxProvider } from 'react-redux';
import { store } from './core';

// Styles
import './index.css';
import './App.css';

// Theme
import ThemeProvider from 'theme';

// Routes
import Routes from './routes';

// Authentication
import { STYTCH_PUBLIC_TOKEN } from './constants/variables';
import { StytchUIClient } from '@stytch/vanilla-js';
import { StytchProvider } from '@stytch/react';

// Components
import { SettingsProvider, ThemeSettings } from './components/settings';
import SnackbarProvider from 'components/snackbar/SnackbarProvider';

// Sentry
import * as Sentry from '@sentry/react';

const stytch = new StytchUIClient(STYTCH_PUBLIC_TOKEN as string);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_MODE,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export default function App() {
  return (
    <SettingsProvider>
      <ThemeProvider>
        <ThemeSettings>
          <ReduxProvider store={store}>
            <StytchProvider stytch={stytch}>
              <SnackbarProvider>
                <Routes />
              </SnackbarProvider>
            </StytchProvider>
          </ReduxProvider>
        </ThemeSettings>
      </ThemeProvider>
    </SettingsProvider>
  );
}

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import * as Yup from 'yup';
import axios, { type AxiosError } from 'axios';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// @mui
import { Stack, IconButton, InputAdornment, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// components
import Iconify from '../../components/iconify';
import FormProvider, { RHFTextField } from '../../components/hook-form';

// variables
import { API_URL } from '../../constants/api';
import { PATH_ONBOARDING } from '../../routes/paths';

import { identifyUser, trackRegisterUser } from '../../services/analytics';
// ----------------------------------------------------------------------

interface FormValuesProps {
  email: string;
  password: string;
  afterSubmit?: string;
}

export default function AuthRegisterForm() {
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const RegisterSchema = Yup.object().shape({
    email: Yup.string().email().required('Necesitamos tu correo electronico'),
    password: Yup.string()
      .required('Nos hace falta una contraseña')
      .min(
        8,
        'Tu contraseña debe de ser de 8 caracteres y tener al menos una mayúscula, una minúscula, un número y un caracter especial',
      )
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,}$/,
        'Tu contraseña debe de ser de 8 caracteres y tener al menos una mayúscula, una minúscula, un número y un caracter especial',
      ),
  });

  const defaultValues = {
    email: '',
    password: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      const response = await axios.post(`${API_URL}admin`, data);

      const { token, user } = response.data;
      localStorage.setItem('Token Stytch', token);
      // Handles Token
      dispatch.auth.getAuth(token);

      // Handles UserAccount
      dispatch.account.getAccountInfo(user);

      identifyUser(user);
      trackRegisterUser(user);

      // TODO: Fix Bandaid solution
      // navigate(PATH_ONBOARDING.new);
      setTimeout(() => {
        navigate(PATH_ONBOARDING.new);
      }, 100);
    } catch (error: any) {
      console.log(error);

      console.log(JSON.stringify(error));

      console.error(error.response?.data.message);
      // TODO: Handle error
      setError('afterSubmit', {
        ...error,
        message: error.response?.data.message || 'Un usuario con este correo ya existe',
      });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2.5}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <RHFTextField name="email" label="Correo electronico" />

        <RHFTextField
          name="password"
          label="Constraseña"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                  edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <LoadingButton
          fullWidth
          color="inherit"
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitSuccessful || isSubmitting}
          sx={{
            bgcolor: theme => theme.palette.primary.main,
            color: theme => theme.palette.primary.contrastText,
            '&:hover': {
              bgcolor: theme => theme.palette.primary.dark,
              color: theme => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
            },
          }}>
          Crear cuenta
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}

import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export type ColorSchema = 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    neutral: string;
  }
  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }
  interface PaletteColor {
    lighter: string;
    darker: string;
  }
}

// SETUP COLORS

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

const PRIMARY = {
  lighter: '#CDF4E5',
  light: '#01D985',
  main: '#00AD69',
  dark: '#037246',
  darker: '#143025',
  contrastText: '#FFFFFF',
};

const SECONDARY = {
  lighter: '#E8F4E1',
  light: '#CEF2BB',
  main: '#AADA91',
  dark: '#89AE75',
  darker: '#3F5036',
  contrastText: '#000000',
};

const INFO = {
  lighter: '#E5E1E6',
  light: '#AEB2B9',
  main: '#54565A',
  dark: '#797B80',
  darker: '#2D2E30',
  contrastText: '#FFFFFF',
};

const BLUE = {
  lighter: '#CCF4FE',
  light: '#68CDF9',
  main: '#078DEE',
  dark: '#0351AB',
  darker: '#012972',
  contrastText: '#FFFFFF',
};

const SUCCESS = {
  lighter: '#CDF4E5',
  light: '#01D985',
  main: '#00AD69',
  dark: '#037246',
  darker: '#143025',
  contrastText: '#FFFFFF',
};

const WARNING = {
  lighter: '#FFEBD7',
  light: '#FFD3A8',
  main: '#FFA043',
  dark: '#D28438',
  darker: '#663F18',
  contrastText: '#212B36',
};

const ERROR = {
  lighter: '#FFADA2',
  light: '#F97C6A',
  main: '#EA5944',
  dark: '#B42F1C',
  darker: '#6C1003',
  contrastText: '#FFFFFF',
};

const COMMON = {
  common: { black: '#000000', white: '#FFFFFF' },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  blue: BLUE,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  divider: alpha(GREY[500], 0.24),
  action: {
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export default function palette(themeMode: 'light' | 'dark') {
  const light = {
    ...COMMON,
    mode: 'light',
    text: {
      primary: GREY[800],
      secondary: GREY[600],
      disabled: GREY[500],
    },
    background: { paper: '#FFFFFF', default: '#FFFFFF', neutral: GREY[200] },
    action: {
      ...COMMON.action,
      active: GREY[600],
    },
  } as const;

  const dark = {
    ...COMMON,
    mode: 'dark',
    text: {
      primary: '#FFFFFF',
      secondary: GREY[500],
      disabled: GREY[600],
    },
    background: {
      paper: GREY[800],
      default: GREY[900],
      neutral: alpha(GREY[500], 0.16),
    },
    action: {
      ...COMMON.action,
      active: GREY[500],
    },
  } as const;

  return themeMode === 'light' ? light : dark;
}

import React from 'react';
import { useSelector } from 'react-redux';

import { useSearchParams, useLocation } from 'react-router-dom';

// @mui
import { Link, Typography, Stack } from '@mui/material';

// components
import Logo from '../../components/logo';

// sections
import AcceptInviteForm from '../../sections/onboarding/AcceptInviteForm';

// assets
import { LogoIcon } from '../../assets/icons';
import AuthWithSocial from 'sections/auth/AuthWithSocial';

// ----------------------------------------------------------------------

export default function AcceptInvitePage() {
  const [searchParams] = useSearchParams();
  const organization = searchParams.get('organization');

  return (
    <>
      <Stack
        sx={{
          m: 'auto',
          maxWidth: 400,
          minHeight: '100vh',
          textAlign: 'center',
          justifyContent: 'center',
        }}>
        <LogoIcon sx={{ mb: 5, height: 96 }} />

        <Typography variant="h3" paragraph>
          Crea tu cuenta y unete a <br /> {organization}
        </Typography>

        <Typography sx={{ color: 'text.secondary', mb: 5 }}>
          Ingresa tu información personal para crear tu cuenta
        </Typography>

        <AcceptInviteForm />
        <AuthWithSocial title="Continuar con google" />
        {/* TODO: add social login form */}
      </Stack>
    </>
  );
}

// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  if (sublink.startsWith('/')) {
    sublink = sublink.substring(1);
  }
  return `${root}/${sublink}`;
}

// ----------------------------------------------------------------------

export const PATH_ROOT = '/';

const ROOTS_DASHBOARD = '/dashboard';

export const PATH_HOME = {
  root: '/dashboard',
};

export const PATH_AUTH = {
  login: '/login',
  register: '/register',
  resetPassword: '/reset-password',
  newPassword: '/new-password',
};

export const PATH_PAGE = {
  faqs: '/faqs',
};

// TODO: Set right paths later
export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  users: path(ROOTS_DASHBOARD, '/users'),
  reports: path(ROOTS_DASHBOARD, '/reports'),
  activity: path(ROOTS_DASHBOARD, '/activity'),
  recommendations: {
    root: path(ROOTS_DASHBOARD, '/recommendations'),
    new: path(ROOTS_DASHBOARD, '/recommendations/new'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/recommendations/${id}/edit`),
  },
  ranges: {
    root: path(ROOTS_DASHBOARD, '/ranges'),
    new: path(ROOTS_DASHBOARD, '/ranges/new'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/ranges/${id}/edit`),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/dashboard'),
    profile: (id: string) => path(ROOTS_DASHBOARD, `/user/${id}/glucose`),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    edit: (name: string) => path(ROOTS_DASHBOARD, `user/${name}/edit`),
  },
  profile: {
    root: path(ROOTS_DASHBOARD, '/profile'),
    general: path(ROOTS_DASHBOARD, '/general'),
  },
};

const ROOT_ONBOARDING = '/onboarding';

export const PATH_ONBOARDING = {
  root: ROOT_ONBOARDING,
  new: path(ROOT_ONBOARDING, '/create-organization'),
  invite: path(ROOT_ONBOARDING, '/invite'),
  update: path(ROOT_ONBOARDING, '/update-account'),
};

export const PATH_LOGIN = {
  root: '/login',
  verify: '/login/verify',
  admin: '/admin',
};

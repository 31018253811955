import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, Link as RouterLink } from 'react-router-dom';

import * as Yup from 'yup';
import axios from 'axios';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, Alert, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { PATH_LOGIN } from '../../routes/paths';

// components
import Iconify from '../../components/iconify';
import FormProvider, { RHFCodes, RHFTextField, RHFPhoneInput } from '../../components/hook-form';
import { useSnackbar } from '../../components/snackbar';

//stytch
import { useStytch } from '@stytch/react';

// variables
import { API_URL } from '../../constants/api';

// ----------------------------------------------------------------------

type OtpMethod = 'whatsapp' | 'sms';
interface FormValuesProps {
  phone: string;
  afterSubmit?: string;
}

export default function AuthLoginForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const stytchClient = useStytch();

  const { enqueueSnackbar } = useSnackbar();

  const sendWhatsappPasscode = useCallback(
    (phone: string): Promise<any> => {
      return stytchClient.otps.whatsapp.send(phone, {
        expiration_minutes: 5,
      });
    },
    [stytchClient],
  );

  const sendSmsPasscode = useCallback(
    (phone: string): Promise<any> => {
      return stytchClient.otps.sms.send(phone, {
        expiration_minutes: 5,
      });
    },
    [stytchClient],
  );

  const LoginSchema = Yup.object().shape({
    phone: Yup.string().required('Phone is required'),
  });

  const defaultValues = {
    phone: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  const onSubmit = async (data: FormValuesProps, method: OtpMethod) => {
    const phone = cleanPhone(data.phone);
    try {
      const response = await axios.get(`${API_URL}users/phone/${phone}`);
      const { id } = response.data;
      let methodId;

      if (id) {
        //Validate Stytch and navigate to OTP page
        if (method === 'whatsapp') {
          try {
            const response = await sendWhatsappPasscode(phone);
            if (response.status_code === 200) {
              methodId = response.method_id;
              enqueueSnackbar('Código enviado a whatsapp');
            }
          } catch (error) {
            console.log('Error', error);
          }
        }

        if (method === 'sms') {
          const response = await sendSmsPasscode(phone);
          console.log('response', response);
          if (response.status_code === 200) {
            methodId = response.method_id;
            enqueueSnackbar('Código enviado a SMS');
          }
        }

        navigate(`${PATH_LOGIN.verify}?methodId=${methodId}`);
      } else {
        reset();
        setError('afterSubmit', { message: 'El número de teléfono no está registrado' });
      }
    } catch (error: any) {
      reset();

      // Check if error.response exists and has a data property
      const serverErrorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : 'An unexpected error occurred';

      // Use the server's error message, or a generic message if not available
      setError('afterSubmit', { message: serverErrorMessage });
    }
  };

  const cleanPhone = (phone: string) => {
    phone = phone.replace(/\s/g, '');
    return phone;
  };

  return (
    <FormProvider methods={methods}>
      <Stack spacing={3} sx={{ my: 2 }}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <RHFPhoneInput name="phone" data-cy="phone-input" />
      </Stack>
      <Stack alignItems="flex-start" sx={{ my: 2 }}>
        <Typography variant="body2"></Typography>
      </Stack>

      {/* <RHFCodes keyName='codes' inputs={['a','b','c','d']} /> */}
      <Stack sx={{ mt: 5 }}>
        <LoadingButton
          data-cy="whatsapp-login"
          onClick={handleSubmit(data => onSubmit(data, 'whatsapp'))}
          fullWidth
          color="inherit"
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitSuccessful || isSubmitting}
          sx={{
            my: 2,
            bgcolor: theme => theme.palette.primary.main,
            color: theme => theme.palette.primary.contrastText,
            '&:hover': {
              bgcolor: theme => theme.palette.primary.dark,
              color: theme => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
            },
          }}>
          Iniciar con Whatsapp
        </LoadingButton>

        <LoadingButton
          data-cy="sms-login"
          onClick={handleSubmit(data => onSubmit(data, 'sms'))}
          fullWidth
          color="inherit"
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitSuccessful || isSubmitting}
          sx={{
            my: 2,
            bgcolor: theme => theme.palette.primary.contrastText,
            color: theme => theme.palette.primary.dark,
            border: '1px solid',
            borderColor: theme => theme.palette.primary.dark,
            '&:hover': {
              bgcolor: theme => theme.palette.primary.dark,
              color: theme => theme.palette.primary.contrastText,
            },
          }}>
          Iniciar con SMS
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}

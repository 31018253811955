import React, { useState, useEffect } from 'react';

// @mui
import { Container } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';

// for api calls
import axios from 'axios';

import { API_URL } from 'constants/api';
import { useSelector, useDispatch } from 'react-redux';

import { type RootState } from '../../core';
import CustomBreadcrumbs from '../../components/custom-breadcrumbs';

import { useSettingsContext } from '../../components/settings';

// sections
import { UserTable, UserTableEmptyState } from '../../sections/@dashboard/user/list';

// ----------------------------------------------------------------------

export default function UserListPage() {
  const { themeStretch } = useSettingsContext();

  const accountState = useSelector((state: RootState) => state.account);
  const isEmpty = accountState.settings.isEmpty;

  return (
    <>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Lista de pacientes"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Usuarios', href: PATH_DASHBOARD.user.root },
          ]}
        />

        {isEmpty ? <UserTableEmptyState /> : <UserTable />}
      </Container>
    </>
  );
}

import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { Home, Recomendations, UserList } from '../views';

// Views
import ProfileAccountPage from '../views/profile/ProfileAccountPage';
import UserProfilePage from '../views/userProfile/UserProfilePage';
import ActivityFeed from '../views/activity/ActivityFeed';
import Ranges from '../views/ranges/Ranges';
import CreateOrganizationPage from '../views/onboarding/CreateOrganizationPage';
import InviteUsersPage from '../views/onboarding/InviteUsersPage';
import UpdateInvitePage from '../views/onboarding/UpdateInvitePage';
import AddUsersPage from '../views/addPatients/AddPatientsPage';
import RecommendationNewEditForm from '../views/recomendations/RecommendationNewEditForm';
import RangesNewEditForm from '../views/ranges/RangesNewEditForm';

// Layouts
import DashboardLayout from 'layout/dashboard/DashboardLayout';
import CompactLayout from 'layout/compact/CompactLayout';
import WebviewLayout from 'layout/webview/WebviewLayout';

// Hooks

import {
  ProfileGlucose,
  EventListPage,
  EventDetailPage,
  IndicatorsListPage,
  IndicatorDetailPage,
  Wearable,
  Files,
  Profile,
  Consultations,
  CreateEditConsultation,
  Resumen,
} from '../sections/@dashboard/user/profile';

import Page404 from '../views/Page404';

import { PATH_DASHBOARD, PATH_ONBOARDING } from './paths';

type LoggedInNavigationProps = {
  isAdmin: boolean;
  userId: string | null;
};

type RoleProps = {
  role: boolean;
  userId: string | null;
};

const RedirectAdmin = ({ role, userId }: RoleProps) => {
  return role ? (
    <Navigate to="/dashboard" replace />
  ) : (
    <Navigate to={`/user/${userId}/glucose`} replace />
  );
};

// Routes if user is logged in.
const LoggedInNavigation = ({ isAdmin, userId }: LoggedInNavigationProps) => {
  return (
    <Routes>
      <Route
        path="/"
        element={<RedirectAdmin role={isAdmin} userId={userId} />}
        key={isAdmin ? '/dashboard' : '/user'}
      />
      {/* Onboarding Routes */}
      <Route path={PATH_ONBOARDING.root} element={<CompactLayout />}>
        <Route path={PATH_ONBOARDING.new} element={<CreateOrganizationPage />} />
        <Route path={PATH_ONBOARDING.invite} element={<InviteUsersPage />} />
        <Route path={PATH_ONBOARDING.update} element={<UpdateInvitePage />} />
      </Route>
      {/* Dashboard Routes */}
      {/* TODO: Update Paths to Variables */}
      <Route path="/dashboard" element={<DashboardLayout />}>
        <Route path="" element={<Home />} />
        <Route path="users" element={<UserList />} />
        <Route path="activity" element={<ActivityFeed />} />
        <Route path="ranges" element={<Ranges />} />
        <Route path="recommendations" element={<Recomendations />} />
        <Route path="profile/:tab?" element={<ProfileAccountPage />} />
        <Route path="user/:userId" element={<UserProfilePage />}>
          <Route path="glucose" element={<ProfileGlucose />} />
          <Route path="events" element={<EventListPage />} />
          <Route path="events/:eventId" element={<EventDetailPage />} />
          <Route path="indicators" element={<IndicatorsListPage />} />
          <Route path="indicators/:templateIndicatorId" element={<IndicatorDetailPage />} />
          <Route path="wearables" element={<Wearable />} />
          <Route path="files" element={<Files />} />
          <Route path="profile" element={<Profile />} />
          <Route path="consultation" element={<Consultations />} />
          <Route path="resumen" element={<Resumen />} />
          <Route path="consultation/new" element={<CreateEditConsultation />} />
          <Route path="consultation/:id" element={<CreateEditConsultation />} />
        </Route>
        <Route path="404" element={<Page404 />} />
      </Route>
      {/* Webview Routes */}
      <Route path="/user/" element={<WebviewLayout />}>
        <Route path=":userId" element={<UserProfilePage />}>
          <Route path="glucose" element={<ProfileGlucose />} />
          <Route path="events" element={<EventListPage />} />
          <Route path="events/:eventId" element={<EventDetailPage />} />
          <Route path="indicators" element={<IndicatorsListPage />} />
          <Route path="indicators/:templateIndicatorId" element={<IndicatorDetailPage />} />
          <Route path="wearables" element={<Wearable />} />
          <Route path="files" element={<Files />} />
          <Route path="profile" element={<Profile />} />
          <Route path="consultation" element={<Consultations />} />
          <Route path="resumen" element={<Resumen />} />
          <Route path="consultation/new" element={<CreateEditConsultation />} />
          <Route path="consultation/:id" element={<CreateEditConsultation />} />
        </Route>
      </Route>
      ;{/* <Route path="*" element={<Navigate to="/dashboard/404" />} /> */}
    </Routes>
  );
};

export default LoggedInNavigation;

import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../../constants/api';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, FormHelperText, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { PATH_DASHBOARD, PATH_ROOT } from '../../routes/paths';
// components
import { useSnackbar } from '../../components/snackbar';
import FormProvider, { RHFCodes } from '../../components/hook-form';
// Stytch
import { useStytch } from '@stytch/react';

// ----------------------------------------------------------------------

type FormValuesProps = {
  code1: string;
  code2: string;
  code3: string;
  code4: string;
  code5: string;
  code6: string;
  afterSubmit?: string;
};

export default function AuthVerifyCodeForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const methodId: string = queryParams.get('methodId')!;

  const stytchClient = useStytch();

  const VerifyCodeSchema = Yup.object().shape({
    code1: Yup.string().required('Código requerido'),
    code2: Yup.string().required('Código requerido'),
    code3: Yup.string().required('Código requerido'),
    code4: Yup.string().required('Código requerido'),
    code5: Yup.string().required('Código requerido'),
    code6: Yup.string().required('Código requerido'),
  });

  const defaultValues = {
    code1: '',
    code2: '',
    code3: '',
    code4: '',
    code5: '',
    code6: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(VerifyCodeSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    setError,
    formState: { isSubmitting, errors, isSubmitSuccessful },
  } = methods;

  const authenticate = useCallback(
    (code: string, method_id: string): Promise<any> => {
      return stytchClient.otps.authenticate(code, method_id, {
        session_duration_minutes: 50,
      });
    },
    [stytchClient],
  );

  const onSubmit = async (data: FormValuesProps) => {
    const otpCode = Object.values(data).join('');
    try {
      const response = await authenticate(otpCode, methodId);
      if (response.status_code === 200) {
        console.log(response);
        const { session_token } = response;
        localStorage.setItem('Token Stytch', session_token);
        console.log('Token Stytch', session_token);

        axios
          .get(`${API_URL}admin/login/user`, {
            headers: {
              Authorization: `Bearer ${session_token}`,
            },
          })
          .then(async res => {
            console.log('res', res);
            // Saves Token
            const { sessionToken } = res.data;
            await dispatch.auth.getAuth(sessionToken);

            // Saves Account Info
            const { data } = res.data;
            await dispatch.account.getAccountInfo(data);
            await dispatch.user.getUserInfo(data);

            // Sets Admin Status
            dispatch.account.getAdminStatus(false);

            navigate(PATH_ROOT);
          })
          .catch(err => {
            console.log('error ->', err);

            navigate('/register');
          });
      } else {
        console.log('Not validated', response);
      }
    } catch (error: any) {
      const stychError = error;
      if (stychError.status_code === 404) {
        setError('afterSubmit', { message: 'El código es incorrecto' });
      }

      if (stychError.status_code === 401) {
        setError('afterSubmit', { message: 'El código ya no es valido' });
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
        <RHFCodes keyName="code" inputs={['code1', 'code2', 'code3', 'code4', 'code5', 'code6']} />

        {(!!errors.code1 ||
          !!errors.code2 ||
          !!errors.code3 ||
          !!errors.code4 ||
          !!errors.code5 ||
          !!errors.code6) && (
          <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
            Código inválido
          </FormHelperText>
        )}

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          sx={{ mt: 3 }}>
          Iniciar sesión
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}

import React from 'react';
import { useNavigate } from 'react-router-dom';

// MUI
import { Container, Button } from '@mui/material';

// Components
import Welcome from '../../../../components/welcome';
import { SeoIllustration } from '../../../../assets/illustrations';

// Variables
import { PATH_DASHBOARD } from '../../../../routes/paths';

export default function UserTableEmptyState() {
  const navigate = useNavigate();

  const clickHandler = () => {
    navigate(`${PATH_DASHBOARD.profile.root}/addUsers`);
  };

  return (
    <>
      <Container>
        <Welcome
          title="Vamos a agregar a tus primeros pacientes"
          description="Pideles que descarguen la app de Somos y enviales el código de tu clinica"
          img={
            <SeoIllustration
              sx={{
                p: 3,
                width: 360,
                margin: { xs: 'auto', md: 'inherit' },
              }}
            />
          }
          action={
            <Button variant="contained" onClick={clickHandler}>
              Agregar Pacientes
            </Button>
          }
        />
      </Container>
    </>
  );
}
